import React, { useState, useEffect } from 'react';
import './fades.module.css';

import RICIBs from 'react-individual-character-input-boxes';

import silhouette from '../../img/satyr.png'
import shield from '../../img/shield.png'

export function CharacterDamageTool(props) {
    const { formData, setFormData } = props;

    // const handleChange = (event) => {
    //     setFormData({ ...formData, birthMonth: event.target.value });
    //     setInfoText(months[event.target.value])
    // };

    return (
        <div >
            <div className="diceBox">
                <div style={{ minWidth: '785px', minHeight: '575px' }}>
                    <BodyParts formData={formData} setFormData={setFormData} />
                    <img src={silhouette} style={{ width: '300px', height: 'auto', margin: '-250px' }} />
                </div>
            </div>
        </div>
    )
}

export function BodyParts(props) {
    const { formData, setFormData } = props;

    return (
        <div style={{ display: 'flex', position: 'relative', zIndex: '4' }}>
            <div id="rightSide" style={{ maxWidth: '340px' }}>
                <BodyPartLabel name="Head" number="10" healthBoxes={4} armor={formData.armor ? formData.armor.head : '0'} marginBot='56px' damage={formData.damage ? formData.damage.head : ''} formData={formData} setFormData={setFormData} id={'head'} />
                <BodyPartLabel name="Right Arm" number="5-6" healthBoxes={5} armor={formData.armor ? formData.armor.rightArm : '0'} marginBot='80px' damage={formData.damage ? formData.damage.rightArm : ''} formData={formData} setFormData={setFormData} id={'rightArm'} />
                <BodyPartLabel name="Right Leg" number="2" healthBoxes={5} armor={formData.armor ? formData.armor.rightLeg : '0'} marginBot='0px' damage={formData.damage ? formData.damage.rightLeg : ''} style={{ position: 'absolute' }} formData={formData} setFormData={setFormData} id={'rightLeg'} />
            </div>
            <div id="leftSide" style={{ maxWidth: '289px', position: 'absolute', right: '0px', top: '100px' }}>
                <BodyPartLabel name="Left Arm" number="3-4" healthBoxes={5} armor={formData.armor ? formData.armor.leftArm : '0'} marginBot='40px' damage={formData.damage ? formData.damage.leftArm : ''} formData={formData} setFormData={setFormData} id={'leftArm'} />
                <BodyPartLabel name="Torso" number="7-9" healthBoxes={10} armor={formData.armor ? formData.armor.torso : '0'} marginBot='40px' damage={formData.damage ? formData.damage.torso : ''} formData={formData} setFormData={setFormData} id={'torso'} />
                <BodyPartLabel name="Left Leg" number="1" healthBoxes={5} armor={formData.armor ? formData.armor.leftLeg : '0'} marginBot='40px' damage={formData.damage ? formData.damage.leftLeg : ''} formData={formData} setFormData={setFormData} id={'leftLeg'} />
            </div>
        </div>
    )
}

export function BodyPartLabel(props) {
    const { name, number, healthBoxes, id, armor, damage, marginBot, formData, setFormData } = props;
    
    const handleChange = (event) => {
        let newArmor = formData.armor;
        if (newArmor != '' && newArmor) {
            newArmor[id] = event;
            setFormData({ ...formData, armor: newArmor });
        }
    };

    const handleDamage = (event) => {
        let newDamage = formData.damage;
        if (newDamage != '' && newDamage) {
            newDamage[id] = event;
            setFormData({ ...formData, damage: newDamage });
        }
    };

    return (
        <div style={{ display: 'flex', minWidth: '200px', marginBottom: `${marginBot}`, position: `${marginBot == '0px' ? 'absolute' : 'relative'}` }}>
            <img src={shield} style={{ width: '64px', height: '72px' }} />
            <div>
                <p style={{ fontWeight: '800', marginBottom: '0px', textAlign: 'left' }}>
                    {name} <span style={{ fontSize: '11px' }}>[{number}]</span>
                </p>
                <RICIBs
                    amount={healthBoxes}
                    handleOutputString={handleDamage}
                    style={{ width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }}
                    inputProps={[
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 0 ? damage[0] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 1 ? damage[1] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 2 ? damage[2] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 3 ? damage[3] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 4 ? damage[4] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 5 ? damage[5] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 6 ? damage[6] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 7 ? damage[7] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 8 ? damage[8] : ''}` },
                        { style: { width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px' }, value: `${damage && damage.length > 9 ? damage[9] : ''}` },
                    ]}
                    inputRegExp={/^[sSkK]$/}
                />
                <RICIBs
                    amount={1}
                    handleOutputString={handleChange}
                    inputProps={[
                        { style: { "color": "#e27d60", position: 'absolute', top: '11px', left: '9px', width: '35px', height: '35px', paddingLeft: '0px', paddingRight: '0px', zIndex: '5' }, value:`${armor}` }
                    ]}
                    inputRegExp={/^[0-9]$/}
                />
            </div>
        </div >
    )
}