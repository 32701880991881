const items = {
    'Exiled': `You are no longer welcome in some province, country or region. Just what did you do that was bad enough to get kicked out, but not bad enough to merit summary execution?`,
    'Robbed': `Someone has stolen pretty much every cent you have in the world. How was this done? Stealth? Confidence trickery? Simple brute force? However it was accomplished, it’s made you more watchful.`,
    'Someone Got Spurned': `You either rejected or got rejected by some potential lover. Regardless of who denied whom, regardless of whether it was swift and painful or slow and gentle (and painful) or cruel and heartless (and really painful) someone’s heart got crushed.`,
    'Racer': `You have, at some point, spent a lot of time jockeying an animal along a track while being cheered on (not to mention bet on) by a feverish crowd of fans. What kind of animal was it? Why’d you quit?`,
    'Outstanding Cook': `Says it all, really. You’re a great cook. You make food taste good.`,
    'Gladiator': `You’ve fought for the entertainment of others. Maybe you were willing, maybe not so much, but you’ve known the pressure of having people hoot and cheer while someone else tries to stick a trident in your thigh.`,
    'Rescued an Injured Animal': `At some point, you saved a critter and nursed it back to health. Probably when it was young. Now it adores you.`,
    'Kidnapped by Barbarians': `Barbarians’ in this case meaning ‘Someone of a culture not your own.’ Why did they kidnap you? How did you escape? Did you escape? Did you come to share their beliefs, or were you always sullen and resentful? `,
    'Gnostic/Mystic/Religious Experience': `You’ve experienced something that fundamentally changed the way you look at the world, nature, spirituality and the underlying order of existence. Or maybe you were just possessed by evil spirits for a year. In any event, you’ve been up close and personal with the alien and bizarre. `,
    'Served the Decadent Rich': `Maybe you played the nose-flute behind a screen in a brothel, or were the towel steward at the Empress’ private bathworks, or were the door guard at the royal seraglio. Whatever, you got to see society’s best at their worst.`,
    'Mistaken-Identity Shenanegans': `You were, at some point, embroiled in zany misadventures when someone mistook you for someone else. You still may not know exactly what was going on, but you came out of it okay. `,
    'Imprisoned': `Maybe you were locked up for your political views. Maybe you were in a prison camp after your cowardly general surrendered. In any event, you were jailed somewhere crowded and unpleasant. Or maybe you were crammed in a madhouse, with or without good reason. (Ask yourself this: Why was your character imprisoned, rather than killed out of hand?)`,
    'Love Triangle': `At some point, you were in love with her and she was in love with him. Or maybe she was in love with him and he was in love with you. Or maybe they were both in love with you and you didn’t want either. Whatever happened, there were lots of hurt feelings and regrets and probably at least one really embarrassing public scene.`,
    'Caravan Debacle': `You were in a caravan that never went to its intended destination. Instead, it went terribly, terribly wrong. This could be due to bandits, horrific weather, disease, monster attack, loss of food which forced you all to resort to cannibalism... the details are up to you, but they were nasty`,
    'Press Ganged': `Against your will, you were forced onto service aboard a warship. How’d you get out of that one? <b>You quite possibly now have a compulsive behavior to beat on some sailors.</b>`,
    'Vengeance Quest': `They murdered your father! (Or maybe it was your husband, or sister, or child.) You’ve spent years honing yourself into an engine of vengeance. Now they must pay.`,
    'Magnificent Garden': `You had a really great garden, once. It was your pride and joy. It brought you happiness and tranquility. Now, it’s gone.`,
    'Stolen Birthright': `You were destined for better than this. Maybe you’re supposed to command a merchant fleet, or rule a barony, or own Apple Blossom Ranch. But you’ve been tricked or conned or forced out of what’s yours by right. Now, you’re going to take it back.`,
    'Survived Hideous Occult Ritual': `Blasphemous religious ceremony? Arcane experiment? Whatever it was, it went really, really wrong. Really. The question is, were you performing it, or just there to be sacrificed?`,
    'Diplomatic Incident': `No matter what your current social station, or what your social station was when the... unpleasantness... occurred, you managed to create tremendous antagonism between two states, nations or tribes. Did you intend to be an agent provocateur? Or was it just sheer mischance? Whatever it was, you’ve attracted somebody’s attention.`,
    'Raised Wild': `Perhaps you were abandoned in the woods as a child and were nursed by wolves (or a bear, or an ape (colony of apes on an island please), or whatever). Maybe primitives who have no real language of their own raised you. Whatever it was, you’re used to living on the edge.`,
    'Escaped a Death Sentence': `Maybe you fought free against incredible odds. Maybe you snuck out through a lucky break. Or maybe the rope just broke at the right time.`,
    'Star-Crossed Lovers': `Well, a parent just didn’t approve. Or maybe it was a spouse. Or maybe events just conspired to trap you, hundreds of miles away from your beloved. In any event, it was not to be, leaving you sadder, wiser, and unusually attentive to the quickest exit from any bedroom you enter. `,
    'Unexpected Windfall': `You’ve come to own something of great value. Maybe you won it at cards. Maybe it ‘fell off the cart.’ Maybe you just found it.`,
    'Stranded': `You could have been shipwrecked, the lone survivor of an exploration mission, or simply gotten lost somewhere desolate. In any event, you had to survive solo for a really long time.`,
    'Saved Someone’s Life': `That’s got to feel good, huh? How’d you do it? Cure the fever no one else could? Pull her from in front of runaway horses? Swim him to shore? Scare off the wolf pack? Whatever it was, you’ve got a friend for life.And your friend is a good friend to have! `,
    'Unlikely Education': `At some point, you’ve gained a great deal of knowledge about some subject that’s completely unrelated to your professions and other experiences. Maybe you were apprenticed to someone. Maybe you have an abiding interest. Maybe you were imprisoned in a specialized library for an extended period.`,
    'Disastrous Mountain Expedition': `Similar to the Caravan Debacle (above), you were involved in a horrible mission that went awry. Maybe you were exploring uncharted lands, or trying to find a profitable new pass, or were guiding mercenaries in to aid your beleaguered people. But by mischance or mischief, it all went awry.`,
    'Strange Birthmark': `You never thought too much about that birthmark on your neck (or ankle, or shoulder-blade, or wrist). You know — the one that looks like a fish (or an anchor, or a crown, or a tree). But a few people who’ve seen it have had very strange reactions, ranging from eagerness and awe, to anger or even fear. What does it mean? You have no idea.`,
    'His Majesty’s Personal Cobbler': `You made the most beautiful, most comfortable, most durable shoes in the entire kingdom, and as a result you are one of the relatively few people to have seen the monarch barefoot. Do you still have this exalted position? If not, what happened? If so, where’d all your money get to? (This result gives you a Skill in Student: Cobbler that costs more than you should really get from a single die, but... come on, it’s cobbling. You can make and fix shoes and other leather goods and that’s it. It’s not quite the lifesaver that a 4+MD Dodge Skill can be.)`,
    'Other': `<b>None of these b-list plots doing it for you?</b> Write your own series of unfortunate events in the description below and a <b>Custom Outcome</b> and maybe the GM will allow it.`
}

export default items;