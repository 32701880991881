import React from "react";
import { SelectProblem } from "../components/Problems";

export class StepFive extends React.Component {
  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="columns">
            <h4 className="reallyHeavyWords">Problem</h4>
            <small>
              Problems harm or inconvenience your character. They don’t cost points, they earn you experience points. Any time your Problem affects play, you get extra XP at the end of the session. Sometimes the player decides when a Problem comes into play, sometimes the GM decides, and sometimes it’s up to the dice.
            </small>
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="columns">
            <label className="heavyWords">Choose Your Problem</label>
            <SelectProblem setFormData={this.props.setFormData} formData={this.props.formData} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="eight columns">
            <label className="heavyWords">Additional Info</label>
            <textarea
              onKeyDown="return event.key != 'Enter';"
              className="u-full-width"
              placeholder="Just pretend this box is AA and let loose. "
              value={this.props.formData.problemAdditionalInfo}
              onChange={(event) =>
                this.props.setFormData({ ...this.props.formData, problemAdditionalInfo: event.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
