const items = {
    'Body': {
        'Athletics': {
            'name': 'Athletics',
            'description': 'This is a catchall for physical tasks that don’t merit their own Skill.  If it’s not running, climbing, riding, or enduring, it’s probably Athletics.  Use it to jump, throw, and lift heavy weights.  Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Endurance': {
            'name': 'Endurance',
            'description': 'Roll Endurance to keep running, or walking, or swimming past the point where weaker (or less driven) individuals collapse. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Fight': {
            'name': 'Fight',
            'description': 'This is the Skill for untrained brawling.  Someone with a high Fight Skill lacks the finesse of someone with a Weapon Skill (see Custom Skills,” below) but that’s rarely a comfort when he buries his axe in your spleen. Fight is used with any hand-to-hand weapon where it’s obvious how it hurts someone.  Even if you’d never seen a sword before, Fight would let you figure it out.  Missile weapons are another matter—training and control matter there.  Also, exotic weapons that aren’t dangerous without training are useless for Fight.  Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Parry': {
            'name': 'Parry',
            'description': 'When you try to knock a weapon away before it hits, or meet force with force, that’s a parry.  You use this Skill to protect yourself or others near you.  However, Parry presumes that you’ve got something hard to parry with—a mailed forearm, a shield, a weapon of your own.  If you try to parry a weapon with nothing but your bare hands, you’re likely to get a nasty cut on your elbow.  Which still may be preferable to a nasty cut on the throat. Expected posessions: A shield or a dagger',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Run': {
            'name': 'Run',
            'description': 'Cowards run from fights.  Brave men run towards them.  Cruel men run down fleeing enemies.  The Skill they all need is ‘Run’.  Roll this to move fast on your feet.  (To keep running for a long distance, roll Endurance.)  In a fight, you can move 15 feet every round automatically.  If you want to move farther than that, roll Body+Run.  A success lets you move farther—five feet for every point of Width. Expected possessions: A comfortable pair of shoes.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Vigor': {
            'name': 'Vigor',
            'description': 'This is not a Skill that is learned.  It represents how well you overcome injury and illness.  You make Body+Vigor rolls to recover from combat so this is often a good bet for belligerent types. It’s also what you roll to resist sickness. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        }
    },
    'Coordination': {
        'Climb': {
            'name': 'Climb',
            'description': 'You can travel vertically with confidence.  Scaling trees, cliffs, or a ship’s mast—it’s all the same.  In combat, you can you can ascend five feet per round for every point of Width in your set, if the surface is easy.  If the conditions are inclement, it’s five feet for every two points of Width.  Or, if you’re not in immanent danger and can take your time, a successful roll or two just gets you to the top. Expected possessions: A coil of rope.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Dodge': {
            'name': 'Dodge',
            'description': 'You move your body away from a threat, so that it does not pain you.  Ducking punches, diving for safety, leaping over bonfires without singeing—all require Dodge rolls.  You cannot use Dodge to protect anyone but yourself. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Ride': {
            'name': 'Ride',
            'description': 'You can get a dumb animal to do the work of hauling you around.  Roll this to beat the other riders in a race, guide your horse through the forest, or charge down on your opponent in battle.Riding is very general.  Riding a creature you’ve never mounted before, you may face a Difficulty until you’re used to the quirks of riding a giant bat, seahorse or rabbit. Expected possessions: A decent saddle and a horse of no great worth.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Stealth': {
            'name': 'Stealth',
            'description': 'A quiet step?  Remaining unseen while the household searches for you?  Pilfering, purloining, and picking pockets?  All covered.  This Skill is the one-stop shop for burglars and adulterers alike.  (Okay, you might want some Climb and Lie in there as well.) Stealth is versatile, useful for all manner of deviousness.  If this sort of mischief is a major part of your game, your GM may break Stealth up into a variety of Skills—moving unseen, moving unheard, confounding traps and so forth.  But for most games, one broad Skill suffices.  Expected possessions: A black cloak with hood and scarf.  ',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        }
    },
    'Knowledge': {
        'Counterspell': {
            'name': 'Counterspell',
            'description': ' In this setting, snarling or snagging lines of occult force is something that can be learned or intuited. It works just like the Dodge skill, by producing Gobble Dice.  However, counter-casting only works on spells that focus on the counterspeller.  If the sorcerer collapses the tower you’re in, counterspelling won’t save you.  Expected possessions: Lucky charm or talisman.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Healing': {
            'name': 'Healing',
            'description': 'This is the mundane ability to clean wounds, apply bandages, and whip up herbal remedies.  Expected possessions: Bandages, splint materials and herbs of various potencies.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Lore': {
            'name': 'Lore',
            'description': 'You’re educated.  You know legends, history, folk tales, the great myths of the major religions—you’ve studied it all.  The GM may not allow you to roll Lore for some facts, simply because they are unknown to any but experts.  If you want to really specialize in some field of knowledge, be a Student of it.  (See the “Student” Skill, below.)  Expected possessions: Several fragile books and scrolls.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Strategy': {
            'name': 'Strategy',
            'description': 'Tacticians fight battles, but the strategist fights the war. Past the level of yelling for the cavalry to shore up a flank is the larger picture of controlling supply lines, encircling or bypassing fortresses and encampments, choosing propitious battlegrounds, or winning by avoiding conflict altogether. Expected possessions: None.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Tactics': {
            'name': 'Tactics',
            'description': 'You’re an expert at allocating unit-scale military force. It’s not much good for engagements involving fewer than ten people or more than a hundred, but that covers an awful lot of encounters. Expected possessions: A game with pieces and board of decent quality',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        }
    },
    'Command': {
        'Haggle': {
            'name': 'Haggle',
            'description': 'You drive a hard bargain.  By debating, wheedling, brow-beating and feigning indignation, you can get the best price for items, whether buying or selling. Expected possessions: A rather nice hat with matching belt and gloves.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Inspire': {
            'name': 'Inspire',
            'description': 'You can provoke the higher, nobler (or crueler, coarser) emotions in those who listen. An inspiring speech could convince the jury to let your friend off, by inspiring mercy.  Or you could give the rousing address that spurs your army into making that last, doomed charge.  Inspire is predicated on sympathy.  It’s the Skill of getting people to feel what you feel (or, more deviously, to feel what you appear to feel).  It can also be used to taunt people into being crazed wrath, but usually you use it to get people on your side.This Skill is only useful when used on crowds or groups. If there is a conversation with give and take, it’s probably more a Fascinate situation. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Intimidate': {
            'name': 'Intimidate',
            'description': 'Where Inspire provokes a broad range of emotions, Intimidate focuses on fear. With an Intimidate set, you present yourself as a person it would be foolish to cross. The nature of the threat doesn’t matter—Intimidation covers anything from political ruination to magical revenge to a brutal beating. It can be used on groups or individuals. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Regale': {
            'name': 'Regale',
            'description': 'You put on a good show for the crowd. It could be that you’re a storyteller, an actor or a musician.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        }
    },
    'Sense': {
        'Direction': {
            'name': 'Direction',
            'description': 'Your sense of direction gives you a feel for your location.  Some part of you is aware of distance, height,  and direction.  You can find North, retrace your steps, and guide your ship back to port by dead reckoning. Expected possessions: A map of the local region.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Eerie': {
            'name': 'Eerie',
            'description': 'With the Eerie sense you detect the uncanny, the unnatural and the unwholesome. Your eerie sense won’t protect you from a lurking footpad, but it might alert you that you’re in a haunted (or cursed, or ill-omened) house. Sorcerers hate people with good eerie senses, unless those people have been co-opted as apprentices. Expected possessions: A good-luck charm. ',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Empathy': {
            'name': 'Empathy',
            'description': 'This social sense permits you to gauge those with whom you speak and interact.  Use it to see through lies, detect influence of your mood with guile, and spot the envious ex-lover at the wedding. Expected possessions: A memento of a bittersweet love affair',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Hearing': {
            'name': 'Hearing',
            'description': 'Your perception of sound is exceptional.  You’re superb at eavesdropping, hearing muffled footsteps in dark alleys, and recognizing which string on your friend’s violin is just a trifle sharp. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Scrutinize': {
            'name': 'Scrutinize',
            'description': 'Scrutinize measures your character’s ability to look around a locale and reconstruct what happened there, or glean information that isn’t immediately obvious.  Use it to track someone in the forest, find the hidden panel in the chest, or get a clue from a crime scene.  Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Sight': {
            'name': 'Sight',
            'description': 'This Skill improves your chances of spotting the trapdoor you’re about to step on, the sail on the distant horizon, or the stealthy assassin taking aim from that minaret. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        }
    },
    'Charm': {
        'Fascinate': {
            'name': 'Fascinate',
            'description': 'You can talk and make someone want to keep listening to you.  It measures your conversational finesse, your talent for listening (or making people think you’re listening) and your general ability to help people like you.  If you want to charm, seduce, persuade or simply amuse an individual, this is the Skill. Expected possessions: A mirror.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Graces': {
            'name': 'Graces',
            'description': ' Short for “social graces,” this measures your manners.  It’s not just obvious stuff like “Never pinch the hostess’ husband right in front of her.”  It also indicates knowledge of foreign cultural mores.  Take this Skill and you know how to dine cordially, dance passably, flirt acceptably, and accept (or decline) a royal gift graciously.  Expected possessions: A set of fine clothes.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Jest': {
            'name': 'Jest',
            'description': '“So these two barbarians walk into a barber shop...”  This measures your ability to make people laugh, through high-minded literary sophistry with low-brow fart gags.  Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Lie': {
            'name': 'Lie',
            'description': 'Whether you’re stretching the truth, fabricating claims of valor or fraudulently name-dropping, this is the Skill you need to remain unflustered and consistent.  It is typically countered with Empathy.  (Some lies may be detected by other means—Knowledge+Tactics or Strategy if someone’s gassing about a famous battle, say.  The GM judges and should tell you which pool to roll.) Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        },
        'Plead': {
            'name': 'Plead',
            'description': 'Rarely a Skill you want to use, it may be one you have to use. Plead plays on the kindness or sympathy (or disgust) of someone you cannot sway through other means. You can plead for your life, plead for a loan, or plead for the good of the country. It’s all covered by this single, shameless Skill. Expected possessions: none.',
            'number': 1,
            'modifier': 0,
            'expertOrMaster': 0,
            'numDice': [1]
        }
    }
}

export default items;