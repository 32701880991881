import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';
import silhouette from '../../img/satyr.png'

import { CharacterDamageTool } from '../../js/components/CharacterDamageTool'

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function DamageDeathHealing(props) {
    return (
        <div>
            <span className="heavyWords">Damage</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                <b>Damage</b>. Attacks produce it. Defenses avoid it. And it's better to give than receive it.
            </p>
            <p className="noPaddingParagraph">
                Being punched in the neck isn’t like being stabbed there. To represent the difference between
                superficial injury and serious wounding, the ORE has two types of damage: <b>Shock</b> and <b>Killing</b>.
            </p>
            <p className="noPaddingParagraph">
                <b>Shock</b> damage rattles you and makes you uncomfortable, but unless you take lots of it
                repeatedly, it’s unlikely to leave you dead.
            </p>
            <p style={{ marginBottom: '60px', textIndent: '30px' }}>
                <b>Killing</b> damage is also what it sounds like. When a blow does both <b>Killing</b> and <b>Shock </b>
                damage, the <b>Killing</b> damage is applied first.
            </p>
            <CharacterDamageTool formData={''} setFormData={''} />
            <p className="noPaddingParagraph">
                Above is a representation of a character with boxes next to indicators for various limbs. These boxes represent how much damage you can take
                at that spot. When you take a point of <b>Shock</b> to a limb, you put an '<b>S</b>' in one unmarked
                box. If you take a point of <b>Killing</b> damage to that limb, you put a '<b>K</b>' in an open box.
            </p>
            <p className="noPaddingParagraph">
                When there are no more open boxes, <b>Shock</b> damage to that location becomes <b>Killing </b>
                damage—you change the '<b>S</b>' to a '<b>K</b>'. Once a limb is completely
                <b>Shocked</b>, further <b>Shock</b> to that limb is taken as <b>Killing</b> damage. (Killing damage is applied
                normally—if a limb is completely <b>Shocked</b>, <b>Killing</b> damage to that limb just replaces the '<b>S</b>' in a
                box with a '<b>K</b>'.)
            </p>
            <p className="noPaddingParagraph">
                Once a limb fills with <b>Killing</b> damage, any further damage to that limb goes into the torso.
                Furthermore, a limb filled with <b>Killing</b> damage cannot be used until some of the <b>Killing </b>
                damage gets healed. (The exception to this is the head location. <b>Once your head is filled
                    with <b>Killing</b> damage, you’re dead. You also die if
                    your torso fills with <b>Killing</b> damage.)</b>
            </p>
            <p className="noPaddingParagraph">
                Once your torso fills with <b>Shock</b> damage, you’re
                dazed. Dazed characters take a -1d penalty to
                all actions, on top of any other penalties. Once
                your head fills with <b>Shock</b> damage, you’re
                unconscious.
            </p>
            <p className="noPaddingParagraph">
                The damage silhouette on your character sheet has numbers corresponding to the different
                limbs. When an attack hits you, the Height of the roll indicates where the blow lands.
            </p>
            <div className="diceBox">
                <table style={{ width: '100%', lineHeight: '1' }} >
                    <tr>
                        <th>Roll</th>
                        <th>Hit Location</th>
                        <th>Total Points of <b>Shock+Killing</b> damage it can receive</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Left Leg</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Right Leg</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>3-4</td>
                        <td>Left Arm</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>5-6</td>
                        <td>Right Arm</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>7-9</td>
                        <td>Torso</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Head</td>
                        <td>4</td>
                    </tr>
                </table>
            </div>
            <p style={{ marginBottom: '20px' }}>
                <b>If a character is missing a limb, any hits to that location go to the torso instead.</b>
            </p>
            <span className="heavyWords" style={{}}>Dying</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p style={{ marginBottom: '20px' }}>
                <b>When a character's head or torso fills up with <b>Killing</b> damage, the character dies</b>.
            </p>
            <span className="heavyWords">Healing</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p style={{ marginBottom: '20px' }}>
                <b>
                    After the fight, as soon as you’ve had a chance to shake it off, half the <b>Shock</b> you’ve taken
                    in that fight disappears (round up).</b>
            </p>
            <p>
                <b>Example:</b>
            </p>
            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} >
                <div style={{ maxWidth: '500px' }}>
                    Lio gets roughed up, taking <b>Four Shock</b> to the torso, <b>Three</b> to his left arm and <b>Five</b> to his right
                    arm.  Once he’s had a chance to wince and feel himself for broken bones, half that damage
                    goes away.  He’s left with <b>Two Shock</b> in his torso, <b>One</b> in his arm and <b>Two</b> in his other arm.
                </div>
            </div>
            <p className="noPaddingParagraph">
                Various healing skills can relieve <b>Shock</b>. Once per day, a character can benefit from the
                Healing Skill. If the healer rolls successfully, a number of <b>Shock</b> damage points equal to
                the Width of the roll is removed, from whichever locations the healer wishes. For this
                roll, Height of the set does not matter.
            </p>
            <p className="noPaddingParagraph">
                Rest and relaxation also cure <b>Shock</b> damage. Every time your character awakens from a
                good night’s rest, make a Body+Vigor roll. If it succeeds, you may remove a number of
                <b> Shock</b> points equal to the Width of the roll.
            </p>
            <p className="noPaddingParagraph">
                <b>Killing</b> damage is another matter. If a character does nothing but rest and recuperate for
                a full day, a healer can attempt a roll to help him. If the roll is a success—any success—
                one point of <b>Killing</b> damage is turned into <b>Shock</b>, at a location of the healer’s choice.
                Only one such attempt is permitted per day.
            </p>
            <p className="noPaddingParagraph">
                If a character goes a week without taking any more damage, one point of <b>Killing</b> damage
                on each limb turns into <b>Shock</b> all by itself.
            </p>
            <p className="noPaddingParagraph">
                Depending on where they are, the players may have recourse to healing magic. Different
                types of magic have different rules for how they are used.
            </p>
        </div>
    )
}