import React, { useState, useEffect } from 'react';
import './fades.module.css';

import { MenuItem, Select } from '@material-ui/core';

import problems from '../data/problems';

export function SelectProblem(props) {
    const { formData, setFormData } = props;
    const [infoText, setInfoText] = useState('')
    const [problem, setProblem] = useState(formData.problem)
    const [options, setOptions] = useState([]);
    const [initLoad, setInitLoad] = useState(true);

    useEffect(() => {
        setProblem(formData.problem);

        let tempArr = [];
        let problemChoices = Object.keys(problems);
        problemChoices.forEach(element =>
            tempArr.push(<MenuItem key={Math.random()} value={element}>{element}</MenuItem>)
        );
        setOptions(tempArr);

        if (initLoad && problem != '') {
            setInfoText(problems[problem]);
        }
        setInitLoad(false);
    }, [formData.problem]);


    const handleChange = (event) => {
        setFormData({ ...formData, problem: event.target.value });
        setInfoText(problems[event.target.value])
    };

    return (
        <div>
            <Select value={problem} onChange={handleChange} >
                {options ? options : <MenuItem></MenuItem>}
            </Select>
            {infoText ?
                <div className="fancyBorder">
                    {infoText ? <p dangerouslySetInnerHTML={{ __html: infoText }}></p> : ''}
                </div> : ''
            }
        </div>

    )
}