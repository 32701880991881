const items = [
    {
        label: 'Journey of Coyote',
        year: `<br /><b> Temple at Shah - First Sighting c. 921 </b><br /><br />
        We stood at the edge of a rift in the clay colored earth; it splintered and ran its course in several directions. 
        I looked over the edge to see a river stained red with iron far below. Before us carved into the mountainous wall of the far edge was an inverted temple. Each descending floor, of which there looked to be a hundred, was studded
        with windows no taller than a hand, and half as wide. Thousands there were, like black stars on a stone sky. The only visible entrance was at the top. A solem stone doorway a hundred feet across from the ridge where we stood.
        Whatever door that had once barred it had long rotten away. Had it existed to keep us out? Or something else in?`
        , author: 'Coyote'
    },
    {
        label: 'Collected Accounts from the Tragedy at Bath',
        year: `"Revelry, merriment, then solitude, death." <br />
        "Creeping pestilience has struck! Like a black cloud it is sunken low over this city!" <br /> 
        "Night has crept into this realm of day, and shadows are <i>folded</i>!" <br />
        "They are spent! Corrupted! Disgusting abominations of god!" <br />
        "Twisted realities! Accursed sinners!" <br />
        "We must show them the light, that they may see their wickedness~" <br /><br /><br />
        "Through devotion, justice. Through righteousness, action. Through fire. Salvation."<br />
        <b> <i>-Father McAffrey: Marc 1011</i>`
        , author: 'Ros Stephenson'
    },
    {
        label: 'Expedition into the Far Lands',
        year: `<b>First encounter with The Shade -</b><br /><br />
        Just past the break of dawn Jhin cried out. <br />
        I rushed, sword in hand, to the base of the Acacia tree he had roosted through the night. <br />
        The boy was pale. This was unusual as Jhin was of the Raft. Young, but already tanned honey-gold by that heavy sun that pounds over their shallow ocean. <br />
        Wordless he pointed me towards the marble pillar we had spied last night before setting camp. <br />
        I crossed to the stone, sword raised, to face whatever manner of beast could send fear into the boy I had before witnessed face down huricanes aboard a boat with no railing, but nothing was there. <br />
        Once. Twice. Thrice. I circled that strange obelisk but nothing was to be seen. <br />
        I turned back to question Jhin when another cry rang forth followed by a hand gesturing, this time towards my feet. <br />
        Concerned, I looked down, and what I saw was <i>wrong</i>. Like the air was the flat plane of a mirror, suddenly my shadow <i>folded</i>. Followed by this terrible wrenching sound, and a dark shape disengaged itself from my shaded form to streak like lightning over the horizon. <br />
        Once I had assured myself it was gone, I cautioned the boy to silence. <br />
        It wouldn't do to have the rest of the men afraid of their own shadows. `
        , author: 'Elroy McFarren'
    },
    {
        label: 'Letters to the Editor - Norigrad Scientific Research Committee',
        year: `All I'm saying is the the term 'Everstorm' is disengenuous. <br />
        The so-called 'strange' and 'magical' phenomena that shatters warships off the coast of Caspia is nothing more than a common ab-polar antimesocyclonic supercell as could be identified by any second-rate student of Aeronomy. <br />
        True, this particular weather structure has lasted a uniquely long time, and unlike other antimesocyclonic supercell's it doesn't appear to be accompanied by the usual intense vertical wind shear that would otherwise explain the heavy level of sustained winds local sailors report. <br />
        But to use the term 'magical' to describe this abnormality is a travesty and truly, I believe, will leave a black mark on the records of both myself and all Meteorologists under the employee of the NSRC until it is addressed. We're scientists for gods sakes. We operate on evidence. Not unicorn horns and pik'se dust.<br /><br />
        Please address this mistake and the others I previously listed in your next issue.<br />
        <i>Yours in despair, Aliz Lashaw</i>`
        , author: 'Aliz Lashaw'
    },
    {
        label: 'Assorted Poetry from the Cannibal Tribes of Equatorial Lis',
        year: `I wish I wish I was a fish <br />
        No street to roam no home to miss <br />
        Swim all day back to the sun <br />
        sleep all night need never run <br /><br />
        
        <b><i>Unknown Author</i></b>`
        , author: 'Uknown Tribesperson'
    },
    {
        label: 'Transport of the -',
        year: `Twice we took measures to see that it'd remain bound through the night. <br />
        First a chain. And not some rusted bog iron from Taugh mind you. Steel. The best money could buy. <br />
        Fat lot of good it did us. For we'd woke to an open lock and two dead the next morning. <br />
        A shit day spent slogging through the swamp was the price of our foolishness. But by evening we'd found it again; curled up under the roots of some stinking barrow tree two clicks north. <br />
        It'd pulled far back into that twisting, tangled mess of roots. <br />
        But not too far for the dogs. <br />
        Blood for blood. Now Tuck and Karlin can rest easy. <br /><br />
        We set a watch of three the next night, silver in the hand of each man. <br />
        Chain lashed tight. No lock to magick. <br />
        I was the one to wake. The only one left who could. The camp was ablaze. My men were dead on the ground, lashed together with that worthless chain. Black smoke as thick as rope coiled through the air. <br />
        Why was I not among them? Why do I yet live? <br /><br />`
        , author: 'Hal Transportervick'
    },
    {
        label: 'One Hundred and One Sea Shanties',
        year: `Hearken all sailormen listen to me <br />
        And I'll sing you a song of the fish in the sea <br />
        It goes windy weather boys, stormy weather boys <br />
        When the winds blow, we all haul together boys <br />
        Blow ye winds westerly, blow ye winds blow <br />
        Jolly sou'westerly steady she goes.. <br /><br />
        
        <b><i>Heard first two miles from the Tunis coast aboard a Cunic Frigate</i></b>`
        , author: 'Gloucester Fisherman'
    },
];
export default items;

/*
    "I must stay awake."
    Now I lay me down to sleep
    I pray the lord my soul to keep
    Guide me safely through the night
    Wake me with the morning light. 

     "We danced and dreamed, and woke to death." 

    <i>Click</i>. <br />
    "Who am I?" <br />
    "Disgusting abomination of god. Twisted reality. Accursed sinner." <br /> <br />
    "Why can't I remember?" <br />
    <i>Click. Click</i> <br /> 
    "Why can't I see the sky?" <br />
    "Is this a dream? Am I about to wake up?" <br />
    <i>Click. Click. Click.</i> <br />
    <i>Click.</i>`

    Only poets write pretty words of the snow.
    The snow is pretty like the teeth of a great white bear.
    The snow is soft like silk tied by a hangman
    The snow is white like the heart of a fire that draw you in on a cold night

    An . A deer is an animal. A grouse is a bird. Tsotskey is our fatherland. Death is inevitable.

    
"An oak is a tree. A rose is a flower. A deer is an animal. A sparrow is a bird. Russia is our fatherland. Death is inevitable."


    "God guide me safely through this night." <br />
    "Wake me with the morning light." 
**/