/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './fades.module.css';

export function AddInventory(props) {
    const { formData, setFormData } = props;
    const [addedInventory, setAddedInventory] = useState([])

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        let inventoryCopy = formData.inventory;
        let tempArr = [];

        inventoryCopy.forEach(element =>
            tempArr.push(<OneLine key={element.key} key2={element.key} name={element.name} description={element.description} formData={formData} setFormData={setFormData}></OneLine>)
        );

        setAddedInventory(tempArr);
    }, [formData]);

    const handleSubmit = (event) => {
        if (name != '') {
            event.stopPropagation();
            const oldArr = formData.inventory;
            oldArr.push({
                key: Math.floor(Math.random() * 1000),
                name,
                description
            });
            setFormData({ ...formData, inventory: oldArr });
            setName('');
            setDescription('');
        }
    };

    const changeName = (event) => { setName(event.target.value); }
    const changeDescription = (event) => { setDescription(event.target.value); }

    return (
        <div>
            <form onKeyDown="return event.key != 'Enter';">
                <div className="row">
                    <div className="two columns">
                        <label>Item Name: </label>
                    </div>
                    <div className="six columns">
                        <input type="text" placeholder="Name" value={name} onChange={changeName} />
                    </div>
                </div>
                <div className="row">
                    <div className="two columns">
                        <label>Description: </label>
                    </div>
                    <div className="eight columns">
                        <textarea
                            className="u-full-width"
                            placeholder="Description"
                            style={{ minHeight: '100px' }}
                            value={description}
                            onChange={changeDescription}
                        />
                    </div>
                </div></form >
            <div className="row">
                <div className="four columns">
                    <input type="button" value="Add" onClick={handleSubmit} />
                </div>
            </div>
            <div className="row" style={{ width: '100%', maxWidth: '90vw', overflowX: 'scroll', scrollbarWidth: 'thin', marginBottom: '10px' }}>
                <div className="columns" style={{ marginBottom: "10px" }}>
                    <table style={{ width: '100%' }} >
                        <caption>
                            <b>Added Inventory</b>
                        </caption>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Remove</th>
                        </tr>
                        {addedInventory ? addedInventory : <></>}
                    </table>
                </div>
            </div>
        </div>
    )
}

export function OneLine(props) {
    const { key2, name, description, formData, setFormData } = props;

    const handleSubmit = (event) => {
        event.stopPropagation();
        let oldArr = formData.inventory;
        for (let i = 0; i < oldArr.length; i++) {
            if (oldArr[i].key == event.target.id) {
                oldArr.splice(i, 1);
                break;
            }
        }
        setFormData({ ...formData, inventory: oldArr });
    };

    return (
        <tr>
            <td>{name}</td>
            <td>{description}</td>
            <td>
                <input type="button" value='Remove' id={key2} onClick={handleSubmit} />
            </td>
        </tr>
    )
}