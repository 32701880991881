const items = {
    'Body': {
        'name': `Body`,
        'description': `Body measures how strong, tough and able to work your character is. A high Body character can easily tote bales, overcome illness and smack around lesser specimens. A low Body indicates indolence, lethargy or general scrawn.`,
        'number': 1,
        'modifier': 0,
        'numDice': [1],
        'cost': 5
    },
    'Coordination': {
        'name': `Coordination`,
        'description': `Coordination is what you use to keep your balance, move gracefully, aim, and climb up trees. A character with poor Coordination is a klutz. `,
        'number': 1,
        'modifier': 0,
        'numDice': [1],
        'cost': 5
    },
    'Sense': {
        'name': `Sense`,
        'description': `Sense measures alertness and how well-attuned a character is to the world around him. An intelligent character might be absent-minded and oblivious to her surroundings—she has high Knowledge, low Sense. `,
        'number': 1,
        'modifier': 0,
        'numDice': [1],
        'cost': 5
    },
    'Knowledge': {
        'name': `Knowledge`,
        'description': `Knowledge describes a character’s ability to learn. An untutored character may still have high Knowledge: She just learned a lot through observation instead of study. Similarly, a person who’s not naturally bright but who has had extensive schooling may have a low Knowledge Stat but make up for it with high Skills. `,
        'number': 1,
        'modifier': 0,
        'numDice': [1],
        'cost': 5
    },
    'Command': {
        'name': `Command`,
        'description': `Command is a measure of presence. A low Command character is easy to ignore, while a high Command character gets your attention if he wants it. You may not like him, but you listen to him.`,
        'number': 1,
        'modifier': 0,
        'numDice': [1],
        'cost': 5
    },
    'Charm': {
        'name': `Charm`,
        'description': `Charm measures how pleasant you seem. People with high Charm are likeable and fun to be around. Low Charm people are crass, boring, and irritating`,
        'number': 1,
        'modifier': 0,
        'numDice': [1],
        'cost': 5
    },
}

export default items;