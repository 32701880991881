import React, { useState, useEffect } from "react";
import { FontBackground } from "./assets/js/components/FontBackground";
import Select from 'react-select';
import { Navigation } from "./Navigation";
import { Outlet, useNavigate } from "react-router-dom";

import { getCharacters } from "./backend/characters/characters";

export default function CharacterLookup(props) {
    const [searchOptions, setSearchOptions] = useState([]);
    const [expanded, setExpanded] = useState(false);
    let navigate = useNavigate();

    useEffect(async () => {
        const result = await getChars();
        const res = JSON.parse(result.body);

        let nameID = [];
        res.data.forEach(element => {
            nameID.push({
                label: element.name,
                value: element.key
            });
        });

        setSearchOptions(nameID);
    }, []);

    const changeSearch = (event) => { navigate(`${event.value}`); setExpanded(true) }

    return (
        <div>
            <Navigation />
            <div style={{ minHeight: '70vh' }}>
                <FontBackground lookupHeight={expanded}/>
                <div className="container" style={{ minWidth: '800px', background: 'none' }}>
                    <div className="row" style={{ position: 'relative', zIndex: '20' }}>
                        <div className="two columns" style={{ minWidth: '100px' }}>
                            <label>Character Lookup:</label>
                        </div>
                        <div className="six columns">
                            <Select
                                styles={{ maxWidth: '100px', padding: '5px' }}
                                className="basic-single"
                                classNamePrefix="Search"
                                isClearable={false}
                                isSearchable={true}
                                name="Search"
                                placeholder=""
                                options={searchOptions}
                                onChange={changeSearch}
                            />
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

async function getChars() {
    try {
        let result = await getCharacters();

        if (result.statusCode == '200') {
            return result;
        }

    } catch (error) {
        console.log(error);
        return {
            statusCode: error.statusCode || 500,
            body: JSON.stringify(error),
        };
    }
}
