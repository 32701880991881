import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getCharacter } from "./backend/characters/characters";
import { LevelUpCharacterSheet } from "./assets/js/components/LevelUpCharacterSheet";

export default function CharacterView(props) {
    let params = useParams();
    const [loading, setLoading] = useState(true);
    const [infoText, setInfoText] = useState({});
    const [validData, setValidData] = useState(false);

    useEffect(async () => {
        setLoading(true);
        const result = await getChar(params.CharacterID);
        const res = JSON.parse(result.body);

        if(params.CharacterID.length != 21) {
            setValidData(false);
        } else {
            setValidData(true);
        }

        setInfoText(res.data);
        setLoading(false);
    }, [params]);

    return (
        <div className="row" style={{ marginTop: '80px' }}>
            {loading ? '' : !validData ? 'Invalid Character Lookup!' :
                <LevelUpCharacterSheet setFormData={setInfoText} formData={infoText} />
            }
        </div>
    );
}

async function getChar(key) {
    try {
        let result = await getCharacter(key);

        if (result.statusCode == '200') {
            return result;
        }

    } catch (error) {
        console.log(error);
        return {
            statusCode: error.statusCode || 500,
            body: JSON.stringify(error),
        };
    }
}
