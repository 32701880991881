const items = {
    'Janos' : "Named for Janos, Goddes of winter, twilight, shadows, stealth. Her symbol is a black lotus blossom. She appears as a Baklunish human of any age and gender, with a slender and graceful build, and wielding a pair of magical falchions that she can shrink to easily conceal. Those born in this month often go unnoticed. <b>When not moving you seem to blend into the background. Those looking for you have disadvantage if they rely on sight.</b>",
    'Februs' : "Named for Februs, God of sun, light, strength and healing. Februs sits on the edge of a cold, clear mountain lake on Mount Celestia's fifth layer, Mertion. The many healing fountains and curative waters in Empyrea can restore withered limbs, lost speech, derangement, and life energy itself; those who ail need only find the right fountain. Februs teaches respect and understanding for laws, rituals, and other cultural traditions. <b>You regain hitpoints at a rate of 2x when resting for more than a day. ( 2/day ) </b>",
    'Marc' : "Named for Marc, God of chivalry, justice, honor, war, daring, and valor.  His symbol is an eight-pointed star composed of spears and maces. Marc is depicted as a hugely muscular man with a red beard and long red hair. He wears a fighting girdle made from a red dragon's hide, gauntlets from a white dragon's hide, and boots from a blue dragon's hide. He wields the greatsword Kelmar in battle. Marc is sometimes depicted by his urbanized faithful as a more civilized athlete or wrestler. Followers of Marc are said to share in his natural athleticism. <b>Gain an Expert Die on all athletic rolls.</b>",
    'April' : "Named after April, Goddess of forests, woodlands, flora & fauna, and fertility. April is known to the elves as 'Apristhra' Her holy symbol is a rampant unicorn or a unicorn's horn. April is variously depicted as an elven or human woman, and often associates with unicorns and other sylvan creatures. Deep within the Beastlands layer of Krigala is the Grove of the Unicorns, a realm she shares with the like-minded goddess Mielikki. April teaches that the animals and plants of the forests are gifts, and are not to be stolen. <b>You can instinctively identify plants and their uses.</b>",
    'Maia' : "Named after Maia, the hearth Goddess. Her holy symbol is a basket of grain and a long scroll. Maia is described as a quiet, gray-haired woman of faded beauty, carrying a basket of grain and holding a scroll, though her image in her temple in the village of Orlane is that of a beautiful young woman. Maia is obsessed with dates and cycles. Maia is a cousin of Xeries. Those born in her month are believed to be natural caretakers. <b>50/50 chance to tame non-hostile animals.</b>",
    'Yune' : "Named after Yuna, Goddess of magic, music, and arts. Her symbol is an eye in a pentagon; usually this is worn as an amulet. Yune is usually portrayed as a young woman with white hair wearing only the golden amulet bearing her symbol. Whether this is truth or fiction has not been verified. She is described as carrying the very first staff of the magi with her at all times. In addition, she knows every spell ever created and can travel to any time and dimension. She is the possessor of the only magical library that contains a copy of every potion, spell, and magic item in existence. <b>Start with a low spell scroll.</b>",
    'Jule' : "Named after Yul, a popular folklore hero. Yul became famous by escaping the slave plantation were she was born, and later returning with an army to crush her birth state. Fearful of her army many neighbooring city-states quickly drafted laws banning the trade of slaves. She is thought to be responsible for the nationwide ending of slavery. <b>Gain Master Die when attempting to escape bonds.</b>",
    'Barmhardt' : "King Barmhardt's Month. Previously dedicated to Aust, God of Death. Barmhardt is known for his generosity to those who share the month of his birth. <b>Start with a bonus 100 gold crowns.</b>",
    'Syvtember' : "Named for Syvtember, God of harvest, revels, wine, rogues, humor, and tricks. He is often called the Laughing Rogue. Syvtember is one of the more eccentric gods of Oerth. The Laughing Rogue is often involved in good-natured schemes involving the other gods (less good-natured for the more evil deities), with repercussions that can make life difficult for his faithful. He has few proper priests, but is held in high regard in almost all regions of the world. It is considered good luck to do business with those born in the Rogue's month. <b>Gain expert die when haggling with merchants.</b>",
    'Oktober' : "Named after Okt, the God of humor, building, noise, music, and defense. His symbol is an ornate but functional shield or breastplate. Okt is the union of storm and metal, a loud and boisterous guardian god obsessed with building and construction. He is the sound of metal on metal, or the roar of the summer rains on the roofs of shelters, or the happy songs sung by those who build and protect. He is depicted as a laughing man carrying a great shield and a cluster of saplings. Often thought as the month of craftsman. Many born in this month do go on to be architects. <b>Buildings built by or overseen by you cannot be set ablaze except in extreme circumstances and take longer to destroy.</b>",
    'Neuvember' : "The 11th month. No associated Dieties. With no divine protection, those born in the 11th month are thought to be unlucky. Maybe they are? Or maybe one will rise to claim the month as their own as Yul once did. <b>Unknown</b>",
    'Zehnember' : "Zehn. The final day. Kept by the God Xeries. His domain is over physical and mental mastery. His symbol is a copper disk. Xeries is the inventor of the Calendar, and records all events on a metallic wheel given to him by Okt. Xeries is depicted as an elderly man with young eyes, wearing a decaying animal pelt and carrying a great copper disk inscribed with runes. Those born in this month are believed to be recruited to Xeries' vanguard on the battle of the final day. <b>Escape death by failed saving throw one time.</b>"
}

export default items;