import React, { useState, useEffect } from 'react';
import './fades.module.css';

import { MenuItem, Select } from '@material-ui/core';

import occupationes from '../data/occupations.js';

export function SelectOccupation(props) {
    const { formData, setFormData } = props;
    const [infoText, setInfoText] = useState('')
    const [occupation, setOccupation] = useState(formData.occupation)
    const [options, setOptions] = useState([]);
    const [initLoad, setInitLoad] = useState(true);

    useEffect(() => {
        setOccupation(formData.occupation);

        let tempArr = [];
        let occupationChoices = Object.keys(occupationes);
        occupationChoices.forEach(element =>
            tempArr.push(<MenuItem key={Math.random()} value={element}>{element}</MenuItem>)
        );
        setOptions(tempArr);

        if (initLoad && occupation != '') {
            setInfoText(occupationes[occupation]);
        }
        setInitLoad(false);
    }, [formData.occupation]);


    const handleChange = (event) => {
        setFormData({ ...formData, occupation: event.target.value });
        setInfoText(occupationes[event.target.value])
    };

    return (
        <div>
            <Select value={occupation} onChange={handleChange} >
                {options ? options : <MenuItem></MenuItem>}
            </Select>
            {
                infoText.name && infoText ?
                    <div className="fancyBorder">
                        {infoText.descriptionSubType ? <div><b>Sub-disciplines: </b> {infoText.descriptionSubType}<br /> </div> : ''}
                        {infoText.description ? <p><b>Description:</b> {infoText.description}<br /></p> : ''}
                        {infoText.trainedWeapon ? <div><b>Trained Weapon:</b> {infoText.trainedWeapon}<br /> </div> : ''}
                        {infoText.tradeGoods ? <div><b>Trade Good: </b> {infoText.tradeGoods}<br /> </div> : ''}
                    </div> : ''
            }
        </div>

    )
}