import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        }
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function TheCombatRound(props) {
    return (
        <div style={{ width: '100%', overflowX: 'scroll', scrollbarWidth: 'thin' }}>
            <div className="row">
                <div className="columns ">
                    <Accordion>
                        <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Declare, Roll, Resolve</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <ol>
                                <li>
                                    <b>Declare</b>
                                    <p className="noPaddingParagraph">
                                        Everyone fighting states what action (or actions) they’re attempting. In a casual
                                        brawl you can often do this in whatever order it is wish. In a more serious or critically interesting
                                        match, the NPCs will declare first followed by whichever player wishes to go next and so on.
                                    </p>
                                </li>
                                <li>
                                    <b>Roll</b>
                                    <p className="noPaddingParagraph">
                                        Everyone rolls the appropriate dice pool. This is usually <b>Body+Fight,
                                            Coordination+Weapon, Coordination+Dodge or Body+Parry.</b>
                                    </p>
                                </li>
                                <li>
                                    <b>Resolve</b>
                                    <p className="noPaddingParagraph">
                                        The <b>Widest</b> set gets resolved first. If two sets are equally <b>Wide</b>, <b>Height</b> serves as a
                                        tiebreaker. If two sets are identical, they happen at the same time. If it’s illogical for them
                                        to be simultaneous, roll a die to see which one happens first.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        When an attack hits, it immediately does damage. As soon as a character gets hit, that
                                        character loses a die out one of his sets (his choice), because being injured is distracting.
                                        (In fact, you lose a die even if you take no damage.) If the set that loses a die is a pair, that
                                        action fails because, with one die lost, it’s no longer a set. (This is one reason it’s good to
                                        have two sets, even if you’re only trying one action: It gives you a backup success.)
                                    </p>
                                    <p className="noPaddingParagraph">
                                        Dodge and Parry rolls produce Gobble Dice (see above "Skill Checks" under "Opposed") but those dice can only apply to
                                        attacks that are timed after the Dodge or Parry. If the attack is Wider, it happens before
                                        you get a chance to react.
                                    </p>
                                </li>
                            </ol>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Combat Actions</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <p style={{ marginBottom: '20px', textIndent: '30px', width: '100%' }}>
                                There are seven standard combat actions. You can have a successful combat career using just Attack, Dodge, Move and Parry, but if you've got dice to burn, flavorful moves like Called Shots and Multiple Actions are available to you.
                            </p>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Attack</AccordionSummary>
                                <AccordionDetails style={{ display: 'grid' }}>
                                    <p className="noPaddingParagraph">
                                        All common, straightforward assaults ae resolved the same way. Your roll the pool (<b>Body+Fight</b>, or if you have a specific weapon skill <b>Coordination+[Weapon]</b>)
                                        and look for matches as described above. There are no difficulties, penalties, or special results. Just a swing and the damage from the weapon if you hit.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        The <b>Wider</b> your result, the sooner you act in the round. If you're up against someone with equal <b>Width</b> the <b>Higher</b> number resolves first. If both <b>Width</b> and <b>Height</b> tie, the action is simultaneous.
                                        <b> Height</b> determines what part of the other fellow you strike. The weapon type determines the type of damage (<b>Shock</b> or <b>Killing</b>) you do. Weapon type and
                                        <b> Width</b> together determine how much damage you do.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Dodge</AccordionSummary>
                                <AccordionDetails style={{ display: 'grid' }}>
                                    <p className="noPaddingParagraph">
                                        If you roll your <b>Coordination+Dodge</b> pool and get successes, you can either dive for cover when you action goes off, or you can move your body so that you
                                        aren't where the striking weapons are.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        You can only protect yourself by dodging: You can’t defend anyone else. You can,
                                        however, dodge when unarmed and out of armor.
                                    </p>
                                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                        Match sets from Dodge rolls become <b>Gobble Dice</b> (see above under Opposed Skill Checks). Each one can
                                        cancel out a die from a set. Dodging, you can apply <b>Gobble Dice </b>as long as
                                        <ol>
                                            <li>
                                                The <b>Width </b>
                                                of the Dodge roll lets you react in time
                                            </li>
                                            <li>
                                                and the <b>Gobble Die</b> has equal or greater Height
                                                than the attack die.
                                            </li>
                                        </ol>
                                    </p>
                                    <p className="noPaddingParagraph"><b>Example:</b></p>
                                    <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        <div style={{ maxWidth: '500px' }}>
                                            <p className="noPaddingParagraph">
                                                A man with six fingers pulls out a dagger. Hokoto, unarmed and with his back to a wall decides to dodge for a round.
                                            </p>
                                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                                {[6, 6, 3, 2, 1].map((v) => (
                                                    <Die key={Math.random()} value={v} />
                                                ))}
                                            </div>
                                            <p className="noPaddingParagraph">
                                                His attacker however gets a <b>3X1</b> on his roll to attack. <b> So while Hokoto's Gobble Dice are Higher
                                                    the 3X1 attack gets resolved first, and Hokoto's leg is sliced before he has a chance to react.
                                                </b>
                                            </p>
                                            <p className="noPaddingParagraph">
                                                Hokoto attempts to dodge again-
                                            </p>
                                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                                {[1, 1, 1, 2, 5].map((v) => (
                                                    <Die key={Math.random()} value={v} />
                                                ))}
                                            </div>
                                            <p className="noPaddingParagraph">
                                                The knife weilder gets a 2X8 on his roll however and Hokoto still gets hit. <b> He acts first, but his Gobble Dice are weak 1's and thus useless against the attackers
                                                    stout 8's
                                                </b>
                                            </p>
                                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                                {[6, 6, 4, 2, 1].map((v) => (
                                                    <Die key={Math.random()} value={v} />
                                                ))}
                                            </div>
                                            <p className="noPaddingParagraph">
                                                In the third round Hokoto gets lucky. The assailant strikes twice and rolls a <b>2X2</b> and a <b>2X5</b> to do so. Hokoto's dodge roll  yields a <b>2X6</b>.
                                                With his two <b>Gobble</b> 6's, he cancels one die from the attacker's <b>2X2</b> attack (reducing it to 1X2, which isn't a match) and another from the
                                                attacker's <b>2X5</b> attack, ruining both strikes with just one set. He flees.
                                            </p>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Parry</AccordionSummary>
                                <AccordionDetails style={{ display: 'grid' }}>
                                    <p className="noPaddingParagraph">
                                        Parrying is like dodging, except that instead of getting out of the way, you deflect the attack
                                        with something hard or interpose something between yourself and it. Knocking a sword
                                        away with a shield is a parry, as is blocking someone’s punch with the handle of a broom.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        If you’re normally dressed and not holding something that can block a blow (even once),
                                        you can only safely parry unarmed attacks. If you’re holding something that can absorb
                                        the force of a blow (though something like a chair might be destroyed in the process), you
                                        can parry, at least until your makeshift shield breaks.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        If you have nothing tough enough to stop a blow and you parry successfully anyhow, you
                                        redirect the blow to the parrying arm. It does full damage, but to your arm instead of (for
                                        example) your head.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        If you’re wearing armor of at least AR 2, you can parry all you want with your arms. If
                                        you’re wearing AR 1 armor, you can still parry, but you take a point of Shock damage to
                                        the parrying limb each time you successfully do so.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        Mechanics-wise, parrying works like dodging (above). Successful Parry dice become
                                        Gobble Dice and are used to wreck incoming attack sets. The difference is, you can parry to
                                        protect other people—but you can’t dive for cover with a Parry roll.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Move</AccordionSummary>
                                <AccordionDetails style={{ display: 'grid' }}>
                                    <p className="noPaddingParagraph">
                                        Everyone in a fight can move about 15 feet per round without penalty. If 15 feet isn’t enough, you can
                                        roll <b>Body+Run</b> and add another 5 feet of movement for each die in your set. Thus, if you
                                        get a 3x[some number] Run set, you can move another 15 feet.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        If you want to run while doing anything else, including attacking, it’s a multiple action as
                                        described below.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Aim</AccordionSummary>
                                <AccordionDetails style={{ display: 'grid' }}>
                                    <p className="noPaddingParagraph">
                                        Aiming is more commonly used with missile weapons, but it is possible to aim in hand
                                        to hand combat as well. Instead of launching an attack, you’re trying to improve your
                                        position for a later strike.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        <b>Penalty</b>: When you aim, spend one round without taking any attacking action. <b>It is
                                        possible to aim while avoiding damage.</b> This gives a –1d penalty to the Dodge or Parry roll.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        <b>Result</b>: If you attack the foe you aimed at next round, you can add one normal die to your
                                        pool, or offset a -1d penalty.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        It is possible to aim for two consecutive rounds and offset a -2d penalty (or add 2d to your
                                        pool) but any aiming after two rounds has no effect.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Called Shot</AccordionSummary>
                                <AccordionDetails style={{ display: 'grid' }}>
                                    <p className="noPaddingParagraph">
                                        The standard attack assumes you swing at whatever target presents itself. Sometimes,
                                        you may want to strike an arm to impair attacks, or hack a leg to prevent someone from
                                        fleeing, or aim at the head for a quick kill.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        These are all Called Shots. You can make these with hand-to-hand weapons or with
                                        missile attacks.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        <b>Penalty</b>:  Called shots are made with a –1d penalty to your attack pool. This is in addition
                                        to any other penalties.
                                    </p>
                                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                        <b>Result</b>: Take a die that remains in your pool and set it to a number corresponding to what
                                        you want to hit: 2 for a leg, 4 for an arm, 10 for the head. Roll the dice that remain, and if
                                        you get a match that hits that site, you succeed. (If you get a pair that indicates another
                                        location, you can opt to hit that or not, as you wish.) Essentially, you throw one die away
                                        to temporarily upgrade another into an Expert Die.
                                    </p>
                                    <p className="noPaddingParagraph"><b>Example:</b></p>
                                    <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        <div style={{ maxWidth: '500px' }}>
                                            <p className="noPaddingParagraph">
                                                The Morigaan aims at the outrider's arm with her shortbow. Her pool is normally 5d. She loses one die for the
                                                penalty, leaving her four. Of those four, she sets her expert die to hit location 6 (a right arm location). She
                                                rolls the remaining three dice.
                                            </p>
                                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                                {[6, 6, 3].map((v) => (
                                                    <Die key={Math.random()} value={v} />
                                                ))}
                                            </div>
                                            <p className="noPaddingParagraph">
                                                Her bow eases and the arrow flies true, the standard bearer at the front of the approaching army drops his flag.
                                            </p>
                                        </div>
                                    </div>
                                    <p className="noPaddingParagraph">
                                        <b>Note</b>: If you already have an Expert Die in your pool, called shots can’t add another
                                        Expert Die. If you have an ED you’re always making a called shot with no penalty.
                                        Similarly, you can’t do called shots with a Master Die because rolling with an MD is
                                        already better than making a called shot. (Under no circumstances can you have more
                                        than one special die—either MD or ED—in your pool.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Multiple Actions</AccordionSummary>
                                <AccordionDetails style={{ display: 'grid' }}>
                                    <p className="noPaddingParagraph">
                                        This was mentioned above, but will be re-explained here. Whenever you try to do two or more things in a combat round, it’s a multiple action.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        Swinging at two opponents, firing at one opponent twice, dodging and attacking
                                        simultaneously... all these are handled with the same multiple action rules.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        <b>Penalty</b>: If you’re using one Skill two times, you roll the pool with a -1d penalty and hope
                                        you get two matches. If you want to use two different Skills, you use the smaller pool,
                                        again with the penalty.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        Sometimes, your smaller pool has an ED or an MD while the larger pool does not. If you
                                        manage to keep that special die you must assign at
                                        least one set to that Skill, if you get any.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        To use a Skill three times, roll the pool with a -2d penalty. Using three different Skills,
                                        use the smallest pool, drop two dice and look for three matches. You use the smallest pool
                                        concerned, take a -1d penalty for every action past the first. Don't forget you need a set for every action.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        <b>Result</b>:  If you get a number of sets equal to the number of actions you’re attempting, you
                                        can assign them as you wish. <b>The only restriction on this is that if one of your actions
                                            makes another possible, you have to assign a faster set to the one that goes off first.</b>
                                    </p>
                                    <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        <div style={{ maxWidth: '500px' }}>
                                            <p className="noPaddingParagraph">
                                                Rush eyes down Thubor and the two guards standing between them. With a <b>Body+Fight</b> pool of 10d, Rush
                                                decides to make three attacks. This knocks his pool down to 8d.
                                            </p>
                                            <p className="noPaddingParagraph">
                                                He gets a <b>4X10</b> and a <b>2X5</b>. But Rush can't hit Thubor until the guards are out of the way.
                                                Splitting the <b>4X10</b> into two <b>2X10</b> pairs, he makes a plan to hit each of the guards in the head and clear a path for his lunge at
                                                Thubor's arm. He might have wanted to apply one of those <b>2X10</b>'s to Thubor's head, but a lesser blow on one of the bodyguards might leave them
                                                in the fight, and Rush wouldn't be able to make his third attack at all.
                                            </p>
                                            <p className="noPaddingParagraph">
                                                The guards get no matches each and go down. Thubor gets a <b>3X1</b>, but his <b>Gobble Die</b> are puny compared to Rush's <b>5's</b>. Thus he is hit. More guards arrive so Rush
                                                makes the hasty decision to flee into the night.
                                            </p>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}