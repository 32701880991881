import React from "react";
import styles from '../../css/colors.module.css';
import Die from "../components/die";

import { StatsSkillsAndAdvantagesTool } from "../components/StatsSkillsAndAdvantagesTool";

export class StatsSkillsAdvantages extends React.Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="columns terms">
            <h4 className="reallyHeavyWords">Stats, Skills, and Advantages</h4>
            <label>Congrats! You've almost got everything sorted. Now all thats left are your <i>Stats</i>. </label>
            <p>
              Below is brief summary of what exactly stats, skills, and advantages are, and how these numbers will correlate to the fancy dice numbers you'll be seeing as you're playing. And also a quick intro into Expert and Master dice.
            </p>
            <div className="columns terms" style={{ marginBottom: '20px' }}>
              <div className="diceBox">
                {[1, 2, 3, 4, 5, 6, 1].map((v) => (
                  <Die key={Math.random()} value={v} />
                ))}
              </div>
            </div>
            <ul className="docs-terms">
              <li>
                <span className={styles.red}>Stats</span>, <span className={styles.green}>Skills</span>, and <span className={styles.cyan}>Advantages </span> are the core of the game. They are gained via a point buy system. This is that system. The point buy works like this:
                You'll start with 85 points. Point which you can spend anywhere and any way you please.
                <ul>
                  <li>
                    You can increase your <span className={styles.green}>Skills</span> at a cost of <large>5</large> points per level. <span className={styles.red}>Stats </span> are: Body, Coordination, Sense, Knowledge, Command, and Charm. They measure your general talent in a field. Each level equals on additional die you will get to roll when rolling a skill check under that pool.
                  </li>
                  <li>
                    <span className={styles.cyan}>Advantages </span> are unique, and the cost of each is layed out on an individual basis next to the description.
                  </li>
                  <li>
                    <span className={styles.blue}>Expert Die</span>: This special kind of Skill die indicates unusual talent. Instead of rolling an <span className={styles.blue}>Expert Die</span> like a normal Die, you set it to whatever number you want before you roll the other dice in your pool. You can only have one <span className={styles.blue}>Expert Die</span> per pool. You cannot combine Expert and <span className={styles.pink}>Master Dice</span> in the same roll.
                  </li>
                  <li>
                    <span className={styles.pink}>Master Dice</span>: <span className={styles.pink}>Master Dice</span> indicate tremendous proficiency. You don't roll a <span className={styles.pink}>Master Die</span>. You set it to any number you want. However, unlike an <span className={styles.blue}>Expert Die</span>, you roll your normal pool of dice <i>before</i> you set your <span className={styles.pink}>Master Die</span>. This means that having a <span className={styles.pink}>Master Die</span> always garuntees a set. You can only have one <span className={styles.pink}>Master Die</span> per pool and cannot combine it with an <span className={styles.blue}>Expert Die</span>.
                  </li>
                  <li><b>Note</b> that it's possible to have only one die in a <span className={styles.green}>Skill</span> and have that be a <span className={styles.pink}>Master Die</span>. That character is skilled, but in a narrow way - they perform excellently under the circumstances for which they were trained, but they are poorly equipped to deal with upredictable obstacles. </li>
                </ul>
                <div className="six columns">
                  <small>So just to re-iterate:</small>
                  <ul>
                    <li><b>Increasing a <span className={styles.red}>Stat</span> by 1: </b>5 points.</li>
                    <li><b>Increasing a <span className={styles.green}>Skill</span> by 1: </b>1 point.</li>
                    <li><b>Aquiring an  <span className={styles.cyan}>Advantage</span>: </b>Varies, but the cost is listed.</li>
                    <li><b>Promoting a Normal <span className={styles.green}>Skill</span> to an <span className={styles.blue}>Expert Die</span>: </b>1 point.</li>
                    <li><b>Promoting an <span className={styles.blue}>Expert Die</span> to a <span className={styles.pink}>Master Die</span>: </b>5 points.</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
        </div>
        <StatsSkillsAndAdvantagesTool setFormData={this.props.setFormData} formData={this.props.formData} />
      </div>
    );
  }
}
