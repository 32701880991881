import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function ExperiencePoints(props) {
    return (
        <div>
            <p className="noPaddingParagraph">
                At the end of every game session, you get experience points, abbreviated as XP. You can
                earn them different ways, and you can spend them to make your character better.
            </p>
            <span className="heavyWords">Earning XP</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                <b>Attendence:</b> Show up for a game session, get 1XP.
            </p>
            <p className="noPaddingParagraph">
                <b>Thespianism:</b> Play your character so engagingly that your GM or fellow players are
                tempted to burst into spontaneous applause, you get another XP.
            </p>
            <p className="noPaddingParagraph">
                <b>Problems:</b> As described under “Problems” on the Character Creator, you get an extra XP any session in
                which one of your self-inflicted troubles harms you.
            </p>
            <p className="noPaddingParagraph">
                <b>Dramatic Plot Alteration:</b> Any session with a climactic (or abysmal) plot event is worth
                an XP, if it was the PCs who spurred this event. This could kick in when you finally
                overcome a hated foe, or when you conclude some lengthy plot episode. This is not an
                award you can expect every session, but it does go to every player present.
            </p>
            <p style={{ marginBottom: '20px' }}>
                You can expect to earn between 1–5 XP per session, likely about 2–3.
            </p>
            <span className="heavyWords">Spending XP</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                <b>Improving Skills:</b> You can permanently raise a Skill by spending XP equal to its new level.
                If I raise my Direction Skill from 3 to 4, it costs me 4 XP. (You can’t skip levels—to raise a
                Skill from 1 to 4, you have to first raise it to 2, then 3, then 4, spending a total of 9 XP.)
            </p>
            <p className="noPaddingParagraph">
                Promoting a Skill die to an Expert Die costs 1 XP. Promoting an Expert Die to Master costs 5 XP.
            </p>
            <p className="noPaddingParagraph">
                Spending XP, you can raise a Skill to 6, but no higher. If this gives you a pool bigger than
                10, you don’t roll more than 10 dice. Instead, you use the overflow to offset penalties.
            </p>
            <p className="noPaddingParagraph">
                <b>Improving Stats:</b> You can permanently raise a Stat by paying 10 XP. Every 10 XP
                you spend raises the Stat one level. You can raise your Stats to 6 using XP, with the
                implications for pools above ten described above.
            </p>
            <p className="noPaddingParagraph">
                It’s the same with Martial Techniques and Esoteric Disciplines. Their XP cost is the same
                as their cost at character generation, but in play you cannot learn them without being
                taught. (If you take them at character generation, you can teach them to the other PCs.)
            </p>
            <p className="noPaddingParagraph">
                The flip side is that the GM may give you Advantages for free. If you organize the retreat
                from a devastated town, you could get some followers from the deal. Or if you engineer a
                brilliant heist, your Wealth can go up.
            </p>
            <p style={{ marginBottom: '20px' }}>
                You can never gain the Wealth or Possession Advantages by spending XP. Your GM might
                allow it in specialized circumstances, but by and large those things have to be gained and
                lost through play.
            </p>
        </div>
    )
}