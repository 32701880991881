import React, { useState } from "react";
import Select from 'react-select';

import { FontBackground } from "./assets/js/components/FontBackground";
import wiki from './assets/js/data/Wiki';

import { Navigation } from "./Navigation";

/* 
Exerpts from books. Each wiki entry is written like it
was grabbed from a book and is signed by it's unique author
some authors are biased in one way or another
Religious Texts
Magical Treatsies
Encyclopedia No Arracht (Monsters and Creatures)
Historic Events
Travel Guides (Settlements, Towns, and Houses)
Poems, Lyrea, and Musings
Official Decrees (Political Structure, War)
On Fashion and Combat
Farming, Animal Husbandry, and Crafte
Festivals
**/
export const Library = (props) => {
    const [infoText, setInfoText] = useState('');

    const changeSearch = (event) => { setInfoText(event.year) }

    return (
        <div>
            <Navigation />
            <div style={{ minHeight: '70vh' }}>
                <FontBackground />
                <div className="container" style={{ marginBottom: '15px' }}>
                    <div style={{ paddingLeft: 'clamp(0px, 5%, 20px)', paddingRight: 'clamp(0px, 15%, 90px)', marginTop: '15px', marginBottom: '20px' }}>
                        <p style={{ marginBottom: "10px", textIndent: '30px' }}>
                            Wind whistles through the cracks of weather worn boards in a small building on the edge of the town of Saltmarsh.
                            The lilting sound of crinkling paper rises and falls like the quiet breath of a very old, very tired man. Above, sunlight beads and drips
                            like dew through a number of small holes in a forelorn roof.
                        </p>
                        <p style={{ marginBottom: "10px", textIndent: '30px' }}>
                            The floor is bare, and there are no shelves. Instead, pinned with a variety of nails,
                            staples and Gods only know what else are words wrapped in all manner of housing. Sheepskins from Toth, reed Papyrus from the Dajh Plains, and bleached white paper from the Norigrad City Presses.
                            Polished slate stone tablets from Tirith with their block style cuniform and obsidian scrolls produced by the University of Cashel. Rare and strange cobalt Ice Skins from the Twilight Forest.
                        </p>
                        <p style={{ marginBottom: "10px", textIndent: '30px' }}>
                            Each page here is an excerpt. The rest lost or ruined over the course of a plethora of dusty travels and damp voyages. Pinned here by hopeful settlers and homesteaders that they might one day offer some help to
                            the strangers who follow them.
                        </p>
                        <p style={{ marginBottom: "10px", textIndent: '30px' }}>
                            Outside the building reads a faded sign. 'Library of Saltmarsh'.
                        </p>
                    </div>

                    <div className="row">
                        <div className="two columns" style={{ minWidth: '100px' }}>
                            <label>Search:</label>
                        </div>
                        <div className="six columns">
                            <Select
                                styles={{ maxWidth: '100px', padding: '5px' }}
                                className="basic-single"
                                classNamePrefix="Search"
                                isClearable={false}
                                isSearchable={true}
                                name="Search"
                                placeholder=""
                                options={wiki}
                                onChange={changeSearch}
                            />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="diceBox">
                        <p style={{ maxWidth: '600px' }} dangerouslySetInnerHTML={{ __html: infoText }}></p>
                    </div>
                </div>
            </div>
        </div>
    );
}