/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './fades.module.css';
import Die from "./die";

import { MenuItem, Select } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';

//todo: 5. Make the finish page look nice, and make the exported pdf look sexy too

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 42,
        fontWeight: '800',
        color: '#F0F2EF',
        textTransform: 'uppercase',
        borderTopLeftRadius: '11px',
        borderTopRightRadius: '11px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        letterSpacing: -2,
        minHeight: 75,
        '&$expanded': {
            minHeight: 75,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function StatsSkillsAndAdvantagesTool(props) {
    const { formData, setFormData } = props;

    //For adding new skills to the skills grid
    const [baseAdvantageTable, setBaseAdvantageTable] = useState([])
    const [bodyTable, setBodyTable] = useState([])
    const [coordinationTable, setCoordinationTable] = useState([])
    const [senseTable, setSenseTable] = useState([])
    const [knowledgeTable, setKnowledgeTable] = useState([])
    const [commandTable, setCommandTable] = useState([])
    const [charmTable, setCharmTable] = useState([])

    const [points, setPoints] = useState(formData.totalPoints)

    useEffect(() => {
        setFormData({ ...formData, availablePoints: points });
    },[points])

    useEffect(() => {
        let baseAdvantages = formData.advantages;
        let baseBody = formData.skills.Body;
        let baseCoordination = formData.skills.Coordination;
        let baseSense = formData.skills.Sense;
        let baseKnowledge = formData.skills.Knowledge;
        let baseCommand = formData.skills.Command;
        let baseCharm = formData.skills.Charm;
        let tempAdvantageArr = [];
        let tempBodyArr = [];
        let tempCoordinationArr = [];
        let tempSenseArr = [];
        let tempKnowledgeArr = [];
        let tempCommandArr = [];
        let tempCharmArr = [];

        Object.keys(baseAdvantages).forEach(key => {
            tempAdvantageArr.push(<OneLineAdvantages key={Math.random()} points={points} setPoints={setPoints} object={baseAdvantages[key]} formData={formData} setFormData={setFormData}></OneLineAdvantages>)
        });
        setBaseAdvantageTable(tempAdvantageArr);
        Object.keys(baseBody).forEach(key => {
            tempBodyArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseBody[key]} category="Body" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setBodyTable(tempBodyArr);
        Object.keys(baseCoordination).forEach(key => {
            tempCoordinationArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseCoordination[key]} category="Coordination" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setCoordinationTable(tempCoordinationArr);
        Object.keys(baseSense).forEach(key => {
            tempSenseArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseSense[key]} category="Sense" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setSenseTable(tempSenseArr);
        Object.keys(baseKnowledge).forEach(key => {
            tempKnowledgeArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseKnowledge[key]} category="Knowledge" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setKnowledgeTable(tempKnowledgeArr);
        Object.keys(baseCommand).forEach(key => {
            tempCommandArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseCommand[key]} category="Command" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setCommandTable(tempCommandArr);
        Object.keys(baseCharm).forEach(key => {
            tempCharmArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseCharm[key]} category="Charm" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setCharmTable(tempCharmArr);
    }, [formData]);

    return (
        <div style={{ marginBottom: '30px' }}>
            <div className="row">
                <div style={{ textAlign: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', fontSize: '2.5em', fontFamily: 'Impact, fantasy', padding: '30px' }}>
                    <u>{points}  </u> <span style={{ paddingLeft: '10px' }}> Point(s) Remaining</span>
                </div>
            </div>
            <div>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary style={{ backgroundColor: '#4F735B' }} expandIcon={<ExpandMore />}>Body</AccordionSummary>
                    <AccordionDetails>
                        <IndividualTables tableName="Body" points={points} setPoints={setPoints} table={bodyTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#AC8B12' }} expandIcon={<ExpandMore />}>Co-ordination </AccordionSummary>
                    <AccordionDetails>
                        <IndividualTables tableName="Coordination" points={points} setPoints={setPoints} table={coordinationTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#8D7576' }} expandIcon={<ExpandMore />}>Sense</AccordionSummary>
                    <AccordionDetails>
                        <IndividualTables tableName="Sense" points={points} setPoints={setPoints} table={senseTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#A63C30' }} expandIcon={<ExpandMore />}>Knowledge</AccordionSummary>
                    <AccordionDetails>
                        <IndividualTables tableName="Knowledge" points={points} setPoints={setPoints} table={knowledgeTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#066B9D' }} expandIcon={<ExpandMore />}>Command</AccordionSummary>
                    <AccordionDetails>
                        <IndividualTables tableName="Command" points={points} setPoints={setPoints} table={commandTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#D67B71' }} expandIcon={<ExpandMore />}>Charm</AccordionSummary>
                    <AccordionDetails>
                        <IndividualTables tableName="Charm" points={points} setPoints={setPoints} table={charmTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#011936' }} expandIcon={<ExpandMore />}>Advantages</AccordionSummary>
                    <AccordionDetails>
                        <div className="row" style={{ width: '100%', maxWidth: '90vw', overflowX: 'scroll', scrollbarWidth: 'thin', marginBottom: '10px' }}>
                            <div className="columns" style={{ marginLeft: "0px", marginBottom: "10px" }}>
                                <table style={{ width: '100%' }} >
                                    <tr>
                                        <th>Name</th>
                                        <th style={{ minWidth: "350px" }} >Description</th>
                                        <th>Cost</th>
                                        <th>Taken</th>
                                        <th>Take</th>
                                        <th>Remove</th>
                                    </tr>
                                    {baseAdvantageTable ? baseAdvantageTable : <></>}
                                </table>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#F0F2EF', color: '#311E10' }} expandIcon={<ExpandMore />}>Custom Skills</AccordionSummary>
                    <AccordionDetails>
                        <AddSkill points={points} setPoints={setPoints} setFormData={setFormData} formData={formData} ></AddSkill>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export function IndividualTables(props) {
    const { tableName, table, points, setPoints, formData, setFormData } = props;

    const handleSubmitAdd = (event) => {
        event.stopPropagation();
        let oldStats = formData.stats;
        const newPoints = points - 5;
        if (oldStats[event.target.id].number + 1 > 5) {
            console.log('Slow down there bud, this here is just the stat. (Max 5)');
        } else if (newPoints < 0) {
            console.log("What're you doin' ya helmet? You can't afford that!");
        } else {
            oldStats[event.target.id].number += 1;
            let arr = []
            for (let i = 1; i <= oldStats[event.target.id].number; i++) {
                arr.push(i);
            }
            oldStats[event.target.id].numDice = arr;
            setPoints(newPoints);
            setFormData({ ...formData, stats: oldStats });
        }
    }

    const handleSubmitRemove = (event) => {
        event.stopPropagation();
        let oldStats = formData.stats;
        const newPoints = points + 5;
        if (oldStats[event.target.id].number - 1 < 1) {
            console.log('Gotta have at least 1 skill boyo. ');
        } else {
            oldStats[event.target.id].number -= 1;
            let arr = []
            for (let i = 1; i <= oldStats[event.target.id].number; i++) {
                arr.push(i);
            }
            oldStats[event.target.id].numDice = arr;
            setPoints(newPoints)
            setFormData({ ...formData, stats: oldStats });
        }
    }

    return (
        <div className="row" style={{ width: '100%', maxWidth: '100wh', overflowX: 'scroll', scrollbarWidth: 'thin', marginBottom: '10px' }}>
            <div className="columns" style={{ marginLeft: "0px", marginBottom: "10px", background: '#f8f8f8', borderRadius: '15px' }}>
                <table style={{ width: '100%', paddingLeft: '30px', paddingRight: '30px' }} >
                    <thead>
                        <th>Add or Subtract Dice</th>
                        <th style={{ textAlign: 'center' }}>Dice Tracker</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="plusminus horiz">
                                    <input type="button" value='-' style={{ right: '0' }} id={formData.stats[tableName].name} onClick={handleSubmitRemove} />
                                    <input type="number" style={{ background: '#f8f8f8' }} name="" value={formData.stats[tableName].number} min="1" max="5" disabled />
                                    <input type="button" value='+' id={formData.stats[tableName].name} onClick={handleSubmitAdd} />
                                </div>
                            </td>
                            <td>
                                <div className="diceBox">
                                    {
                                        formData.stats[tableName].numDice.map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <b>
                        {formData.stats[tableName].description ? formData.stats[tableName].description : ''}
                    </b>
                    <hr></hr>
                </div>
            </div>
            <div className="columns" style={{ marginLeft: "0px", marginBottom: "10px" }}>
                <table style={{ width: '100%' }} >
                    <tr>
                        <th>Name</th>
                        <th style={{ minWidth: "350px" }} >Description</th>
                        <th>+ / -</th>
                        <th>Dice Tracker</th>
                        <th>Expert-Master</th>
                        <th>Delete</th>
                    </tr>
                    {table ? table : <></>}
                </table>
            </div>
        </div>
    )
}

export function OneLineSkills(props) {
    const { object, category, points, setPoints, formData, setFormData } = props;

    const handleSubmitAdd = (event) => {
        event.stopPropagation();
        let oldSkills = formData.skills;
        const newPoints = points - 1;
        if (oldSkills[category][event.target.id].number + 1 > 5) {
            console.log('Slow down there bud, this here is just the stat. (Max 5)');
        } else if (newPoints < 0) {
            console.log("What're you doin' ya helmet? You can't afford that!");
        } else {
            oldSkills[category][event.target.id].number += 1;
            let arr = []
            for (let i = 1; i <= oldSkills[category][event.target.id].number; i++) {
                arr.push(i);
            }
            oldSkills[category][event.target.id].numDice = arr;
            setPoints(newPoints);
            setFormData({ ...formData, skills: oldSkills });
        }
    }

    const handleSubmitRemove = (event) => {
        event.stopPropagation();
        let oldSkills = formData.skills;
        const newPoints = points + 1;
        if (oldSkills[category][event.target.id].number - 1 < 1) {
            console.log('Gotta have at least 1 skill boyo. ');
        } else {
            oldSkills[category][event.target.id].number -= 1;
            let arr = []
            for (let i = 1; i <= oldSkills[category][event.target.id].number; i++) {
                arr.push(i);
            }
            oldSkills[category][event.target.id].numDice = arr;
            setPoints(newPoints)
            setFormData({ ...formData, skills: oldSkills });
        }
    }

    const changeExpert = (event) => {
        event.stopPropagation();
        let oldSkills = formData.skills;
        if (oldSkills[category][event.target.id].expertOrMaster == 1) {
            oldSkills[category][event.target.id].expertOrMaster = 0;
            const newPoints = points + 1;
            setPoints(newPoints)
            setFormData({ ...formData, skills: oldSkills });
        } else if (oldSkills[category][event.target.id].expertOrMaster == 6) {
            oldSkills[category][event.target.id].expertOrMaster = 1;
            const newPoints = points + 5;
            setPoints(newPoints)
            setFormData({ ...formData, skills: oldSkills });
        } else if (oldSkills[category][event.target.id].expertOrMaster == 0 && points - 1 >= 0) {
            oldSkills[category][event.target.id].expertOrMaster = 1;

            const newPoints = points - 1;
            if (newPoints < 0) {
                console.log('Not enough caps');
            } else {
                setPoints(newPoints)
                setFormData({ ...formData, skills: oldSkills });
            }
        }
    };

    const changeMaster = (event) => {
        event.stopPropagation();
        let oldSkills = formData.skills;
        if (oldSkills[category][event.target.id].expertOrMaster == 6) {
            oldSkills[category][event.target.id].expertOrMaster = 0;
            const newPoints = points + 6;
            setPoints(newPoints)
            setFormData({ ...formData, skills: oldSkills });
        } else if (oldSkills[category][event.target.id].expertOrMaster == 1 && points - 5 >= 0) {
            oldSkills[category][event.target.id].expertOrMaster = 6;
            const newPoints = points - 5;
            setPoints(newPoints)
            setFormData({ ...formData, skills: oldSkills });
        } else if (oldSkills[category][event.target.id].expertOrMaster == 0 && points - 6 >= 0) {
            oldSkills[category][event.target.id].expertOrMaster = 6;

            const newPoints = points - 6;
            if (newPoints < 0) {
                console.log('Not enough caps');
            } else {
                setPoints(newPoints)
                setFormData({ ...formData, skills: oldSkills });
            }
        }
    };

    const handleSubmitDelete = (event) => {
        event.stopPropagation();
        let oldSkills = formData.skills;
        const newPoints = points + oldSkills[category][event.target.id].number + oldSkills[category][event.target.id].expertOrMaster;
        delete oldSkills[category][event.target.id];
        setPoints(newPoints)
        setFormData({ ...formData, skills: oldSkills });
    };


    return (
        <tr>
            <td><b>{object.displayName ? object.displayName : object.name}</b></td>
            <td>{object.description}</td>
            <td>
                <div className="plusminus">
                    <input type="button" value='-' id={object.name} onClick={handleSubmitRemove} />
                    <input type="number" name="" value={object.number} min="1" max="5" disabled />
                    <input type="button" value='+' id={object.name} onClick={handleSubmitAdd} />
                </div>
            </td>
            <td>
                <div className="diceBox">
                    {object.numDice ?
                        object.numDice.map((v) => (
                            <Die key={Math.random()} value={v} />
                        )) : ''
                    }
                </div>
            </td>
            <td>
                <div style={{ display: 'flex' }}>
                    <input type="checkbox" className="largeCheckbox" checked={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? true : false} id={object.name} value={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? true : false} onChange={changeExpert} style={{ marginRight: '20px' }} />
                    <input type="checkbox" className="largeCheckbox" checked={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? false : true} id={object.name} value={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? false : true} onChange={changeMaster} />
                </div>
            </td>
            <td>
                {object.displayName ? <input type="button" value='Delete' id={object.name} onClick={handleSubmitDelete} /> : ""}
            </td>
        </tr>
    )
}

export function OneLineAdvantages(props) {
    const { object, points, setPoints, formData, setFormData } = props;

    const handleSubmitTake = (event) => {
        event.stopPropagation();
        let oldAdvantages = formData.advantages;
        const newPoints = points - oldAdvantages[event.target.id].cost;
        if (oldAdvantages[event.target.id].taken === true) {
            console.log('Already took that mate');
        } else if (newPoints < 0) {
            console.log("What're you doin' ya helmet? You can't afford that!");
        } else {
            oldAdvantages[event.target.id].taken = true;
            setPoints(newPoints)
            setFormData({ ...formData, advantages: oldAdvantages });
        }
    }

    const handleSubmitRemove = (event) => {
        event.stopPropagation();
        let oldAdvantages = formData.advantages;
        const newPoints = points + oldAdvantages[event.target.id].cost;
        if (oldAdvantages[event.target.id].taken === true) {
            oldAdvantages[event.target.id].taken = false;
            setPoints(newPoints)
            setFormData({ ...formData, advantages: oldAdvantages });
        } else if (newPoints < 0) {
            console.log("What're you doin' ya helmet? You can't afford that!");
        } else {
            console.log("You don't have that advantage to give up.");
        }
    }

    return (
        <tr>
            <td><b>{object.name}</b></td>
            <td>{object.description}</td>
            <td>{object.cost}</td>
            <td>{object.taken === true ? 'Yes' : ''}</td>
            <td>
                <input type="button" value='Take' id={object.id} onClick={handleSubmitTake} />
            </td>
            <td>
                {object.taken ? <input type="button" value='Remove' id={object.id} onClick={handleSubmitRemove} /> : ""}
            </td>
        </tr>
    )
}

export function AddSkill(props) {
    const { points, setPoints, formData, setFormData } = props;

    //States for the select input
    const [options, setOptions] = useState([]);
    const [customSkill, setCustomSkill] = useState({})
    const [infoText, setInfoText] = useState('')
    const [customSkills, setCustomSkills] = useState(formData.customSkillsTemplates)

    //User defined states
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        let tempArr = [];
        Object.keys(customSkills).forEach(element => {
            tempArr.push(<MenuItem key={Math.random()} value={customSkills[element].id}>{customSkills[element].name}</MenuItem>)
        });
        setOptions(tempArr);
    }, []);

    const handleChange = (event) => {
        setCustomSkill(customSkills[event.target.value]);
        setInfoText(customSkills[event.target.value].description)
    };

    const handleSubmit = (event) => {
        event.stopPropagation();
        if (name != '' && description != '' && JSON.stringify(customSkill) != '{}') {
            let oldSkills = formData.skills;
            let userDefinedName = name.replace(/\s/g, '');
            let tempName = customSkill.id.toLowerCase() + userDefinedName;
            if (customSkill.category === "Coordination") {
                oldSkills.Coordination[tempName] = {
                    'name': tempName,
                    'displayName': name,
                    'description': description,
                    'number': 1,
                    'modifier': 0,
                    'expertOrMaster': 0,
                    'numDice': [1]
                }
            } else if (customSkill.category === "Knowledge") {
                oldSkills.Knowledge[tempName] = {
                    'name': tempName,
                    'displayName': name,
                    'description': description,
                    'number': 1,
                    'modifier': 0,
                    'expertOrMaster': 0,
                    'numDice': [1]
                }
            }
            setPoints(points - 1);
            setName('');
            setDescription('');
            setFormData({ ...formData, skills: oldSkills });
        } else {
            console.log('Unknown Skill Category', customSkill.category);
        }
    };

    const changeName = (event) => { setName(event.target.value); }
    const changeDescription = (event) => { setDescription(event.target.value); }

    return (
        <div className="columns" style={{ marginLeft: "0px", marginBottom: "10px", background: '#f8f8f8', borderRadius: '15px', borderBottom: '1px solid #8d7576', padding: '10px' }}>
            <form onKeyDown="return event.key != 'Enter';">
                <div className="row" style={{ display: 'grid', marginTop: '20px', marginBottom: '10px' }}>
                    <div className="four columns">
                        <label>Skill Type: </label>
                    </div>
                    <div className="four columns">
                        <Select value={customSkill.id || ''} onChange={handleChange} >
                            {options ? options : <MenuItem></MenuItem>}
                        </Select>
                    </div>
                </div>
                <div className="row" style={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '20px' }}>
                    {infoText ? infoText : ""}
                </div>
                <div className="row">
                    <div className="four columns">
                        <label>Skill Name: </label>
                    </div>
                    <div className="four columns">
                        <input type="text" placeholder={customSkill.category === "Coordination" ? "Pitchforks, Juggling, etc..." : "Mime, Herptology, etc..."} value={name} onChange={changeName} />
                    </div>
                </div>
                <div className="row">
                    <div className="four columns">
                        <label>Description: </label>
                    </div>
                    <div className="four columns">
                        <textarea
                            className="u-full-width"
                            placeholder={'Please write a brief description of the skill'}
                            style={{ minHeight: '100px' }}
                            value={description}
                            onChange={changeDescription}
                        />
                    </div>
                </div>
            </form >
            <div className="four columns">
                <input type="button" value="Add" onClick={handleSubmit} />
            </div>
        </div >
    )
}