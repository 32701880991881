const items = {
    'absoluteDirection': {
        'name': `Absolute Direction`,
        'id': 'absoluteDirection',
        'cost': 1,
        'description': `You always know which way is north, and you can always re-trace a path you have followed within the past month, no matter how faint or confusing it may be. This ability does not work in environments such as interstellar space, the limbo of the astral plane, etc., but it does work underground, underwater, and on other planets.`,
        'taken': false,
    },
    'absoluteTiming': {
        'name': `Absolute Timing`,
        'id': 'absoluteTiming',
        'cost': 1,
        'description': `You have an accurate mental clock. Unless you have been knocked unconscious, hypnotized, or otherwise interfered with, you always know what time it is, down to the second. You can also measure any elapsed time with equal accuracy. Sleep does not interfere with this (and you can wake up at a predetermined time if you choose). Changes of time zone also have no effect. Time travel will confuse you until you find out what the “new” time is.`,
        'taken': false,
    },
    'animalCompanion': {
        'name': `Animal Companion`,
        'id': 'animalCompanion',
        'cost': 2,
        'description': `You’ve got a dog, a hunting falcon or some other trained animal. It helps you out. If you want to be a knight with an outstanding horse, take it as a companion. You can just purchase a horse, but Animal Companions are part of your character. Your GM can callously slay any bought animal to advance the plot. Animal Companions can’t be so summarily dismissed. They aren’t invulnerable—take it with you when you storm the castle and you’re taking your chances—but their loss should mean something within the terms of the plot.`,
        'taken': false,
    },
    'beauty1': {
        'name': `Beauty - I`,
        'id': 'beauty1',
        'cost': 1,
        'description': `If you take one point in this, you’re the prettiest boy in your village or someone who gets a second look on the street. In game terms, any time you roll a low success with your Fascinate or Graces Skills, there’s a minimum Height to your set. If you roll under it, your Beauty raises it. The new level depends on how much you spend on your Beauty Advantage. [1: 3, 3: 7, 5: 10] Suppose you have a 5d Charm+Fascinate pool and you have the 1 point Beauty Advantage. Chatting up the archduchess, you roll a 2x1. Normally that would make you mildly diverting. But because you’re beautiful, it’s treated at a 2x3. If you had the 5 point version of the Advantage, it would act like a 2x10 success, keeping her absolutely enthralled`,
        'taken': false,
    },
    'beauty3': {
        'name': `Beauty- III`,
        'id': 'beauty3',
        'cost': 3,
        'description': `At three points of beauty a decadent noble might want to marry or abduct you. (See above for how this works in game Terms, only one Beautiful advantage may be taken, any additional are ignored)`,
        'taken': false,
    },
    'beauty5': {
        'name': `Beauty- V`,
        'id': 'beauty5',
        'cost': 5,
        'description': `Five points of beauty gets you a face that can launch a thousand ships. (See above for how this works in game Terms, only one Beautiful advantage may be taken, any additional are ignored)`,
        'taken': false,
    },
    'cannibalSmile': {
        'name': `Cannibal Smile`,
        'id': 'cannibalSmile',
        'cost': 1,
        'description': ` You have strong jaws and sharp teeth. With a successful Body+Fight roll, you can bite someone for Width Shock damage, 1 Killing damage. You can also add 1d to your Intimidate pool when scaring someone who knows you’re a biter.`,
        'taken': false,
    },
    'dangerSense': {
        'name': `Danger Sense`,
        'id': 'dangerSense',
        'cost': 3,
        'description': `You can’t depend on it, but sometimes you get this prickly feel-ing right at the back of your neck, and you know something’s wrong... If you have Danger Sense, the GM rolls once against your SENSE, secretly, in any situation involving an ambush, impending disaster, or similar hazard. A successful roll means you get a warn-ing that something’s wrong. A roll of 3 or 4 means you get a little detail as to the nature of the danger.`,
        'taken': false,
    },
    'doubleJointed': {
        'name': `Double-Jointed`,
        'id': 'doubleJointed',
        'cost': 1,
        'description': `Your body is unusually flexible. You have a +1D when climbing or trying to escape from ropes, handcuffs or other restraints.`,
        'taken': false,
    },
    'empathy': {
        'name': `Empathy`,
        'id': 'empathy',
        'cost': 3,
        'description': `You have a “feeling” for people. When you first meet someone,or when you are reunited after an absence, you may request the GM to roll against your SENSE. He will then tell you what you “feel” about that person. (Note that a failed roll means the GM may lie to you.)This talent, when it works, is excellent for spotting imposters,ghostly possession, etc., and determining the true loyalties of NPCs. You can also use it to determine whether someone is lying... not what the truth really is, but just whether they are being honest with you.`,
        'taken': false,
    },
    'foolLucky': {
        'name': `Fool Lucky`,
        'id': 'foolLucky',
        'cost': 5,
        'description': `You can re-roll a pool, but it 'cost' s you an XP every time you do it. You can only re-roll each pool once—you have to accept the second result, even if it’s worse than the first.`,
        'taken': false,
    },
    'immunityToDisease': {
        'name': `Immunity to Disease`,
        'id': 'immunityToDisease',
        'cost': 2,
        'description': `Your body naturally resists all disease organisms. You will never catch any infection or disease “naturally.” If you are forcibly injected with a disease organism, your body will throw it off immediately. Virus and fungus invasions are also considered “disease,”though larger parasites (e.g., a tapeworm) are not.`,
        'taken': false,
    },
    'nightVision': {
        'name': `Night Vision`,
        'id': 'nightVision',
        'cost': 2,
        'description': `Your eyes adapt rapidly to the darkness. You cannot see in total dark – but if you have any light at all, you can see fairly well.Whenever the GM exacts a penalty because of darkness, except for total darkness, this penalty does not apply to you.`,
        'taken': false,
    },
    'wealth': {
        'name': `Wealth`,
        'id': 'wealth',
        'cost': 2,
        'description': `You begin with 100 extra gold.`,
        'taken': false,
    },
}

export default items;