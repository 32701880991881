import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function Money(props) {
    return (
        <div style={{ width: '100%', overflowX: 'scroll', scrollbarWidth: 'thin' }}>
            <div className="row">
                <div className="columns ">
                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                        Money is important in a political game, but tracking every coin is not. These rules treat
                        money as a flexible principle, not as a concrete fact.
                    </p>
                    <span className="heavyWords">An Introduction to Wealth</span>
                    <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                    <p className="noPaddingParagraph">
                        Wealth is the abstraction for personal cash, which you can purchase at character creation as
                        an Advantage. If you don’t get that Advantage, you begin with a Wealth of 0.
                    </p>
                    <p className="noPaddingParagraph">
                        <b>If your Wealth score is greater than an object’s price, you can buy it and your Wealth
                        score doesn’t change.</b>
                    </p>
                    <p className="noPaddingParagraph">
                        If your Wealth score is equal to the object’s price, you have a choice. One option is to
                        simply pay full price. If you do that, you get the object but your Wealth drops by 1.
                    </p>
                    <p className="noPaddingParagraph">
                        The other option is to bargain for it. If you do this, you can either roll your Wealth as a
                        pool, or roll <b>Command+Haggle</b>. This is a contested roll against the seller. If you get the
                        Higher match, you get the object and your Wealth doesn’t change. <b>If you don’t get a match,
                        you can’t get the object at all—not even by dropping your Wealth rating.</b> 
                    </p>
                    <p className="noPaddingParagraph">
                        If your Wealth score is less than the object’s price, you can’t get it.
                    </p>
                    <p className="noPaddingParagraph">
                        Astute players quickly realize the vulnerability in the first option: If they have Wealth
                        5, and want to buy a sword that costs Wealth 2, they can automatically do so. If there’s
                        no limit to the number of times they can do that, there’s no reason that they can’t buy a
                        hundred swords, or a thousand, and outfit an army on the cheap. Right?
                    </p>
                    <p className="noPaddingParagraph">
                        Well, no. Wealth may be amorphous but there is a limit. Attempting to cheat the system typically brings about 
                        very dire consequences.
                    </p>
                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                        Wealth converts to Treasure at roughly a 3-to-1 ratio, so you can outfit a private army
                        with private monies. However, using Treasure to arm your people is more efficient.
                    </p>
                    <Accordion>
                        <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Losing Wealth</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <p className="noPaddingParagraph">
                                The most obvious way to lose Wealth is to buy things. But there are other ways.
                            </p>
                            <p className="noPaddingParagraph">
                                First and foremost, there’s robbery. If you're not lugging your silver bars around with you everywhere you go then you need 
                                either have them buried very deeply or in the hands of someone you trust. And even that might not be enough to stop a determined theif. 
                            </p>
                            <p className="noPaddingParagraph">
                                More commonly, there are infrastructure expenses. Being rich in a more primitive economy
                                costs money. You pay for guards, and traps on your treasure vault, and thief-takers to
                                recover your lost goods... or you just write off the losses. And then there are taxes.
                            </p>
                            <p className="noPaddingParagraph">
                                In game terms, these factors mean there is a tendency for riches to erode. Any time your
                                Wealth score is 4–6, it drops by 1 every season. (Not every game session, every season.) If
                                it’s 7–10, it drops by 1 every year. However, this only happens if you fail to raise or lower
                                your Wealth in that time period. Only stagnant funds depreciate.
                            </p>
                            <p className="noPaddingParagraph">
                                This is an incentive to
                                <ol>
                                    <li>
                                        Keep adventuring to improve your resources
                                    </li>
                                    <li>
                                        and spend money rather than sit on it.
                                    </li>
                                </ol>
                            </p>
                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                You can also share the Wealth. By voluntarily decreasing your Wealth by 1, you can give
                                one person Wealth equal to your newly lowered Wealth rating.
                            </p>
                            <p className="noPaddingParagraph">
                                <b>Example:</b>
                            </p>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p style={{ marginBottom: '4px' }}>
                                        When Jubelus the Merchant marries off her only daughter, she provides a dowry equal to
                                        half her riches. Jubelus has Wealth 6. By dropping to Wealth 5, she can raise her previously
                                        penniless daughter to Wealth 5 as well.
                                    </p>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Gaining Wealth</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <p className="noPaddingParagraph">
                                Treasure. Mounds of coin and small piles of semi-precious stones have Wealth levels (or ranges)
                                attached to them. When you find them, you can trade them in to boost Wealth.
                            </p>
                            <p className="noPaddingParagraph">
                                The reason for ranges is that
                                <ol>
                                    <li>
                                        A treasure may have greater or lesser value depending on
                                        the buyer
                                    </li>
                                    <li>
                                        and its value may also fluctuate depending on the ability of the seller. If your
                                        GM assigns a changing value, it’s because they has a plan, either for you to get a bigger payoff
                                        if you find the right seller, or by roleplaying a good story about getting rid of it, or by using
                                        <b>Command+Haggle</b>. Your GM may not tell you the Wealth range of a particular treasure without
                                        some knowledge that would let you appraise its value.
                                    </li>
                                </ol>
                            </p>
                            <p className="noPaddingParagraph">
                                Once the Treasure's (item, horde, or otherwise) Wealth level is set, you can decide what to do with it.
                            </p>
                            <p className="noPaddingParagraph">
                                If your current Wealth is higher than the level of the treasure, you don’t change your
                                character’s Wealth by taking it. (You get the goodies as objects, but they have no effect on
                                your character sheet because they’re not significant compared to your overall bankroll.)
                            </p>
                            <p className="noPaddingParagraph">
                                If your current Wealth is lower than the level of the treasure, you can take it and jump up to
                                the Wealth level of the treasure.
                            </p>
                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                If your current Wealth is equal to the level of the treasure, you can sell it and raise your
                                current Wealth level by 1
                            </p><p className="noPaddingParagraph">
                                <b>Example:</b>
                            </p>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p style={{ marginBottom: '4px' }}>
                                        Vados the Merchant has Wealth 5. He acquires a trove of spices valued at Wealth 3. He can
                                        take the trove, but it doesn’t raise his overall Wealth. Vados’ player could note down “Wealth 3
                                        spices” and later give them to a poorer PC or GMC. That’s fine and a good idea. But they don’t
                                        impact his personal wealth.
                                    </p>
                                </div>
                            </div>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p style={{ marginBottom: '4px' }}>
                                        Rahn the beggar finds an emerald the size of a hen’s egg! It’s got a Wealth range of
                                        3–5. Rahn goes to sell it. Sadly, the jewel merchant is unimpressed by his rags and wretched
                                        odor, so Rahn’s only going to get Wealth 3 for it. If he cleans up, he might get Wealth 4. If
                                        Rahn cleans up and makes a good <b>Command+Haggle</b> roll, he could jump up to Wealth 5.
                                    </p>
                                </div>
                            </div>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p style={{ marginBottom: '4px' }}>
                                        The merchant who bought the emerald has Wealth 5. If he can get the emerald’s full value
                                        of 5 when he sells it in turn, his Wealth rises to 6.
                                    </p>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Just How Much Money Is It?</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <p className="noPaddingParagraph">
                                As advertised, the Wealth system is fuzzy and imprecise, based on the premise that there’s a tension
                                between the character’s inclination to carouse and debauch and the player’s tendency to hoard.  With
                                that in mind, here are rough guides to exactly how many coins of a given type are represented by
                                a particular Wealth rating.  (You will note gaps.  Don’t worry about them.  They’re there to explain
                                why you can sometimes buy a Cost 4 item with Wealth 4 and not drop, and sometimes you can’t.)
                            </p>
                            <p className="noPaddingParagraph">
                                It’s not precise and it won’t stand up to rigorous examination—but did you really start playing games like Reign and the ORE
                                so that you could rigorously examine the monetary model?  These sums are here so that when your
                                characters crack open a chest and see a thousand small silver coins, they know they’ve struck it rich.
                            </p>
                            <div className="diceBox" style={{ marginBottom: '20px' }} >
                                <table style={{ width: '100%', lineHeight: '1' }} >
                                    <tr>
                                        <th>Wealth</th>
                                        <th>Thick, Pure Gold Coins</th>
                                        <th>Thin, Devalued Gold Coins</th>
                                        <th>Small Silver Coins</th>
                                        <th>Large Silver Coins</th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>1-2</td>
                                        <td>1-2</td>
                                        <td>5</td>
                                        <td>2-3</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>2-3</td>
                                        <td>4-5</td>
                                        <td>10</td>
                                        <td>5-6</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>4-5</td>
                                        <td>8-10</td>
                                        <td>20</td>
                                        <td>10-12</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>8-10</td>
                                        <td>15-20</td>
                                        <td>40</td>
                                        <td>20-25</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>15-20</td>
                                        <td>30-40</td>
                                        <td>80</td>
                                        <td>40-50</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>30-40</td>
                                        <td>65-80</td>
                                        <td>160</td>
                                        <td>80-100</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>65-80</td>
                                        <td>130-160</td>
                                        <td>320</td>
                                        <td>160-200</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>130-160</td>
                                        <td>250-300</td>
                                        <td>640</td>
                                        <td>320-400</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>250-300</td>
                                        <td>500-600</td>
                                        <td>1300</td>
                                        <td>640-800</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>500-600</td>
                                        <td>1000-1500</td>
                                        <td>2500</td>
                                        <td>1300-1600</td>
                                    </tr>
                                </table>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Stuff On Which to Spend Money</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <div style={{ width: '100%', overflowX: 'scroll', scrollbarWidth: 'thin' }}>
                                <div className="row">
                                    <div className="columns ">
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Personal Service or Item</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr>
                                                            <th>Personal Service or Item</th>
                                                            <th>Wealth</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Stewed turnips with black bread (or local equivalent)</td>
                                                            <td>0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pauper's funeral (hole, cairn, no coffin or tombstone)</td>
                                                            <td>0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Night of hearty drinking</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fresh and hearty meal</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Invigorating massage</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Decent burial (prayers, wood coffin, simple monument)</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ablutions (haircut, shave, manicure, bath with fancy soaps)</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lavish personal feast</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fine dinner for a small group of friends</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Negotiable affection</td>
                                                            <td>1-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lavish funeral (mourners, elaborate casket, imposing tomb)</td>
                                                            <td>5</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Vehicles / Transportation</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr>
                                                            <th>Vehicles / Transportation</th>
                                                            <th>Wealth</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Mule or donkey</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rowboat or canoe</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Riding horse</td>
                                                            <td>1-2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ox or draft horse</td>
                                                            <td>1-2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Peasant wagon</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small sailboat (crew of 2)</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Warhorse</td>
                                                            <td>2-4</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Racehorse</td>
                                                            <td>2-7</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gentleman's coach</td>
                                                            <td>2-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Merchant riverboat (crew of 5)</td>
                                                            <td>4-5</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small ocean vessel (crew of 10)</td>
                                                            <td>6-7</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Medium ocean vessel (crew of 20)</td>
                                                            <td>8</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Huge ocean vessel (crew of 35)</td>
                                                            <td>9</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Crewed trio of huge ocean vessels with a 3-frigate escort</td>
                                                            <td>10</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Precious Items</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr>
                                                            <th>Precious Items</th>
                                                            <th>Wealth</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Plain gold band, earring or ampalang</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Signet ring</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Half-pound of rare local spices</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Peasant's wedding dress</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Jeweled cape clasp</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Flask of fragrant oil or perfume (local)</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Perfectly adequate pearl</td>
                                                            <td>2-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Half-pound of rare, imported spices</td>
                                                            <td>3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Simple diamond necklace</td>
                                                            <td>3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Solid gold "brass" knuckles with your initials on them</td>
                                                            <td>3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Enourmous, intricate, tourmaline-studded jade lip disc</td>
                                                            <td>3-4</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Flask of rare perfume from a distand land</td>
                                                            <td>3-5</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Wedding gown fit for minor nobility</td>
                                                            <td>4</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Working spinning wheel made, for some reason, of silver</td>
                                                            <td>5</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Immense ruby</td>
                                                            <td>5-6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pearl of great price</td>
                                                            <td>7-8</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Begemmed wedding gown fit for a queen</td>
                                                            <td>8</td>
                                                        </tr>
                                                        <tr>
                                                            <td>An Imperial Crown</td>
                                                            <td>9</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Weapons</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr>
                                                            <th>Weapons</th>
                                                            <th>Wealth</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Small weapon (dagger, shortbow) of poor quality</td>
                                                            <td>0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Combat weapon of poor quality (typically iron)</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small weapon of adequate quality</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Combat weapon of adequate quality (typically steel)</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small weapon of exquisite quality</td>
                                                            <td>3-6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Combat weapon of exquisite quality (such as a Dinda Sword)</td>
                                                            <td>3-6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Exotic weapon (such as a Truil Hornbow)</td>
                                                            <td>3-5</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Armor</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr>
                                                            <th>Armor</th>
                                                            <th>Wealth</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Wooden Helmet</td>
                                                            <td>0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Iron Cap</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small Wooden Shield</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cavalry Boots</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cuirass (Boiled Leather)</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Large Wooden Shield</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small Metal Shield</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Steel Gauntlets</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reinforced Full Helmet</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Breastplate</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chain Shirt</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Large Metal Shield</td>
                                                            <td>3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chainmail</td>
                                                            <td>3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Full Plate Armor</td>
                                                            <td>6</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Buildings (Used)</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr>
                                                            <th>Buildings (Used)</th>
                                                            <th>Wealth/Treasure</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Rude Shack</td>
                                                            <td>3/1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tidy Cabin</td>
                                                            <td>4/1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Modest Home</td>
                                                            <td>5/1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>House in Town</td>
                                                            <td>5-6/1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>House in the City</td>
                                                            <td>6-7/2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lavish Country Manor</td>
                                                            <td>6-8/2-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Imposing City Mansion</td>
                                                            <td>6-9/2-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small Fortified Tower</td>
                                                            <td>6/2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stout Fort</td>
                                                            <td>7-10/3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Allegedly Impenetrable Fortress</td>
                                                            <td>??/4</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Palace</td>
                                                            <td>??/5</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gilded Palace of Importance</td>
                                                            <td>??/6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chainmail</td>
                                                            <td>3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Full Plate Armor</td>
                                                            <td>6</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Buildings (Constructed to Specification)</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr>
                                                            <th>Buildings (Constructed to Specification)</th>
                                                            <th>Wealth/Treasure</th>
                                                        </tr>
                                                        <tr>
                                                            <td>House in Town</td>
                                                            <td>3-4/1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>House in the City</td>
                                                            <td>4-5/1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lavish Country Manor</td>
                                                            <td>6-8/2-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Imposing City Mansion</td>
                                                            <td>7-9/2-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Small Fortified Tower</td>
                                                            <td>6/2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stout Fort</td>
                                                            <td>8-9/2-3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Allegedly Impenetrable Fortress</td>
                                                            <td>??/5</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Palace Fit to Stagger the Sensibilities of a Potentate</td>
                                                            <td>??/6</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Stocking, Provisioning, and Supplying Soldiers</AccordionSummary>
                                            <AccordionDetails style={{ display: 'grid' }}>
                                                <div className="diceBox">
                                                    <table style={{ width: '100%', lineHeight: '1', marginBottom: '20px' }} >
                                                        <tr style={{ lineHeight: '20px' }}>
                                                            <th>Number of Soldiers</th>
                                                            <th>Coarse Rations</th>
                                                            <th>Hearty Rations</th>
                                                            <th>Rude Lodging (with ghastly food)</th>
                                                            <th>Simple Lodging (with decent food)</th>
                                                            <th>Simple Weapons and Shields</th>
                                                            <th>Armor and Combat Weapons</th>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>1/0</td>
                                                            <td>1/0</td>
                                                            <td>1/0</td>
                                                            <td>2/0</td>
                                                            <td>2/0</td>
                                                            <td>3/0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6</td>
                                                            <td>2/0</td>
                                                            <td>2/0</td>
                                                            <td>2/1</td>
                                                            <td>3/0</td>
                                                            <td>3/0</td>
                                                            <td>5/1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>12</td>
                                                            <td>3/1</td>
                                                            <td>4/1</td>
                                                            <td>3/1</td>
                                                            <td>4/1</td>
                                                            <td>4/1</td>
                                                            <td>6/2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>50</td>
                                                            <td>4/1</td>
                                                            <td>5/1</td>
                                                            <td>4/2</td>
                                                            <td>5/2</td>
                                                            <td>5/2</td>
                                                            <td>7/3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>100</td>
                                                            <td>5/1</td>
                                                            <td>6/2</td>
                                                            <td>5/2</td>
                                                            <td>6/2</td>
                                                            <td>6/2</td>
                                                            <td>8/3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>200</td>
                                                            <td>7/2</td>
                                                            <td>8/2</td>
                                                            <td>6/2</td>
                                                            <td>7/3</td>
                                                            <td>7/3</td>
                                                            <td>9/4</td>
                                                        </tr>
                                                        <tr>
                                                            <td>400</td>
                                                            <td>8/2</td>
                                                            <td>9/3</td>
                                                            <td>7/3</td>
                                                            <td>8/3</td>
                                                            <td>8/4</td>
                                                            <td>10/5</td>
                                                        </tr>
                                                        <tr>
                                                            <td>600</td>
                                                            <td>9/3</td>
                                                            <td>10/3</td>
                                                            <td>8/3</td>
                                                            <td>9/3</td>
                                                            <td>9/5</td>
                                                            <td>??/8</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1000</td>
                                                            <td>10/3</td>
                                                            <td>??/4</td>
                                                            <td>9/3</td>
                                                            <td>10/3</td>
                                                            <td>10/6</td>
                                                            <td>??/10</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1500</td>
                                                            <td>??/4</td>
                                                            <td>??/5</td>
                                                            <td>10/3</td>
                                                            <td>??/4</td>
                                                            <td>??/7</td>
                                                            <td>??/??</td>
                                                        </tr>
                                                        <tr>
                                                            <td>3000</td>
                                                            <td>??/5</td>
                                                            <td>??/7</td>
                                                            <td>??/4</td>
                                                            <td>??/5</td>
                                                            <td>??/9</td>
                                                            <td>??/??</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div >
            </div>
        </div>
    )
}