const items = {
    'Primitive': {
        'Plains': {
            name: 'Plains',
            description: `Meadow, Prairie, Scrub, and Steppe. Grasslands, savannas, and shrublands in semi-arid to semi-humid climates of temperate latitudes. The soil must be rather poor
            to only support grasses and shrubs. Or perhaps you are from the Arathi Highlands were huge terrifying storms whip up at a moments notice, destroying anything which dares to stand too
            tall or too rigid. On the Plains your days likely consist of movement. Tracking beasts, or your own flock, picking what little vegetation you can. Perhaps this ends when you first come
            into sight of a real settlement?`,
        },
        'Woodlands': {
            name: 'Woodlands',
            description: `Bark, blossom, bough, and burl. Temperate, boreal, or tropical? Each a type of forest and each so different. In what layer do you live? High up in the trees in a nest or
            crude tree-house? Or down below with the monsters that go bump in the night? Are you with family or on your own? Were you born into this or did you flee civilization? If you didn't flee 
            could it be that you're a hardcore survivalist?`,
        },
        'Desert': {
            name: 'Desert',
            description: `Dunes. Sandstorms. Oases. Equatorial, dry winds dissipate cloud cover, adding sunlight and heat. Do you cover your skin completely from the sun, or let it wash
            over you? Do you travel from Oasis to Oasis or have some other measure of obtaining water? Perhaps you only travel at night? Were you born into this life, or was it thrust upon 
            you?`,
        },
        'Coastal': {
            name: 'Coastal',
            description: `Rifts, ridges, vents, and seeps. How have you managed to keep to your way of life when there is so much activity on the ocean? Each day dozens of ships must pass by your
            shore. Everything from tall Galleons with their square sails and several masts to small skipjacks and oyster boats. Do you forage tidal pools for food? Fish? Dive for crustaceans and mollusks?
            Do you live on the mainland, somewhere remote in the Norigrad Empire? Or perhaps one of the small islands off of it's southernmost equatorial point? Careful, if you're from the second
            you'll likely have to deal with pirates.`,
        },
        'Mountain': {
            name: 'Mountain',
            description: `Hills and volcanoes, peaks and passes. Overlooks, passageways, abyssal depths. Life in the mountains differs elevation. Do you live along the sea, where mountains
            were formed by volcanic activity? Or far inland where years of tectonic movement has lifted the land into the sky. Primitive people living high in the mountains often have to do
            their farming or hunting far down in the valley. Or perhaps your herd and tend to your own flock? You could live alone or in a small group, likely consisting of close family.`,
        },
        'Tundra': {
            name: 'Plains',
            description: `Glaciers. Hoarfrost. Auroras. Mountain ranges and peaks, ice sheets, island archipelagos, fjords, glassland plateaus, river valleys, forests, as well
            as areas with grasses, sedges, mosses, and lichens for vegetation. Your life revolves around the sun, you dress in many layers, and the number of people you know could
            be counted on both your hands. Have you ever seen a stranger?`,
        }
    },
    'Nomadic': {
        'Raft': {
            name: 'Raft',
            government: `Familial Clans`,
            jobs: `Fisherman, Whaler, Spearman, Diver, Boat-builder (Craftsman)...`,
            location: `Coral Sea`,
            baseDescription: `The Rafters take no home but their rafts; each crew is a family or clan. Whenever two Raft freighters happen to meet, they may arrange to dock
            or hold a sort of family reunion party on land. When a few crews (often linked by marriage) start running out of berths or building up an excess of wealth, 
            they'll arrange to purchase a new ship and set up members of their crews as a new clan. No one (other than the Rafters) knows how many Rafters there are, 
            or if they have any goals beyond simply trading; they are famously insular. All of their freighters have at least a few weapons aboard, meaning piracy doesn't really pay,
            and it is well known the Raft keep a few well-armed enforcer ships around for just those kinds of occasions.`,
        },
        'Clan Aluh': {
            name: 'Clan Aluh',
            government: `High Priest`,
            jobs: `Shaman, Healer, Herbalist, Alchemist, Tracker, Falconer...`,
            location: `Y'zra Forest`,
            baseDescription: `The Aluh, which derives from the words aal uh that means 'great ones' in Ancient T'aange the 
            language spoken by the first. Later to be known in common tongue as Elves. They reside in a forest called Y'zra and worship a god that manifest itself in a rare 
            type of tree that grows only in the Y'zra forest. All elves (and non-elf members) have a customary celebration in which children of the age of 10 must cut out a twig 
            from the tree and immediately plant it. What happens next is unknown.`,
        },
        'Sheepeater Tribe': {
            name: 'Sheepeater Tribe',
            government: `???`,
            jobs: `???`,
            location: `Razorfen Plains - Grasslands`,
            baseDescription: `Anyone asking about this tribe in the early 11th century would hear a flurry of fantastical tales: The Sheepeaters were of a race of pygmies 
            living deep in the mountains. No, they were a “feeble-minded” tribe. No, a band of destitute renegades kicked out of other native societies. 
            No, they were a group of dirty, timid “primitives” afraid of the geothermal features that defined their home. None of these replies would touch even slightly at who the Sheepeater
            people were. They disappeared from the public eye long ago, leaving only wickiups, or cone-shaped shelters behind.`,
        },
        'Serrano': {
            name: 'Serrano',
            government: `Village Elder`,
            jobs: `Archer, Well-Digger, Grave-Digger, Hunter, Tracker, Outlaw...`,
            location: `Dajh Plains - Desert`,
            baseDescription: `The Serrano Tribesmen live in the Western Dajh plains, which is a particularly long stretch of desert. Demographic research suggests a majority are human and half-orc. 
            The Serrano Tribe live in one large community. They are known for being excellent archers, and many youth spend their time practicing archery on desert animals. There is a generally 
            strong dislike of the Norigrad Empire. The tribes' founder was believed to be an anti-government vigilante who spent much of his life terrorizing caravans that would try to pass through the desert. 
            This vigilante lifestyle continues today through some of the members.`,
        },
        'Clan Kel': {
            name: 'Clan Kel',
            government: `Family Head`,
            jobs: `Dog Trainer, Hunter, Trapper, Cart-Builder...`,
            location: `Twilight Forest - Cold`,
            baseDescription: `Clan Kel are nomadic, traveling in sled-boats. Each sled boat houses 4-6 people, though they could hardly be described as spacious. During Winter they hunt large mammals, 
            e.g. moose, using dogs to pull their sleds to where the game is most bountiful. During Summer the twilight forest melts into a maze of swampy channels, and the Foresters 
            mostly fish and gather plants and bog iron, poling their boat along and through the forest.`,
        },
        'Shoalwater Nomads': {
            name: 'Shoalwater Nomads',
            government: `Khan`,
            jobs: `Archer, Oarsman, Galley Slave, Captain...`,
            location: `All bodies of water with a depth of greater than 3'`,
            baseDescription: `The Shoalwater Nomads are, perhaps, the sole reason the trade of slavery had existed in Norigrad for as long as it did. With a civilization built on trade and combat in equal parts it's really no wonder why.
            Members of the Shoalwater Nomads can be identified by the unique flags flown from the masts of their ships. Rarely less than a dozen, and oftentimes many times that, these flags are used to quickly convey messages to other
            Nomadic ships. The ability to read these flags is a closely guarded secret. Shoalwater Nomads are usually only encountered at sea, though it is known that they live on and control some islands on the southern equatorial edge of the
            Norigrad empire. All contact with the outside world is in the form of trade in harbor towns. Recently the King has issued orders for all navy ships to sink Shoalwater Vessels on sight.`,
        },
        'Hoh and Fox': {
            name: 'Hoh and Fox',
            government: `Small Clans each with their own Chief`,
            jobs: `Herbalist, Miner, Leatherworker (Craftsman), Warrior...`,
            location: `Roll Swamp Region - Cold`,
            baseDescription: `The Hauk (hoh) and Meskie joined together to form an alliance for mutual protection against other tribes. Hoh and Fox live in the icy swamps on the edge of the sunlit region. Members inherit their tribe and political allegiance through their father, and clan 
            and spiritual allegiance through their mother. Tribes travel together most of the year, except during the midwinter and midsummer festivals, when multiple tribes will 
            gather, and each clan present will have it's own bonfire (though there is pretty free mingling). This is also the time for young Foresters to find a suitable spouse 
            from outside both their tribe and their clan. They trade downstream some, mostly iron and medicinal herbs in exchange for spices and cotton, but are almost entirely self sufficient. 
            All demographics are represented.`,
        },
        'Soma Family': {
            name: 'Soma Family',
            government: `Unofficial - each band has a leader they look to`,
            jobs: `Perfumer, Acrobat, Storyteller, Bard, Wagon-Driver...`,
            location: `All Places`,
            baseDescription: `One by one we light the candles of this show <br />
            One by one, enter the theatre of the primal birth <br />
            Silently watch the planetary curtain go down <br />
            Laugh and rejoice, as the powerful play greets you tonight <br />
            We are the Soma Ruh <br />
            We know the songs the sirens sang <br />
            See us dream every tale true <br />
            The verse we leave with you will take you home <br />
            We'll give you a key to open all of the gates <br />
            We'll show you a sea of starlight to drown all your cares <br />
            Mirrorhouses, the sweetest kisses and wines <br />
            A Debussy dialogue between wind and the roaring sea <br />
            We are the Soma Ruh <br />
            We know the songs the sirens sang <br />
            See us dream every tale true <br />
            The verse we leave with you will take you home <br />
            Dance to the whistle, to the play, to the story <br />
            To infinite encores <br />
            Laugh at the royalty with sad crowns <br />
            And repeat the chorus once more <br />
            We are the Soma Ruh <br />
            We know the songs the sirens sang <br />
            See us dream every tale true <br />
            The verse we leave with you will take you home <br />
            We are the Soma Ruh <br />
            We know the songs the sirens sang <br />
            See us dream every tale true <br />
            The verse we leave with you will take you home`,
        }
    },
    'Barbaric': {
        'Araho': {
            name: 'Araho',
            government: `Matriarchal`,
            jobs: `Pearl Diver, Storyteller, Net Tender, Fire-swallower, Farmer...`,
            location: `Cuzco Island`,
            baseDescription: `Famously nudist. After an event of synchronized eruptions of the volcanoes in nearby Draken Montem the lands of the Araho suffered from the largest tidal 
            wave seen in history. Less than 1000 members were able to flee the disaster and commence their new life on the first island they found. 
            The new land's ground was fertile and the once nomadic people settled and became a mix of farmers/fishermen. It is not known by outsiders why the decision to shun clothing was made.`,
        },
        'Clan Bannon': {
            name: 'Clan Bannon',
            government: `Monarchy - King/Queen`,
            jobs: `Entertainer, Courtier, Courtesan, Brewer...`,
            location: `Castle Bannon and surrounding lands`,
            baseDescription: `The members of Clan Bannon are found almost exclusively along the flooded shore of Lake Calel. They worship a strange mix of gods from both the Norigradian pantheon and their own idols which allegedly boasted superhuman strengths. 
            They dress and arm themselves to reflect their gods. Beyond dress, those born to Clan Bannon accumulate a number of feat-specific tattoos throughout their life. Unusually, tattoos are not 
            reserved only for deads of valor or combat-prowess, but could be given for any form of achievement. Three words to describe the members of Clan Bannon would be powerful, boisterous, and loud.`,
        },
        'Blood Tribe': {
            name: 'Blood Tribe',
            government: `???`,
            jobs: `???`,
            location: `Within the Byzan Jungle`,
            baseDescription: `Interactions with this tribe are rare. In the past decade three expeditionary forces have been sent into the Byzan Jungle. Later, helmets, armor and assorted bits of jewelry
            have been found in the nets of the fishermen of Village Rhyne: a small village down-river of the Byzan Jungle. Plans for further expeditions have been canceled by order of the King of Norigrad until further notice.`,
        },
        'Catawba Confederation': {
            name: 'Catawba Confederation',
            government: `Village Head`,
            jobs: `Merchant, Miner, Beast-Tamer, Performer, Courtesan...`,
            location: `Entrance near the Base of Mount Vulcan`,
            baseDescription: `The Catawba make their home in a cave shaped like the inside of a torus, carved out of the earth. Their walls are smooth black stone, behind which an observer can feel gentle 
            heat. Hundreds of feet high, Orange points of light dot the ceiling, like thousands of pinholes in a tent. 
            Families live here in cave-homes crawling up along the sides of the curved cliff walls. Glowing stones in cobbled paths lights the way from one winding staircase to another. 
            In the open floor between the walls are massive open-air warehouses. All known races are represented here. The Village Head is picked in secret by a small exclusive council.
            Incredible things pass through the Catawba Confederation, the village which doesn't exist.`,
        },
        'Crow Creek Hual': {
            name: 'Crow Creek Hual',
            government: `Czar - Strength Based`,
            jobs: `Trapper, Fisher, Warrior, Hunter, Pearl Diver...`,
            location: `Where the Crow Creek feeds into the Salin Sea`,
            baseDescription: `The Crow Creek Hual are a collection of some of the more (assumed) ruthless races that inhabit Norigrad. Lead and dominantly populated by Lizardfolk, 
            they build their huts on walkways over marshland. Known demographics are some Orcs, Goblins, a few Humans and a small settlement of Merfolk dwelling deep in the waters of the Crow Creek.
            They are fisher people by trade, attaining modest wealth through trading away any excess caught fish. Szerak is the legendary Lizardfolk Trapper that leads the tribe. Any crime is brought
            before him and judged by the tossing of bones. Divine guidance shows whether the person is guilty or innocent. 
            Guilty verdicts result in punishment ranging from re-payment for stolen property; exile for undermining the authority of the Czar or ignoring
            their civic responsibilities; to death for more serious offences. `,
        },
        'Eastern Band of Quechan': {
            name: 'Easter Band of Quechan',
            government: `Chief - Family Based`,
            jobs: `Merchant, Sapphire Miner, Lapidarist, Guard, Scout...`,
            location: `Foothills of the Som Mountain Range. In a wood filled with thick black pine trees hundreds of feet tall - Cold`,
            baseDescription: `The Quechan Band operate more as an organization than a typical tribe. Their primary source of income is through the sale of sapphires mined at one of three hidden sites.
            All members of the tribe work in some way to aid in the mining and selling of these sapphires. Some, trusted, work in the mine itself, others work with lapidary tools to cut and polish the gemstones, 
            still others take jobs as merchants, travelling to nearby cities with carts laden with stones. Those that do not work directly in the process, or those less trusted by the clan chief are usually given roles
            as guards, warriors, or scouts to ensure that safety of those producing and selling the stones.`,
        },
        'Little Lake Kwatami': {
            name: 'Little Lake Kwatami',
            government: `Elders - Age Based Council of Five`,
            jobs: `Fisher, Forester, Ploughman, Excavator, Net Tender... `,
            location: `Sentinel Plains - Rolling Lush Grasslands - Within the bounds of the Norigrad Empire`,
            baseDescription: `Renowned for their man-made fish farms. The Little Lake Kwatami People are druidical/shamanistic following a pantheon of nature spirits believed to live in close proximity to the village. 
            They practice extensive blue facial tattooing on all members of both sexes from a young age. They celebrate a holiday on the summer solstice known as Long Day. To honor the nature gods the tribe stops work and has a series of celebratory games and feasting. 
            If other tribes are nearby, they may join together for this one day (even warring tribes will lay down arms and feast at the same floor). The tribe's demographics are 30% dwarf, 30% human, 30% elf, 10% other. Recently the Candlekeep Library in Norigrad has begun
            sending scholars to learn the construction and techniques they employ in their fish farms.`,
        },
        'Fort Joshua': {
            name: 'Fort Joshua',
            government: `Chief - Elected Leader - All Tribe-members Vote`,
            jobs: `Hunter, Trapper, Gate-guard, Healer, Craftsman...`,
            location: `The foothills of the Mountains of Carn - Temperate - On the Edge of the Norigrad Empire`,
            baseDescription: `A circular stone fort atop a grassy hill. It's walls are cuboidal, twelve feet high and deep. The stone is white marble, impossibly smooth. It's original purpose and builders are unknown. Rediscovered by the formerly
            nomadic Joshua Tribe, who settled there. The tribe's demographics are 60% human, 30% dwarf, 5% gnome, 5% other. Its current leader is a male human swordsmith, six feet in height with dreadlocked red hair.
            Its symbol was once the spoked wheel. It is now the pine tree. Though still primarily hunters and gathers the tribe has recently begun the practice of farming. Since settling, there have been three major events of note.
            First an attack by members of Catawba Confederation as the group was moving in. Second the unearthing of a mineshaft beneath the Chiefs house. A small group was sent in to investigate. The shaft was re-bricked over three weeks later when 
            none had returned. Third, an attempted assassination of the current Chief. The assassin escaped identification but many believe it was someone from within the tribe.`,
        },
    },
    'Civilized': {
        'Apuldram': {
            name: 'Apuldram',
            government: `Oligarchy, Elective Monarchy ‐ Lord/Lady`,
            description: `Founded as a port to ferry spices around the world. It is a city of 
            old  beauty  and  stone  archways.  Back  alleys  teem 
            with  deals  and  opulent  halls  feature  decidedly  more 
            devious transactions.`,
            economy: `Shipping  metals  and  spice  to  other  cities  and 
            kingdoms  keeps  the  economy  strong.  However, 
            quarrels among the clans stunt innovation.
            `,
            notableBusinesses: `The Saffron Trading Company, 
            The Spice Works Trading Company, 
            The Blind Beggar Inn, 
            Radnor Tailor Shop, 
            Oglesby Arms and Weapons`,
            attractions: `The  Famed  Spice  Bazaar  ‐  Stalls  filled  to  the  brim 
            with spices and other goods from around the world. 
            The  air  permeated  with  a  thousand  aromas. 
            Shoppers  haggle  with  merchants  and  porters 
            unload exotic cargos. <br />
            The Statue of Ravinger ‐ A local hero who improved 
            cultivation of saffron. <br />
            Orman  Shrine  ‐  A  religious  site  renowned  for  its 
            slanting and curving architecture.`,
        },
        'Colston Basset': {
            name: 'Colston Basset',
            government: `City State, Monarchy ‐ King/Queen`,
            description: `Founded  by  a  band  of  runaway  slaves  nearly  four 
            centuries  ago,  the  city  has  often  been  a  haven  for 
            those on the run. Cedar, pine, and oak are the main 
            building materials. `,
            economy: `For a  hundred years  the  town  has  served as a  hub 
            for the timber and lumber trade.`,
            notableBusinesses: `The Browersburg Inn,
            Timna’s Lumber Company,
            The Colston Timber Company,
            Grindner’s Sawmill
            `,
            attractions: `Freedom  Rock  ‐ The  rock  where  the  founders’  boat 
            landed which has now become a monument. <br />
            Kelvin’s  Maritime  Museum  ‐  A  small  museum 
            concerning maritime trade and ships. The entry fee 
            is one gold per person. A guided tour is provided 
            for four gold. <br />
            The  Falcon Throne  ‐  Carved  from  beautiful  dark 
            wood.  The  throne  resembles  a  giant  falcon.  The 
            throne  was  a  gift  to  the  king  from  another  town 
            several generations ago.
            `,
        },
        'Dartmoor': {
            name: 'Dartmoor',
            government: `Aristocracy, Noocracy ‐ Prime Thinker`,
            description: `The  city  served  as  a  trading  center  for  rich 
            farmlands until diamonds were discovered. The old 
            city  is  mostly  stone  buildings  and  arched 
            colonnades.  The  new  construction  is  hasty  and 
            mainly wooden.
            `,
            economy: `The  discovery  of  diamonds  has  spurred  incredible 
            growth.`,
            notableBusinesses: `Ebba Diamond Exchange, 
            The Gaines Dairy Farm, 
            The Babbling Brook Inn, 
            The Three Coppers Inn
            `,
            attractions: `Various  Philosophical  Schools  ‐  The  old  city  is  a 
            hotbed  for  philosophers.  Great  minds  attract 
            students  and  followers,  so  there  are many  schools 
            and  lecture  halls  where  one  can  learn  the  latest 
            schools of thought. <br />
            The  Star  of  Mymor  ‐  To  this  date  the  largest 
            diamond near Dartmoor. Cut it is 57.3 carats.`,
        },
        'Draycott': {
            name: 'Draycott',
            government: `City State, Monarchy ‐ King/Queen`,
            description: `The elves have had a city here and have cultivated 
            the land since beyond human memory. The curved 
            wooden  buildings  reflect  the  elves  love  of  nature 
            and natural shapes.`,
            economy: `The economy is agriculturally based and moves at a 
            glacial pace. `,
            notableBusinesses: `The Handsome Inn,
            The Leaf and Stream Inn,
            Aelothia Winery`,
            attractions: `The  Draycottian  Library  ‐  Over  the  centuries  the 
            elves have compiled an impressive collection of rare 
            and  eclectic  books.  Scholars  and  wizards  often 
            travel here to pour over the ancient books. <br /> 
            Aelothia Winery  ‐ A marvel  of  wine  production  and 
            storage that produces highly prized vintages. There 
            is also a  restaurant on  site  that is  renowned  for its 
            cuisine. <br />
            Draycott College  ‐ A  school  of  magic  arts  and  the 
            arcane for which the city has become known. `,
        },
        'Cashel': {
            name: 'Cashel',
            government: `City State Magocracy ‐ Archmage`,
            description: `In addition to being a hub of coffee and tea exports, 
            Great  Cashel  has  grown  into  a  seat  of  magical 
            knowledge in  the world. It is home  to  the Cashel 
            University  and  its  many  colleges.  For  more  than 
            two  centuries  the  head  of  the  university  has  also 
            been the ruler of the city. Ancient arch‐lined streets 
            lead  to  shaded  courtyards  where  students  and 
            masters  savor  sumptuous  coffees  and  teas.  Stone 
            spires  capped  with  private  studies  and 
            observatories pepper the skyline. `,
            economy: `Sea  trade  and  numerous  schools  keep  fueling 
            growth.`,
            notableBusinesses: `Safford’s Bookbinding,
            Ordway Coffee Exports,
            Upwood Tea Company,
            The Rose and Crown Inn,
            The Red Ship Inn,
            The White Stag Inn,
            Rushford Winery`,
            attractions: `The Cathedral of Books  ‐ A  temple  to books as well 
            as the largest library in the known world. <br />
            Museum of the Arcane ‐ A collection of artifacts and 
            timelines of the development and history of magic. <br />
            Axton’s Tea  House  ‐  A  popular  tea  house  with  an 
            outstanding atmosphere for studying. <br />
            Athelstan’s Tomb  ‐ The  tomb  of  an  archmage  that 
            lived four or five centuries ago. <br />
            Claibourne’s Statue ‐ The statue of an archmage that 
            lived a couple of centuries ago.`,
        },
        'Grimston Hill': {
            name: 'Grimston Hill',
            government: `Plutocracy ‐ Mayor`,
            description: `Built  on  a  natural  rise,  the  town  is  located  near 
            several  large  sand  deposits.  Glass  blowing  is  a 
            widely practiced trade. Most of the buildings in the 
            town  have  glass  windows  set  it  apart  from  other 
            cities.
            `,
            economy: `Stable largely because of the glassworks.`,
            notableBusinesses: `Grimston Glassworks,
            The Jumping Donkey Inn`,
            attractions: `The  Grimston  Glass  Spire  ‐  A  metal  and  glass 
            structure that is about five stories tall. It was built to 
            draw  tourists  and  help  raise  awareness  about  the 
            glass trade.
            `,
        },
        'Haughton': {
            name: 'Haughton',
            government: `Anocracy ‐ Warlord/Warlady`,
            description: `The inhabitants threw off a despotic ruler a decade 
            ago,  but  the  rebels  then  shattered  into  factions. 
            Now  the  groups  compete  for  power  and  the  town 
            suffers  because  of  it.  Burned‐out  and  abandoned 
            buildings can be seen on nearly every street. Some 
            neighborhoods have become fortified compounds. `,
            economy: `In  turmoil  because  of  the  squabbling  factions. The 
            economy is also shrinking since people are leaving. 
            However,  Haughton  is  becoming  known  for  its 
            black market and contraband sales.`,
            notableBusinesses: `The Hind’s Heart Inn
            `,
            attractions: `The  Hind’s  Heart  Inn  ‐ The  inn  is  nearly  a  fortress 
            now. However, it stills caters quite well to travelers. <br />
            The  Black  Market  ‐  If  you  are  looking  for  illegal 
            goods,  you  can  probably  find  them  in  the  many 
            back alleys and gang hideouts.`,
        },
        'Hound Tor': {
            name: 'Hound Tor',
            government: `Democracy, Demarchy ‐ Mayor `,
            description: `A  quiet  farming  village  that  hasn’t  seen  much 
            change  in  decades. Walls  were  erected  about  20 
            years ago after some bandits raided the town.`,
            economy: `Shrinking slightly. `,
            notableBusinesses: `The Full Pint Inn`,
            attractions: `None. However, the Full Pint Inn is quite nice after a 
            long day of work.`,
        },
        'Shalford': {
            name: 'Shalford',
            government: `City State Monarchy ‐ King/Queen`,
            description: `A  moderately  active  seaport  until  gold  was 
            discovered  12  years  ago.  Now,  the  city  is  growing 
            wildly.  Dwarves  are  flocking  to  the  town  and 
            building homes outside the walls. The gold rush has 
            led  to  increased  crime  and  confusion  in  the  city. 
            Longtime  residents  are  feeling  the  surge  of  new 
            treasure hunters.`,
            economy: `The  city  is  currently  experiencing  a  gold  rush  and 
            the economy is booming.
            `,
            notableBusinesses: `The Singing Siren Inn, 
            The New Ship Inn, 
            Ironfoot’s Tavern, 
            The Easy Money Inn and Gambling House, 
            Stonegrim’s Smithy, 
            The Shalford Gold Company, 
            Shalford Imports and Exports, 
            Bromley and Sons Winery
            `,
            attractions: `Warmund Quay  ‐  An  historic  quay  located  at  the 
            heart of the port. <br />
            Bromley  and  Sons Winery  ‐  A  popular  winery  that 
            produces excellent vintages. <br />
            The Ulfred Stone ‐ A large multifaceted stone that is 
            kept  on  display  in  the  throne  room.  It  was 
            discovered generations ago and is now an heirloom 
            of the royal family. 
            `,
        },
        'Stonea Camp': {
            name: 'Stonea Camp',
            government: `Adhocracy Minarchism ‐ Foreman`,
            description: `Founded about five years ago, Stonea Camp serves 
            the  workers  in  the  lumber  and  timber  industries. 
            Most of the buildings are wooden bunkhouses.`,
            economy: `Growing  because  of  the  increased  demand  for 
            timber`,
            notableBusinesses: `The Saw Mill,
            The Common Room Inn`,
            attractions: `None.  Unless  you  consider  muddy  streets  and 
            grumpy workmen attractions.
            `,
        },
        'Norigrad': {
            name: 'Norigrad',
            government: `City State Monarchy ‐ King/Queen`,
            description: `A  strategic  seaport  that  has  been  fortified  for 
            several  generations. The  stonework  of  the  city  is 
            austere  and  appears  formidable  upon  approach. A 
            massive  multigenerational  palace  looms  over  the 
            city.
            `,
            economy: `The economy is stable and only growing slightly. `,
            notableBusinesses: `Candlekeep Library,
            The Broken Chair Inn,
            Valora’s Books,
            Yule’s Fine Gems
            `,
            attractions: `The Battlefield of Wakeman  ‐ An ancient battlefield 
            that is now considered a holy site. `,
        }
    }
}

export default items;