import React, { useState, useEffect } from 'react';
import './fades.module.css';

import { MenuItem, Select } from '@material-ui/core';

import experiences from '../data/unusualExperiences';

export function SelectExperience(props) {
    const { formData, setFormData } = props;
    const [infoText, setInfoText] = useState('')
    const [experience, setExperience] = useState(formData.experience)
    const [options, setOptions] = useState([]);
    const [initLoad, setInitLoad] = useState(true);

    useEffect(() => {
        setExperience(formData.experience);

        let tempArr = [];
        let experienceChoices = Object.keys(experiences);
        experienceChoices.forEach(element =>
            tempArr.push(<MenuItem key={Math.random()} value={element}>{element}</MenuItem>)
        );
        setOptions(tempArr);

        if (initLoad && experience != '') {
            setInfoText(experiences[experience]);
        }
        setInitLoad(false);
    }, [formData.experience]);


    const handleChange = (event) => {
        setFormData({ ...formData, experience: event.target.value });
        setInfoText(experiences[event.target.value])
    };

    return (
        <div>
            <Select value={experience} onChange={handleChange} >
                {options ? options : <MenuItem></MenuItem>}
            </Select>
            {infoText ?
                <div className="fancyBorder">
                    {infoText ? <p dangerouslySetInnerHTML={{ __html: infoText }}></p> : ''}
                </div> : ''
            }
        </div>

    )
}