const items = {
    'customPerform': {
        'category': 'Coordination',
        'name': 'Performer of....',
        'id': 'customPerform',
        'description': 'You can put on a good show.  Pick a category of entertainment when you select the Skill.  You could be a juggler, acrobat, or dancer. You can buy this Skill more than once for different specialties. The Perform Skill is listed under Command as well, but that’s for putting on a show that’s mostly about who you are, not what you’re doing.  If there’s any question, just pick a Stat.  Expected possessions: A costume.',
        'numberOfDice': 1,
        'tempModifier': 0,
        'expertOrMaster': 0,
        'custom': true
    },
    'customWeapon': {
        'category': 'Coordination',
        'name': 'Unique Weapon Skill',
        'id': 'customWeapon',
        'description': 'Pick a category of weapons like “bows” or “axes.”  You’ve been trained to hurt and kill with weapons of that type.  You can choose “empty hand striking” or “grappling” as Weapon Skills too, instead of using the broader Fight Skill.',
        'numberOfDice': 1,
        'tempModifier': 0,
        'expertOrMaster': 0,
        'custom': true
    },
    'customLanguage': {
        'category': 'Knowledge',
        'name': 'Knowledge of a Language',
        'id': 'customLanguage',
        'description': 'Every character starts out with one Master Die in his native language.  If you wish, you can buy additional dice, to help explain complex concepts, or understand them, or read.  You can also purchase this Skill multiple times, picking a different language each time.  Language Skills are unique in that you combine your Skill with your listener’s Skill and roll that as a pool. Expected possessions: A set of decent, if unremarkable, clothes.',
        'numberOfDice': 1,
        'tempModifier': 0,
        'expertOrMaster': 0,
        'custom': true
    },
    'customSubject': {
        'category': 'Knowledge',
        'name': 'Student of...',
        'id': 'customSubject',
        'description': 'You’re knowledgeable about something that’s not already on the list.  It’s a way to let people have characters who are wise in the ways of identifying birds or designing aqueducts without listing every field of human study. Expected possessions: Depends entirely on the field of study.',
        'numberOfDice': 1,
        'tempModifier': 0,
        'expertOrMaster': 0,
        'custom': true
    }
}

export default items;