const items = {
    'Primitive' : {
        name : 'Primitive',
        description : "Of all peoples, primitives are those that live closest with the land, in tune with its hidden secrets. Other cultures commonly dismiss them as little more than animals, yet they merely eschew the technology that so many others take for granted; relying instead on flint spears and arrows, and their skill for survival in the wild. Living in extended family groups, primitive cultures gather around very simple dwellings that can range from caves and areas of natural shelter through to lean-tos or very basic huts. Primitives are typically hunter-gatherers with either few, or poorly developed, systems for farming and husbandry. However, certain cultural beliefs, often based on superstitions, can be very well defined. Few primitive cultures have developed anything approaching a written language although paintings and other pictorial symbols serve as a means of communication.",
        standardSkills : "Brawn, Endurance, Evade, Locate, Perception, Stealth, Swimming.",
        exampleCombatStyles : "Ghost Warrior, Head Hunter, Jaguar Brother, Jungle Savage, Savannah Hunter.",
        ProfessionalSkills : "Craft (any), Healing, Lore (any), Navigation, Survival, Tracking.",
        culturalPassions : ['Loyalty to Chief/Headman' , ' Love (friend, sibling or romantic lover) ', ' Hate (something that scares or intimidates you)']
    },
    'Nomadic' : {
        name : 'Nomadic',
        description : "Nomadic people are constantly on the move, with no home and hearth to call their own. They may wander aimlessly about, or might have several camps they move to and from throughout the year. they raise few crops and instead follow the migrations of animals or fish, perhaps even herding their own domesticated beasts. Nomads are adept at subsisting on what they can quickly and easily scavenge from their surroundings, letting nothing go to waste. the skills of the nomad culture should be tailored to their environment. Some journey vast distances on foot, others live in caravans or ride strange creatures, and some drift across the ocean on great floating rafts.",
        standardSkills : "Endurance, First Aid, Locale, Perception, Stealth; Athletics, Riding.",
        exampleCombatStyles : "Camel Cavalry, Feathered Death, Horse Lord, Whale Hunter, Charioteer.",
        ProfessionalSkills : "Craft (any), Culture (any), Language (any), Lore (any), Navigation, Survival, Tracking.",
        culturalPassions : ['Loyalty to Tribal Chieftain/Khan ', ' Love (friend, sibling or romantic lover) ', ' Hate (creature, rival or tribe)']
    },
    'Barbaric' : {
        name : 'Barbaric',
        description : "Tribal in nature, barbarians tend to shun civilization, viewing those who live in large towns and cities as weak and corrupt. Although their own settlements are also sedentary, they tend to be far smaller and closer to nature. Barbarian tribes occupy distinct territories, often half tamed wilderness, which they regard as their own for hunting, grazing and farming purposes. Most are adept in the use of weaponry as they must overcome many dangers throughout their lives - facing creatures of the wilds or mustering to defend their land against rival tribes. Since they live in small communities the ties of kinship and clan are strong elements of their society.",
        standardSkills : "Athletics, Brawn, Endurance, First Aid, Locale, Perception, and either Boating or Riding.",
        exampleCombatStyles : "Barbaric Fyrdman, Berserker, Horse Eater, Seaborne, Reaver, Wolf Hunter.",
        ProfessionalSkills : "Craft (any), Healing, Lore (any), Musicianship, Navigation, Seamanship, Survival, Tracking.",
        culturalPassions : ['Loyalty to Clan Chieftain ', ' Love (friend, sibling or romantic love) ', ' Hate (creature, rival or clan)']
    },
    'Civilized' : {
        name : 'Civilized',
        description : "The Civilized culture is epitomized by a semblance of law and order, underpinned by complex social codes, and supported by a bureaucracy. Civilized people believe themselves superior to all other cultures because their achievements tend towards permanence: sprawling cities, imposing temples, celebratory monuments, and the recording of history and events in books, tomes, and scrolls. Of course this is a thin veneer. The most civilized of cities can be more lawless than any barbarian frontier settlement; and the social codes may be cruder than the hospitality rituals of nomads. But the Civilized culture prides itself on its achievements, its infrastructure, its professional artisans, and its ability to easily extend its reach to wherever it wishes to go.",
        standardSkills : "Conceal, Deceit, Drive, Influence, Insight, Locale, Willpower.",
        exampleCombatStyles : "Citizen Legionary, City-state Phalangite, Levied Archer, Light Skirmisher, Street Thug, Town Militia.",
        ProfessionalSkills : "Art (any), Commerce, Craft (any), Courtesy, Language (any), Lore (any), Musicianship, Street-smarts.",
        culturalPassions : ['Loyalty to Town/City ', ' Love (friend, sibling or romantic lover) ', ' Hate (rival, gang, district or city)']
    }
}

export default items;