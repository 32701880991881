/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './fades.module.css';
import Die from "./die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from "../../css/colors.module.css";

import homes from '../data/homes';
import months from '../data/months';
import occupations from '../data/occupations';
import origins from '../data/origins';
import problems from '../data/problems';
import races from '../data/races';
import unusualExperiences from '../data/unusualExperiences';

import { postCharacter } from '../../../backend/characters/characters';
import { CircularProgress } from '@material-ui/core';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export const CharacterSheet = (props) => {
    const { formData, setFormData } = props;

    //For adding new skills to the skills grid
    const [baseAdvantageTable, setBaseAdvantageTable] = useState([]);
    const [bodyTable, setBodyTable] = useState([]);
    const [coordinationTable, setCoordinationTable] = useState([]);
    const [senseTable, setSenseTable] = useState([]);
    const [knowledgeTable, setKnowledgeTable] = useState([]);
    const [commandTable, setCommandTable] = useState([]);
    const [charmTable, setCharmTable] = useState([]);
    const [addedRelations, setAddedRelations] = useState([]);
    const [loading, setLoading] = useState(false);

    const [points, setPoints] = useState(formData.totalPoints);
    const [successfullySaved, setSuccessfullySave] = useState(1);

    useEffect(() => {
        let relationsCopy = formData.relations;
        let baseAdvantages = formData.advantages;
        let baseBody = formData.skills.Body;
        let baseCoordination = formData.skills.Coordination;
        let baseSense = formData.skills.Sense;
        let baseKnowledge = formData.skills.Knowledge;
        let baseCommand = formData.skills.Command;
        let baseCharm = formData.skills.Charm;
        let tempAdvantageArr = [];
        let tempBodyArr = [];
        let tempCoordinationArr = [];
        let tempSenseArr = [];
        let tempKnowledgeArr = [];
        let tempCommandArr = [];
        let tempCharmArr = [];
        let tempArrRelationships = [];

        Object.keys(baseAdvantages).forEach(key => {
            if (baseAdvantages[key].taken) {
                tempAdvantageArr.push(<OneLineAdvantages key={Math.random()} points={points} setPoints={setPoints} object={baseAdvantages[key]} formData={formData} setFormData={setFormData}></OneLineAdvantages>)
            }
        });
        setBaseAdvantageTable(tempAdvantageArr);
        Object.keys(baseBody).forEach(key => {
            tempBodyArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseBody[key]} category="Body" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setBodyTable(tempBodyArr);
        Object.keys(baseCoordination).forEach(key => {
            tempCoordinationArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseCoordination[key]} category="Coordination" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setCoordinationTable(tempCoordinationArr);
        Object.keys(baseSense).forEach(key => {
            tempSenseArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseSense[key]} category="Sense" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setSenseTable(tempSenseArr);
        Object.keys(baseKnowledge).forEach(key => {
            tempKnowledgeArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseKnowledge[key]} category="Knowledge" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setKnowledgeTable(tempKnowledgeArr);
        Object.keys(baseCommand).forEach(key => {
            tempCommandArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseCommand[key]} category="Command" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setCommandTable(tempCommandArr);
        Object.keys(baseCharm).forEach(key => {
            tempCharmArr.push(<OneLineSkills key={Math.random()} points={points} setPoints={setPoints} object={baseCharm[key]} category="Charm" formData={formData} setFormData={setFormData}></OneLineSkills>)
        });
        setCharmTable(tempCharmArr);
        relationsCopy.forEach(element =>
            tempArrRelationships.push(<OneLineRelationship key={Math.random()} object={element} formData={formData} setFormData={setFormData}></OneLineRelationship>)
        );
        setAddedRelations(tempArrRelationships);

    }, [formData]);

    return (
        <div>
            <div className='diceBox'>
                <div className={`gridBackground`} style={{ width: '100%', marginBottom: '30px', color: '#5C5C5C', backgroundColor: '#f0f2ef', textAlign: 'left' }}>
                    <div className="liftedCard" style={{ backgroundColor: '#71332C', color: '#F0F2EF', marginBottom: '20px', marginRight: 'clamp(0px, 5%, 10px)', border: '4px double #f0f2ef' }}>
                        <div className="row">
                            <div style={{ textAlign: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'right', fontSize: '2.5em', fontFamily: 'Impact, fantasy', paddingRight: 'clamp(0px, 5%, 10px)' }}>
                                {formData.name ? formData.name : 'Character Name'}
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: 'clamp(0px, 5%, 20px)', paddingRight: 'clamp(0px, 5%, 10px)' }}>
                        <Accordion>
                            <AccordionSummary style={{ backgroundColor: 'rgba(79, 115, 91, .6)' }} expandIcon={<ExpandMore />}>Body<span style={{ float: 'right' }}>{formData.stats.Body.number} Point{formData.stats.Body.number > 1 ? 's' : ''}</span></AccordionSummary>
                            <AccordionDetails>
                                <IndividualTables tableName="Body" points={points} setPoints={setPoints} table={bodyTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{ backgroundColor: 'rgba(172, 139, 18, .6)' }} expandIcon={<ExpandMore />}>Co-ordination<span style={{ float: 'right' }}>{formData.stats.Coordination.number} Point{formData.stats.Coordination.number > 1 ? 's' : ''}</span></AccordionSummary>
                            <AccordionDetails>
                                <IndividualTables tableName="Coordination" points={points} setPoints={setPoints} table={coordinationTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{ backgroundColor: 'rgba(141, 117, 118, .6)' }} expandIcon={<ExpandMore />}>Sense<span style={{ float: 'right' }}>{formData.stats.Sense.number} Point{formData.stats.Sense.number > 1 ? 's' : ''}</span></AccordionSummary>
                            <AccordionDetails>
                                <IndividualTables tableName="Sense" points={points} setPoints={setPoints} table={senseTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{ backgroundColor: 'rgba(166, 60, 48, .6)' }} expandIcon={<ExpandMore />}>Knowledge<span style={{ float: 'right' }}>{formData.stats.Knowledge.number} Point{formData.stats.Knowledge.number > 1 ? 's' : ''}</span></AccordionSummary>
                            <AccordionDetails>
                                <IndividualTables tableName="Knowledge" points={points} setPoints={setPoints} table={knowledgeTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{ backgroundColor: 'rgba(6, 107, 157, .6)' }} expandIcon={<ExpandMore />}>Command<span style={{ float: 'right' }}>{formData.stats.Command.number} Point{formData.stats.Command.number > 1 ? 's' : ''}</span></AccordionSummary>
                            <AccordionDetails>
                                <IndividualTables tableName="Command" points={points} setPoints={setPoints} table={commandTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{ backgroundColor: 'rgba(214, 123, 113, .6)' }} expandIcon={<ExpandMore />}>Charm<span style={{ float: 'right' }}>{formData.stats.Charm.number} Point{formData.stats.Charm.number > 1 ? 's' : ''}</span></AccordionSummary>
                            <AccordionDetails>
                                <IndividualTables tableName="Charm" points={points} setPoints={setPoints} table={charmTable} setFormData={setFormData} formData={formData} ></IndividualTables>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{ backgroundColor: 'rgba(1, 25, 54, .4)' }} expandIcon={<ExpandMore />}>Advantages</AccordionSummary>
                            <AccordionDetails>
                                <div className="row" style={{ width: '100%', maxWidth: '90vw', overflowX: 'scroll', scrollbarWidth: 'thin', marginBottom: '10px' }}>
                                    <div className="columns" style={{ marginLeft: "0px", marginBottom: "10px" }}>
                                        <table style={{ width: '100%' }} >
                                            <tr>
                                                <th>Name</th>
                                                <th style={{ minWidth: "350px" }} >Description</th>
                                            </tr>
                                            {baseAdvantageTable ? baseAdvantageTable : <></>}
                                        </table>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="liftedCard" style={{ backgroundColor: '#71332C', color: '#F0F2EF', marginTop: '30px', marginBottom: '20px', marginRight: 'clamp(0px, 5%, 10px)', border: '4px double #f0f2ef' }}>
                        <div className="row">
                            <div style={{ textAlign: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'right', fontSize: '1.5em', fontFamily: 'Impact, fantasy', paddingRight: 'clamp(0px, 5%, 10px)' }}>
                                Backstory
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: 'clamp(0px, 5%, 20px)', paddingRight: 'clamp(0px, 5%, 10px)' }}>
                        <div className="row">
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: 'rgba(220,220,200,.8)', color: '#311E10' }} expandIcon={<ExpandMore />}>Birth Month<span style={{ float: 'right' }}>{formData.birthMonth}</span></AccordionSummary>
                                    <AccordionDetails style={{ display: 'grid' }}>
                                        {
                                            months[formData.birthMonth] ?
                                                <div className='fancyBorder'>
                                                    <p dangerouslySetInnerHTML={{ __html: months[formData.birthMonth] }}></p>
                                                </div>
                                                : ''}
                                        {
                                            formData.age ?
                                                <div className="row">
                                                    <b className="heavyWords">Age: </b>{formData.age ? formData.age : ''}
                                                </div> : ''
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: '#F0F2EF', color: '#311E10' }} expandIcon={<ExpandMore />}>Race<span style={{ float: 'right' }}>{formData.race}</span></AccordionSummary>
                                    <AccordionDetails style={{ display: 'grid' }}>
                                        <div className='row'>
                                            {races[formData.race] ?
                                                <div className="fancyBorder">
                                                    <p><b>Physical Description: </b>{races[formData.race].description}</p>
                                                    <p><b>Society: </b>{races[formData.race].society}</p>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                        {
                                            formData.userAdditionalInfo ?
                                                <div className="row">
                                                    <b className="heavyWords">Additional Character Info: </b> {formData.userAdditionalInfo}
                                                </div> : ''
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: 'rgba(220,220,200,.8)', color: '#311E10' }} expandIcon={<ExpandMore />}>Social Class<span style={{ float: 'right' }}>{formData.socialClass}</span></AccordionSummary>
                                    <AccordionDetails>
                                        {origins[formData.socialClass] ?
                                            <div className="fancyBorder">
                                                <p><b>Description: </b>{origins[formData.socialClass].description}</p>
                                                <p><b>Standard <span className={styles.green}>Skills </span>: </b>{origins[formData.socialClass].standardSkills} <br />
                                                    <b>Example Combat Styles: </b>{origins[formData.socialClass].exampleCombatStyles} <br />
                                                    <b>Professional <span className={styles.green}>Skills </span>: </b>{origins[formData.socialClass].ProfessionalSkills} <br />
                                                    <b>Cultural Passions: </b>{origins[formData.socialClass].culturalPassions}</p>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: '#F0F2EF', color: '#311E10' }} expandIcon={<ExpandMore />}>Home<span style={{ float: 'right' }}>{formData.home}</span></AccordionSummary>
                                    <AccordionDetails style={{ display: 'grid' }}>
                                        <div className="row">
                                            {
                                                formData.socialClass ?
                                                    homes[formData.socialClass][formData.home] ?
                                                        <div className="fancyBorder">
                                                            <div>
                                                                {homes[formData.socialClass][formData.home].location ? <div><b>Location:</b> {homes[formData.socialClass][formData.home].location}<br /> </div> : ''}
                                                                {homes[formData.socialClass][formData.home].government ? <div><b>Government:</b> {homes[formData.socialClass][formData.home].government}<br /> </div> : ''}
                                                                {homes[formData.socialClass][formData.home].economy ? <div><b>Economy:</b> {homes[formData.socialClass][formData.home].economy}<br /> </div> : ''}
                                                                {homes[formData.socialClass][formData.home].jobs ? <div><b>Example Jobs:</b> {homes[formData.socialClass][formData.home].jobs}<br /> </div> : ''}
                                                            </div>
                                                            {homes[formData.socialClass][formData.home].description ?
                                                                <p><b>Description:</b> {homes[formData.socialClass][formData.home].description}<br /></p> : ''
                                                            }
                                                            {homes[formData.socialClass][formData.home].baseDescription ?
                                                                <div><b>Description: </b><p dangerouslySetInnerHTML={{ __html: homes[formData.socialClass][formData.home].baseDescription || '' }}></p></div> : ''
                                                            }
                                                            <p>
                                                                {homes[formData.socialClass][formData.home].notableBusinesses ? <div><b>Notable Businesses:</b> {homes[formData.socialClass][formData.home].notableBusinesses}<br /> </div> : ''}
                                                            </p>
                                                            {homes[formData.socialClass][formData.home].attractions ?
                                                                <div><b>Attractions: </b><p dangerouslySetInnerHTML={{ __html: homes[formData.socialClass][formData.home].attractions || '' }}></p></div> : ''
                                                            }
                                                        </div> : '' : ''
                                            }
                                        </div>
                                        {
                                            formData.homeAdditionalInfo ?
                                                <div className="row">
                                                    <b className="heavyWords">Additional Home Info: </b>{formData.homeAdditionalInfo}
                                                </div> : ''
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: 'rgba(220,220,200,.8)', color: '#311E10' }} expandIcon={<ExpandMore />}>Occupation<span style={{ float: 'right' }}>{formData.occupation}</span></AccordionSummary>
                                    <AccordionDetails style={{ display: 'grid' }}>
                                        <div className="row">
                                            {
                                                occupations[formData.occupation] ?
                                                    <div className="fancyBorder">
                                                        {occupations[formData.occupation].descriptionSubType ? <div><b>Sub-disciplines: </b> {occupations[formData.occupation].descriptionSubType}<br /> </div> : ''}
                                                        {occupations[formData.occupation].description ? <p><b>Description:</b> {occupations[formData.occupation].description}<br /></p> : ''}
                                                        {occupations[formData.occupation].trainedWeapon ? <div><b>Trained Weapon:</b> {occupations[formData.occupation].trainedWeapon}<br /> </div> : ''}
                                                        {occupations[formData.occupation].tradeGoods ? <div><b>Trade Good: </b> {occupations[formData.occupation].tradeGoods}<br /> </div> : ''}
                                                    </div> : ''
                                            }
                                        </div>
                                        {
                                            formData.occupationAdditionalInfo ?
                                                <div className="row">
                                                    <b className="heavyWords">Additional Occupation Info: </b>{formData.occupationAdditionalInfo}
                                                </div> : ''
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: '#F0F2EF', color: '#311E10' }} expandIcon={<ExpandMore />}>Unusual Experience<span style={{ float: 'right' }}>{formData.experience}</span></AccordionSummary>
                                    <AccordionDetails style={{ display: 'grid' }}>
                                        <div className="row">
                                            {unusualExperiences[formData.experience] ?
                                                <div className="fancyBorder">
                                                    {unusualExperiences[formData.experience] ? <p dangerouslySetInnerHTML={{ __html: unusualExperiences[formData.experience] }}></p> : ''}
                                                </div> : ''
                                            }
                                        </div>
                                        {
                                            formData.experienceAdditionalInfo ?
                                                <div className="row">
                                                    <b className="heavyWords">Additional Experience Info: </b>{formData.experienceAdditionalInfo}
                                                </div> : ''
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: 'rgba(220,220,200,.8)', color: '#311E10' }} expandIcon={<ExpandMore />}>Problem<span style={{ float: 'right' }}>{formData.problem}</span></AccordionSummary>
                                    <AccordionDetails style={{ display: 'grid' }}>
                                        <div className="row">
                                            {problems[formData.problem] ?
                                                <div className="fancyBorder">
                                                    {problems[formData.problem] ? <p dangerouslySetInnerHTML={{ __html: problems[formData.problem] }}></p> : ''}
                                                </div> : ''
                                            }
                                        </div>
                                        {
                                            formData.problemAdditionalInfo ?
                                                <div className="row">
                                                    <b className="heavyWords">Additional Problem Info: </b>{formData.problemAdditionalInfo}
                                                </div> : ''
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: '30px' }}>
                            <div className="columns ">
                                <Accordion>
                                    <AccordionSummary style={{ backgroundColor: '#F0F2EF', color: '#311E10' }} expandIcon={<ExpandMore />}>Relationships<span style={{ float: 'right' }}>{formData.relations.length}</span></AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            formData.relations.length > 0 ?
                                                <RelationshipsTable addedRelations={addedRelations}></RelationshipsTable> : <div className="heavyWords" style={{ color: '#000' }}>None</div>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="row">
                        <div style={{ textAlign: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', fontSize: '1.5em', fontFamily: 'Impact, fantasy', paddingRight: 'clamp(0px, 5%, 15px)' }}>
                            <div className="liftedCard" style={{ color: '#000', padding: '0px' }}>
                                <input type="button" style={{ margin: '0px' }} value='Save' disabled={loading ? true : false} onClick={() => { normalizeCharacterData(formData, setSuccessfullySave, setLoading) }} id={Math.random()} />
                            </div>
                            {loading ? <CircularProgress /> : ''}
                        </div>
                    </div>
                </div>
            </div >
            <div className='row'>
                <div className='diceBox'>
                    <ErrorBox successOrFailure={successfullySaved} />
                </div>
            </div>
        </div>
    )
}

export function normalizeCharacterData(data, setSuccessfullySave, setLoading) {
    let tempData = data; 

    delete tempData.customSkillsTemplates;

    Object.keys(tempData.stats).forEach(key => {
        delete tempData.stats[key].description;
    });

    Object.keys(tempData.skills.Body).forEach(key => {
        delete tempData.skills.Body[key].description;
    });
    Object.keys(tempData.skills.Coordination).forEach(key => {
        delete tempData.skills.Coordination[key].description;
    });
    Object.keys(tempData.skills.Knowledge).forEach(key => {
        delete tempData.skills.Knowledge[key].description;
    });
    Object.keys(tempData.skills.Command).forEach(key => {
        delete tempData.skills.Command[key].description;
    });
    Object.keys(tempData.skills.Sense).forEach(key => {
        delete tempData.skills.Sense[key].description;
    });
    Object.keys(tempData.skills.Charm).forEach(key => {
        delete tempData.skills.Charm[key].description;
    });

    Object.keys(tempData.advantages).forEach(key => {
        if (!tempData.advantages[key].taken) {
            delete tempData.advantages[key];
        }
    });
    submitNewCharacter(tempData, setSuccessfullySave, setLoading);
}

async function submitNewCharacter(character, setSuccessfullySave, setLoading) {
    if (character.name != "") {
        setLoading(true);
        try {
            let result = await postCharacter(character);

            if (result.statusCode == '200') {
                console.log(character);
                setSuccessfullySave(3);
            }
            setLoading(false);
        } catch (error) {
            console.log(character);
            setSuccessfullySave(2);
            setLoading(false);
            return {
                statusCode: error.statusCode || 500,
                body: JSON.stringify(error),
            };
        }
    } else {
        setSuccessfullySave(5);
    }
}

export function ErrorBox(props) {
    const { successOrFailure } = props;
    return (

        <div style={{ marginBottom: '10px' }}>
            {
                successOrFailure == 3 ?
                    <div className="liftedCard" style={{ color: '#4f8a10', backgroundColor: '#dff2bf', marginTop: '10px', borderRadius: '10px', minWidth: '200px' }}>
                        <p style={{ marginBottom: '0px' }}>Success! New Character has been saved! <br /> Check them out by going to 'Character Lookup' and typing in their name.</p>
                    </div> : successOrFailure == 2 ?
                        <div className="liftedCard" style={{ color: '#e02730', backgroundColor: '#ffbaba', marginTop: '10px', borderRadius: '10px', minWidth: '200px' }}>
                            <p style={{ marginBottom: '0px' }}>Save Failed! <br /> But all is not lost. Go to the web console in your browser (right click anywhere and click 'inspect' then navigate
                                to console), there should be an object there that has your character info in it, maximise it, then copy and email it to me, also send me any errors you see. </p>
                        </div> : successOrFailure == 5 ?
                            <div className="liftedCard" style={{ color: '#a86e11', backgroundColor: '#feefb3', marginTop: '10px', borderRadius: '10px', minWidth: '200px' }}>
                                <p style={{ marginBottom: '0px' }}>A Man Needs a Name.<br /> Fill out at least the bare essentials ya cheeky git. </p>
                            </div> :
                            ''
            }
        </div>
    )
}

export function IndividualTables(props) {
    const { tableName, table, formData } = props;
    return (
        <div className="row" style={{ width: '100%', maxWidth: '100wh', overflowX: 'scroll', scrollbarWidth: 'thin', marginBottom: '10px' }}>
            <div className="columns" style={{ marginLeft: "0px", marginBottom: "10px", background: '#f8f8f8', borderRadius: '15px' }}>

                <table style={{ width: '100%', paddingLeft: '30px', paddingRight: '30px' }} >
                    <thead>
                        <th>Number of Base Stat Dice</th>
                        <th style={{ textAlign: 'center' }}>Dice Tracker</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {formData.stats[tableName].number}
                            </td>
                            <td>
                                <div className="diceBox">
                                    {
                                        formData.stats[tableName].numDice.map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <b>
                        {formData.stats[tableName].description ? formData.stats[tableName].description : ''}
                    </b>
                    <hr></hr>
                </div>
            </div>
            <div className="columns" style={{ marginLeft: "0px", marginBottom: "10px" }}>
                <table style={{ width: '100%' }} >
                    <tr>
                        <th>Name</th>
                        <th style={{ minWidth: "350px" }} >Description</th>
                        <th>Stat Points</th>
                        <th>Dice Tracker</th>
                        <th>Expert-Master</th>
                    </tr>
                    {table ? table : <></>}
                </table>
            </div>
        </div>
    )
}

export function OneLineSkills(props) {
    const { object } = props;
    return (
        <tr>
            <td><b>{object.displayName ? object.displayName : object.name}</b></td>
            <td>{object.description}</td>
            <td>
                {object.number}
            </td>
            <td>
                <div className="diceBox">
                    {object.numDice ?
                        object.numDice.map((v) => (
                            <Die key={Math.random()} value={v} />
                        )) : ''
                    }
                </div>
            </td>
            <td>
                <div style={{ display: 'flex' }}>
                    <input type="checkbox" className="largeCheckbox" checked={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? true : false} id={object.name} value={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? true : false} style={{ marginRight: '20px' }} />
                    <input type="checkbox" className="largeCheckbox" checked={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? false : true} id={object.name} value={object.expertOrMaster == 0 ? false : object.expertOrMaster == 1 ? false : true} />
                </div>
            </td>
        </tr>
    )
}

export function OneLineAdvantages(props) {
    const { object } = props;
    return (
        <tr>
            <td><b>{object.name}</b></td>
            <td>{object.description}</td>
        </tr>
    )
}

export function OneLineRelationship(props) {
    const { object } = props;

    return (
        <tr>
            <td>{object.name}</td>
            <td>{object.description}</td>
            <td>{object.relation}</td>
            <td>{object.alive === true ? 'Yes' : ''}</td>
        </tr>
    )
}

export function RelationshipsTable(props) {
    const { addedRelations } = props;

    return (
        <div className="row" style={{ width: '100%', maxWidth: '90vw', overflowX: 'scroll', scrollbarWidth: 'thin', marginBottom: '10px' }}>
            <div className="columns" style={{ marginBottom: "10px" }}>
                <table style={{ width: '100%' }} >
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Relation</th>
                        <th>Alive</th>
                    </tr>
                    {addedRelations ? addedRelations : <></>}
                </table>
            </div>
        </div>
    )
}