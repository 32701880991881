import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Navigation = () => {
    const [menuToggle, setMenuToggle] = useState('');

    return (
        <div>
            <div className="navbar-component">
                <div className="navbar area" style={{ fontFamily: "typewriterStyle2" }}>
                    <Link className="brand" to="/" style={{ fontFamily: "runeFont" }}> This is Saltmarsh DEV</Link>
                    <nav role="navigation" id="navigation" className={`list ${menuToggle}`}>
                        <Link className="item -link" to="/CharacterCreator">Character Creator</Link>
                        <Link className="item -link" to="/HowToPlay">How To Play</Link>
                        <Link className="item -link" to="/Library">Library</Link>
                        <Link className="item -link" to="/CharacterLookup">Character Lookup</Link>
                        <span className="item"><i className="fa fa-search"></i></span>
                    </nav>
                    <button className="toggle" onClick={() => menuToggle === '' ? setMenuToggle('-on') : setMenuToggle('')}>
                        <span className="icon"></span>
                    </button>
                </div>
            </div>

            <div className='white'>
                <div className="ribbon-1"></div>
                <div className="App"></div>
                <div className='squares'>
                    <div className='square'>A</div>
                    <div className='square'>B</div>
                    <div className='square'>C</div>
                    <div className='square'>D</div>
                    <div className='square'>R</div>
                    <div className='square'>S</div>
                    <div className='square'>G</div>
                    <div className='square'>H</div>
                    <div className='square'>I</div>
                    <div className='square'>J</div>
                    <div className='square'>K</div>
                    <div className='square'>L</div>
                    <div className='square'>M</div>
                    <div className='square'>N</div>
                    <div className='square'>X</div>
                    <div className='square'>P</div>
                    <div className='square'>S</div>
                    <div className='square'>L</div>
                    <div className='square'>M</div>
                    <div className='square'>N</div>
                    <div className='square'>P</div>
                    <div className='square'>E</div>
                </div>
            </div>
            <div className='grey'></div>
        </div>
    );
}