import React from "react";
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import CharacterCreator from './CharacterCreator.js'
import Home from './Home.js'
import HowToPlay from "./HowToPlay.js";
import CharacterLookup from "./CharacterLookup.js";
import CharacterView from "./CharacterView.js";
import { Library } from "./Library.js";

import './assets/css/firefly.css'
import './assets/css/navbar.css'

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="HowToPlay" element={<HowToPlay />} />
      <Route path="CharacterCreator" element={<CharacterCreator />} />
      <Route path="Library" element={<Library />} />
      <Route path="CharacterLookup" element={<CharacterLookup />} >
        <Route path=":CharacterID" element={<CharacterView />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>,
  rootElement
);
