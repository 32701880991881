const items = {
    'Drunken Blackouts': `When you drink, you do foolish, dangerous and/or illegal things. After sobering up, you don’t remember them. The player decides when a Blackout kicks in, but the GM controls that character until the binge ends. When this behavior causes trouble, you get the 1XP reward. (If you find a way to black out without causing trouble you don’t get the XP.)`,
    'Gruesome': `You’re not just homely or plain. It’s actively unpleasant to look upon your face. Your GM may give –1d penalties to Command or Charm Skills, if he feels that being appallingly hideous might impact them. You cannot take this and the ‘Beauty’ Advantage. You get a bonus XP in any session where this penalty is levied. `,
    'Hated Enemy': `Someone hates you. Maybe you know who, maybe not. Whenever this enemy’s acts harm or inconvenience you, you get a bonus XP.`,
    'Jinx': `Once per session, the GM can nullify one successful set you’ve rolled. You cannot appeal or re-roll. `,
    'Misplaced Confidence': `Pick a Skill in which you have misplaced confidence. Any rolls against that Skill have their Difficulty increased by 3. If you fail a Skill roll because of that Difficulty, you get an extra XP.`,
    'Missing Arm': `If you attempt a task where you’d normally use both arms (trying to climb a tree or pull a rickshaw), your GM can assess a Difficulty—something around 3-5 is typical. Finally, if someone hits you in hit locations 3-4 (if you’re missing your left arm) or 5-6 (if it’s your right) the damage goes straight into your torso. Any time one of these difficulties arises, expect an extra XP at the session’s end`,
    'Missing Hand': `You still have the arm, it’s just tipped with a hook or some other sort of prosthesis. You still may face Difficulties for two-handed actions, but you still have wound boxes in your arm. Nevertheless, when a hit turns up at location 4 (if your left hand is gone) or 6 (if it’s right) the damage goes into your torso.`,
    'Peg Leg': `Instead of a leg, you’ve got a shoddy wooden prosthesis. Any time you try to run, climb or perform some other leg-intensive action, you face a Difficulty of 3-5 (GM’s discretion). Any time a hit turns up that would strike you in your missing limb, the damage goes right into your torso.`,
    'Repulsive to Animals': `All your Expert Animal Trainer and Ride rolls have their Difficulty raised by 3. If you fail a roll because of this Difficulty, you get an extra XP at the end of the session. Furthermore, wild animals or even tame ones are more likely to attack you.  Any time this happens, you can expect the XP payoff at session’s end.`,
    'Stupidly Forgiving': `Instead of polishing off a helpless foe, you’re likely to give him a lecture and let him go. It’s up to you to try and activate this Problem—you have to say you’re letting someone off with a warning. Once you decide to show mercy to an enemy, it’s up to the GM to decide if this comes back to haunt you. If it ever does, you earn that XP.`,
    'Unwholesome': `The first time you meet someone, he rolls his Eerie pool. With a success, something about you makes them feel creepy. You can still win them over with charm or bribery or an obvious display of good deeds, but you start out with one social strike against you.`,
    'Poverty': `You were born poor, relative to the norm of your culture, or lost your money somehow. As such you find it difficult to spend money or get rid of things that you may need 'one day'.`,
    'Addiction': `You are addicted to a drug, which you must use daily or suffer the penalties of withdrawal. The bonus for this disadvantage depends on the nature of the drug addiction. 
    <br /> Examples: Tobacco is cheap, highly addictive, and legal; a chain-smoker may only be put at disadvantage by this on occasion. Heroin is very expensive, incapacitating, illegal and totally addictive; a heroin addict has a massive disadvantage.
    <br /> An <b>incapacitating</b> drug will render its user unconscious (or blissfully, uselessly drowsy) for about two hours. 
    <br />A <b>stimulating</b> drug will affect its user for the same period of time: the user thinks he is smarter, but in fact is temporarily faced with a +1 skill check difficulty for anything requiring serious thought. 
    <br /> A <b>hallucinogenic</b> drug renders its users useless for work or combat, though they may be active and talkative.
    <br /> <b>Withdrawal</b>. Sometimes, voluntarily or otherwise, a drug user must try to give up his addiction. This may happen if he is imprisoned, if he travels to a place where his drug is not available, or just because he can’t afford it. Withdrawal is a painful process requiring two weeks (14 days) of successful rolls (type of role varies depending on the drug). Each day, the addict rolls. A successful roll puts him one day closer to shaking off the addiction. A failed roll means the 
    addict must (if his drug is available) give in to the craving and take a dose of the drug. He must then start withdrawal all over again if he still wants to try. If the drug is not available, the addict takes 1 hit of damage and may continue to try to withdraw... but that day doesn’t count toward the 14 successful rolls needed to withdraw. Health losses caused by withdrawal cannot be cured (by any “normal” means, that is) until the withdrawal has succeeded or been abandoned.`,
    'Code of Honor': `You take pride in a set of principles which you follow at all times. Codes of honor differ, but all require (by their own standards) “brave" and “honorable” behavior. A Code of Honor may also be called “pride,” “machismo,” or “face.” Under any name, it is the willingness to risk death rather than be thought dishonorable... whatever that means. A Code of Honor is a disadvantage because it will often require dangerous (if not reckless) behavior. Furthermore, an honorable person can often be forced into unfair situations, because his foes know he is honorable. Gain XP whenever this causes issues for you or the party.`,
    'Compulsive Behavior': `You have a habit (usually, but not always, a vice) which you feel compelled to indulge on a daily basis. You waste a good deal of your time indulging your habit. Examples of compulsive behavior include gambling, attraction to another person, arguing (or even fighting). Gain XP any time this puts you or the group at a disadvantage.`,
    'Honesty': `You MUST obey the law, and do your best to get others to do so as well. You are compulsive about it; this is essentially another typeof Code of Honor (see above). In an area with little or no law, you will not “go wild” – you will act as though the laws of your own home were in force. This is a disadvantage, because it will often limit your options! Faced with unreasonable laws you must decide how to handle it. Gain XP whenever doing the honorable thing goes against what the party wishes.`,
    'Gullibility': `There’s one born every minute, and you’re it. A gullible person naturally believes everything they hear; they’ll swallow even the most ridiculous story, if it’s told with conviction. Gain XP when this affects your characters decision making.`,
    'Jealousy': `You have an automatic bad reaction toward anyone who seems smarter, more attractive, or better-off than you! You will resist any plan proposed by a “rival,” and will hate it if someone else is in the limelight. `,
    'Overconfidence': `You feel yourself to be far more powerful, intelligent and/or competent than you really are, and you should behave that way. Any time (in the GM’s opinion) you show an unreasonable degree of caution, it's time to change tactics. Gain XP when this adversely affects you or your party.`,
    'Pacifism': `You are opposed to violence. This opposition can take three forms, each with its own value: 
    <br /> <b>Total nonviolence</b> is just that: you will not lift a hand against another intelligent creature, for any reason. You must do your non-violent best to discourage violent behavior in others, too. You are free to defend yourself against attacks by animals, mosquitoes, etc.
    <br /> <b>Self-defense</b> only means that you will only fight to defend yourself or those in your care, using only as much force as may be necessary (no pre-emptive strikes allowed!). 
    You must do your best to discourage others from starting fights. 
    <br /> <b>Cannot kill</b> means that you may fight freely, and even start a fight, but you may never do anything that seems likely to kill another. This includes abandoning a wounded foe to die “on his own”! You must do your best to keep your companions from killing, too. If you do kill someone 
    (or feel yourself responsible for a death), you immediately suffer a nervous breakdown. Roll 3 dice and be totally morose and useless for that many days (can manifest after danger has passed).`,
    'Phobia': `A “phobia” is a fear of a specific item, creature, or circumstance. Many fears are reasonable, but a phobia is an unreasonable, unreasoning, morbid fear. The more common an object or situation, the greater the XP value of a phobia against it. Fear of darkness is far more troublesome than fear of left-handed plumbers. Phobias may be mild or severe; the severe version is worth twice as much XP.`,
    'Vow': `You have sworn an oath to do (or not to do) something. This disadvantage is especially appropriate for knights, holy men and fanatics. Note that, whatever the oath, you take it seriously. If you didn’t, it would not be a disadvantage. The precise value of a vow is up to the GM, but should be directly related to the inconvenience it causes the character. Some examples: 
    <br /> <b>Trivial Vow:</b> (a quirk). Always wear red; never drink alcohol; treat all the m'ladies with courtesy; pay 10% of your income to your church.
    <br /> <b>Minor Vow:</b> Vow of silence during daylight hours; vegetarianism; chastity. (Yes, for game purposes, this is minor).
    <br /> <b>Major Vow:</b> Use no edged weapons; keep silence at all times; never sleep indoors; own no more than your horse can carry.
    <br /> <b>Great Vow:</b> Never refuse any request for aid; always fight with the wrong hand; hunt a given foe until you destroy him; challenge every knight you meet to combat.`,
}

export default items;