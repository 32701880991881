import React from "react";
import Die from "../components/die";

export function TheBasics(props) {
    return (
        <div>
            <h4 className="heavyWords" style={{ marginTop: '20px' }}>The Basics</h4>
            <p className="noPaddingParagraph">
                The ORE uses ten sided dice (D10's). In most cases you'll roll a number of dice equal to your character's Stat (measuring general talent) plus a Skill (training, experience or intuitive ability). this
                combined number is your pool for the task.
            </p>
            <p className="noPaddingParagraph">
                You want matches. Dice in your pool that turned up the same number. For example imagine you rolled 6D10 and this was the result (<b>Note: D6 are used here just because they look nicer graphically, you'll still be rolling 10 sided dice.</b>):
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                    {[1, 2, 3, 4, 5, 6].map((v) => (
                        <Die key={Math.random()} value={v} />
                    ))}
                </div>
                In this scenario you rolled, <b>1X1, 1X2, 1X3, 1X4, 1X5, and 1X6</b>. Neat! But you got no matches and failed. Now if instead you had rolled:
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                    {[3, 3, 3, 5, 4, 1].map((v) => (
                        <Die key={Math.random()} value={v} />
                    ))}
                </div>
                You would have still rolled <b>1X1, 1X4, and 1X5</b>. But this time you would also have <b>3X3</b> (or three 3's). It's like poker, where you're looking for pairs and treys, and where
                higher numbers are generally better.
            </p>
            <p className="noPaddingParagraph">
                There are two measure of success in this system.
                <ol>
                    <li>How many dice were rolled with the same number (i.e the three 3's above). Which is referred to hereafter as the <b>Width</b>.</li>
                    <li>And the size of the number itself (3). Which will now be reffered to as the <b>Height.</b></li>
                </ol>
                The most common set is a pair. That's a match with <b>Width</b> 2. A pair of twos, a pair of nines, a pair of fives. They all have different <b>Heights</b> but the same <b>Width</b>.
            </p>
            <p className="noPaddingParagraph">
                Higher and Wider are desirable in different ways. A <b>4X1</b> result has poor <b>Height</b> but great <b>Width</b>. A <b>2X10</b> has great <b>Height</b> but poor <b>Width</b>.
            </p>
            <p className="noPaddingParagraph">
                So which is better? Well that depends entirely on the situation.
            </p>
            <p className="noPaddingParagraph">
                <b>Width</b> usually indicates speed and competence. <b>Think of it this way. in order to have 6 of kind show up in a roll (6X1, 6X2, 6X3, etc), you'd need to have a
                    minimum of 6 dice to roll. Since dice represent a mixture of your characters general ability (Stats) plus their specific training, intuition or experience (Skills), having the ability to roll and match more dice, directly equates to how
                    good (or <i>competent</i>) your character is at doing something.
                </b> In a fight, <b>Wider</b> sets happen faster so they would go first and do more damage when they hit.
            </p>
            <p className="noPaddingParagraph">
                <b>Height</b> generally shows luck (though someone with more training, skill, or general ability has a much greater chance to "luckier" than someone without those things)
                or how favorable circumstances were at the moment, and how well the character took advantage of them. Sometimes the more poorly skilled guy (the one with the smaller pool of dice to roll) who can't get a <b>Wide</b> result still gets lucky.
                <b> In a fight, Height indicates where a blow falls.</b> Low numbers strike less vital areas like the arms and legs. High numbers are strikes to the body or head. (More on this in the combat section).
            </p>
            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                To sum up then: You want to get results that are both <b>Wide</b> and <b>High</b>. If you roll five dice, the best possible result is <b>5X10</b>. However, in one throw you may roll more than a single set, i.e:
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                    {[2, 2, 2, 5, 5, 1].map((v) => (
                        <Die key={Math.random()} value={v} />
                    ))}
                </div>
                Here you've got yourself a <b>3X2</b> and a <b>2X5</b>, so you'll have to decide between picking a set that is <b>Wider</b> or <b>Higher</b>. The set you will pick then depends on what you're trying to accomplish.
            </p>
        </div>
    )
}