import React from "react";
import Die from "../components/die";

export function Cover(props) {
    return (
        <div>
            <p className="noPaddingParagraph">
                You cannot take cover from someone if you’re already engaged in hand-to-hand combat
                with him. By that point he’s too close. However, you can take cover before someone gets
                that close, or take cover from long-range attacks.
            </p>
            <p className="noPaddingParagraph">
                When you have cover, certain hit locations are immune to attack. If you’re standing behind
                a low wall with both your legs behind it, any hits that would have struck locations <b>1-2</b> simply
                miss, instead. If you’re firing at someone from around a corner, you might have protection
                for your legs <b>(1-2)</b>, and part of your torso <b>(7-8)</b>. If you’re just peeking around the corner to
                see if he’s following, everything but your head (hit location <b>10</b>) is protected. If you use a
                mirror to look around the corner, perhaps only location <b>3</b> (left hand) is vulnerable.
            </p>
            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                Cover, then, depends on how good the available shelter is, and how well you rolled trying to
                get to it. To model this, the GM decides how many locations you can conceal in a hiding place
                (from 1-10). If you have plenty of time to get situated, you can describe which limbs you want
                to protect. If you’re rushing for cover in the middle of a fight, roll <b>Coordination+Dodge</b>.
                The <b>Width</b> of the roll indicates how many locations you can conceal. You get to pick which
                ones you defend, though the GM may veto certain combinations. Once you’re in cover,
                you can get its best protection by spending a round doing nothing but arranging yourself.
                This happens during the declare phase, so you needn’t even roll. If you want to improve
                your coverage while doing something else, roll <b>Coordination+Dodge</b> as part of a multiple
                action. If you retain a <b>Dodge</b> set, when it goes off you can protect your bits as you see fit.
            </p>
            <p className="noPaddingParagraph">
                <b>Example:</b>
            </p>
            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: '40px' }}>
                <div style={{ maxWidth: '500px' }}>
                    <p className="noPaddingParagraph">
                        A squad of archers blows their cover early.  Tud (their target) has a chance to dive for cover.
                    </p>
                    <p className="noPaddingParagraph">
                        “Is there anything that might protect me?” Tud asks no one in particular.  Then he spies
                        a small boulder—maybe enough to protect three hit locations—he decides to dive for it.  
                        He rolls his <b>5d Coordination+Dodge</b> and gets a <b>2x9</b> and a <b>2x1</b>, enough
                        to cover two hit locations.  The archers fire, getting a <b>3x8</b>, a <b>2x8</b> and a <b>2x3</b>.
                    </p>
                    <p className="noPaddingParagraph">
                        Even though Tud is trying to protect himself, the <b>3x8</b> hits.  Because its <b>Width</b> is greater, it
                        happens before Tud has a chance to start his maneuver.  Tud gets hit and loses a die from a
                        set, but he luckily has that extra <b>2x1</b> set and can take the punishment there.
                    </p>
                    <p className="noPaddingParagraph">
                        His remaining <b>2x9</b> action resolves next and he dives behind the rock, hoping to protect <b>10 </b>
                        and <b>8</b>.  The GM asks just how he’s protecting his head and body while exposing both arms,
                        and the player describes Tud hugging the stone.
                    </p>
                    <p className="noPaddingParagraph">
                        The <b>2x8</b> attack resolves next, and because Tud chose to protect location <b>8</b>, the arrow
                        bounces off the rock.  The <b>2x3</b> happens after he jumps, but his arm is hanging out and thus gets hit.
                    </p>
                </div>
            </div>
        </div>
    )
}