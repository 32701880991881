import React from "react";
import styles from '../../css/colors.module.css';
import Die from "../components/die";

export class Process extends React.Component {
  render() {
    return (
      <div className="col-sm-8">
        <form>
          <label>Welcome! Let’s walk through creating a character. </label>
          <p>
            Below is a brief summary of what to expect as you progress through
            the character creator, as well as an intro into what exactly the One Roll Engine (ORE) is.
          </p>
          <div className="diceBox" style={{ marginBottom: '30px' }}>
                {[2, 1, 5, 6, 4, 3, 2].map((v) => (
                  <Die key={Math.random()} value={v} />
                ))}
            </div>
          <ol>
            <li>
              <span className={styles.red}>Stats</span>, <span className={styles.green}>Skills</span>, and <span className={styles.cyan}>Advantages </span> are the core of the game. They are gained via a point buy system. During character creation, you can spend these points in any way you choose, creating a character anywhere from pretty good at most things to a highly-trained master of one. Afterwards, points are gained in the form of EXP from Combat, Roleplay, and <span className={styles.purple2}>Problems</span>.
              These attributes will numerically influence <i> how</i> your character interacts with the world but not <i> who</i> your character is or <i> why</i> they are the way that they are. That's why the point buy system is last in the Character Creator. Start with who you want to be, and then worry about the how.
              <ul>
                <li>
                  <span className={styles.red}>Stats </span> are: Body, Coordination, Sense, Knowledge, Command, and Charm. They measure your general talent in a field.
                </li>
                <li>
                  <span className={styles.green}>Skills </span> are specific things you're good at. the Character Creator
                  has a number of example <span className={styles.green}>Skills </span> to pick from, but feel free to have expertise in anything you wish. From hobbyist cheese maker to unopposed master of Hambone, you have the freedom to be gifted in any passion no matter how obscure. And if you can
                  <i> convincingly</i> relate that to another skill check your character is attempting, maybe the DM would be cool. Who knows?
                </li>
                <li>
                  <span className={styles.cyan}>Advantages </span> are just that. They're cheat codes you put in at the start menu of life. Want to be Born <span style={{ fontFamily: 'Brush Script MT, Brush Script Std, cursive', fontSize: '2rem' }}>Rich</span>? Beautiful enough to start an armed conflict? Or maybe you'd like
                  a pet monkey that steals things for you or could cause a distraction by dancing in his fancy little fez and vest. <span className={styles.cyan}>Advantages </span> give you an edge in an otherwise unforgiving world. You don't need to take them, but they're there.
                </li>
              </ul>
            </li>
            <li>
              Great. So who are you anyway? Such an easy question that it can be answered with only 5 smaller, even easier, questions.
            </li>
            <ol>
              <li><b>Where are you from?</b> A Nomadic Tribe travelling uncharted oceans with only the stars to guide them? A primitive clan of close family members living deep in the Jungle? Or maybe an unamed alley in the slums of a dying city? </li>
              <ul><li>Your <span className={styles.orange}>Origin </span> will define the style with which you fight. Will lay groundwork for what professional and standard skills you might have gained up to this point. And will likely be where people will start when they tell of the story of <b>you</b>. </li></ul>
              <li><b>Who are <i>YOU</i>?</b> What is your race, your name, and how many times have your spun your way around the sun? What month were you born, and what god grants you grace? Who are your family and friends? These are backstory elements true, but the month you were born in this world plays a role in your fate. With gods smiling down on those who were born in their named month. </li>
              <ul><li>Your <span className={styles.purple}>Birth Month </span> will grant you the boon of a god. </li></ul>
              <li><b>How do you make money?</b> There's more to life than paying taxes and dying. You must also suffer. A lot. Job's are good for that. </li>
              <ul><li>Your <span className={styles.blue}>Occupation </span> will determine what weapons you would have trained with and what trade goods you might be carrying. Also what skills you might have had, and, if you can <i>convincingly</i> describe why your <span className={styles.blue}>Occupation </span> would warrant an extra die (1D) on a skill check, the DM might just give it to you.</li></ul>
              <li><b>What makes your story unique?</b> Were you exiled? Robbed? Raised Wild? They don't tell stories about the people who were promptly in bed by 9. What's your hook? </li>
              <ul><li>Your <span className={styles.pink}>Experiences </span> define you. And <i>unusual </i><span className={styles.pink}>Experiences </span> do that and then some. Pick one out of the list or make a new one up, it's important.</li></ul>
              <li><b>What do people hate about you?</b> Or what do you hate about yourself? Everyone has problems. They're just as much a part of us as our <span className={styles.cyan}>Advantages</span>, and to deny them would be to deny ourselves.</li>
              <ul><li><span className={styles.purple2}>Problems </span> harm or invonvenience your character. They don't cost points, they earn you experience points (EXP). Any time your <span className={styles.purple2}>Problem(s) </span>affect play, you get an extra XP at the end of the session. You never get more than 1 EXP per session from <span className={styles.purple2}>Problems </span> no matter how many you take. Sometimes you decide when a <span className={styles.purple2}>Problem </span> comes into play, sometimes the GM decides, and sometimes it's up to the dice. </li></ul>

            </ol>
            <li>
              So now you've got your character fleshed out, here is a quick intro into the One Roll Engine. AKA How you'll be playing the game outside of Roleplay (i.e making skill checks and fighting in combat).
            </li>
            <ul>
              <li>
                The ORE uses ten sided dice (d10s).
              </li>
              <li>
                In most cases, you roll a number of dice equal to your character’s <span className={styles.red}>Stat</span> (measuring general talent) plus a <span className={styles.green}>Skills </span> (training, experience or intuitive ability). This combined number is your pool for that task.
              </li>
              <li>
                If, say, your character is trying to convince a merchant to give you a big break on a purchase, you'd roll your “haggle pool.” This consists of your <span className={styles.green}>Haggle Skill </span> (for mercantile bullying) and your <span className={styles.red}>Command stat</span> (showing how impressive and overbearing you can be).
              </li>
              <li>
                If your <span className={styles.green}>Haggle Skill </span> is 4, and your <span className={styles.red}>Command stat</span> is 2, your pool for haggling is <span style={{ color: 'purple', fontWeight: 'bold' }}>6 dice</span>.
              </li>
              <li>
                You'd then roll those 6D10 all at once, and (without going into to much depth here) the DM (or dice) will tell you if you've succeeded or failed.
              </li>
              <li>
                (For a more in depth look at the ORE, click <a href="#">here</a>) <b>Important: Don't click out of the Character Creator until you've clicked Save. Any unsaved changes you make will be gone when you get back! </b>
              </li>
            </ul>
            <li>And that's that. Soon you'll have your character all backstoried up and suitably fleshed out. All that's left is to review all your answers on the review page and then click save.</li>
          </ol>
        </form>
      </div>
    );
  }
}
