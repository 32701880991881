/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './fades.module.css';

export function AddNotes(props) {
    const { formData, setFormData } = props;
    const [addedNotes, setAddedNotes] = useState([])

    const [description, setDescription] = useState('');

    useEffect(() => {
        let notesCopy = formData.notes;
        let tempArr = [];

        notesCopy.forEach(element =>
            tempArr.push(<OneLine key={element.key} key2={element.key} date={element.date} description={element.description} formData={formData} setFormData={setFormData}></OneLine>)
        );

        setAddedNotes(tempArr);
    }, [formData]);

    const handleSubmit = (event) => {
            event.stopPropagation();
            const oldArr = formData.notes;
            oldArr.push({
                key: Math.floor(Math.random() * 1000),
                date: newDate(),
                description
            });
            setFormData({ ...formData, notes: oldArr });
            setDescription('');
    };

    const changeDescription = (event) => { setDescription(event.target.value); }

    return (
        <div>
            <form onKeyDown="return event.key != 'Enter';">
                <div className="row">
                    <div className="columns">
                        <textarea
                            className="u-full-width"
                            placeholder="Notes..."
                            style={{ minHeight: '100px' }}
                            value={description}
                            onChange={changeDescription}
                        />
                    </div>
                </div></form >
            <div className="row">
                <div className="columns" style={{ display: 'grid', justifyContent: 'right'}}>
                    <input type="button" value="Add" onClick={handleSubmit} />
                </div>
            </div>
            <div className="row" style={{ width: '100%', maxWidth: '90vw', overflowX: 'scroll', scrollbarWidth: 'thin', marginBottom: '10px' }}>
                <div className="columns" style={{ marginBottom: "10px" }}>
                    <table style={{ width: '100%' }} >
                        <tr>
                            <th>Date</th>
                            <th>Note</th>
                            <th>Remove</th>
                        </tr>
                        {addedNotes ? addedNotes : <></>}
                    </table>
                </div>
            </div>
        </div>
    )
}

export function OneLine(props) {
    const { key2, date, description, formData, setFormData } = props;

    const handleSubmit = (event) => {
        event.stopPropagation();
        let oldArr = formData.notes;
        for (let i = 0; i < oldArr.length; i++) {
            if (oldArr[i].key == event.target.id) {
                oldArr.splice(i, 1);
                break;
            }
        }
        setFormData({ ...formData, notes: oldArr });
    };

    return (
        <tr>
            <td>{date}</td>
            <td>{description}</td>
            <td>
                <input type="button" value='Remove' id={key2} onClick={handleSubmit} />
            </td>
        </tr>
    )
}

function newDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}