import React from "react";
import Die from "../components/die";

export function MultipleActions(props) {
    return (
        <div>
            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                When a character attempts to do two or more things in the time usually
                required for one, that’s a multiple action. Trying to seduce someone while racing her on
                horseback is a multiple action involving the Fascinate and Riding pools. A more common
                example—trying to hit two people in quick succession—is a double use of the Fight pool.
                When trying to do two things at once, use the lower pool, take one die out of it, and hope
                for two sets. If you get two sets, you can apply one set to each action.
            </p>
            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <div style={{ maxWidth: '500px' }}>
                    <p style={{ marginBottom: '4px' }}>
                        "First I'm gonna break out of these cuffs, then I'm gonna break your nose."
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                        {[2, 6, 6, 2].map((v) => (
                            <Die key={Math.random()} value={v} />
                        ))}
                    </div>
                    <p className="noPaddingParagraph">
                        <b>
                            Mouray's Athletics pool is 5d while his Fight pool is 8d. When he wants to escape a set of
                            bonds and make a surpise attack in the same round he rolls the smaller of his two pools minus one die. If he had
                            only gotten one set he would only have been able to make the first action. If he had wanted to take another action or two after breaking
                            his captors nose he would have to remove the equivalent amount of dice before rolling. In this case he succeeds at both his intended actions.
                        </b>
                    </p>
                </div>
            </div>
        </div>
    )
}