import React from "react";
import { SelectOccupation } from "../components/Occupation";

export class StepThree extends React.Component {
  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="columns">
            <h4 className="reallyHeavyWords" style={{ marginBottom: "20px" }}>Occupations</h4>
            <small>
              Your profession defines your starting weapon and inventory. You also will also receive a bonus die on any attempted roll you make that is related to your profession if you can give a good explanation as to why. (More on bonus dice later).
            </small>
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px'}}>
            <label className="heavyWords">Choose Your Occupation</label>
            <SelectOccupation setFormData={this.props.setFormData} formData={this.props.formData} />
        </div>
        <div className="row" style={{ marginTop: '20px'}}>
          <div className="eight columns">
            <label className="heavyWords">Additional Info</label>
            <textarea
              onKeyDown="return event.key != 'Enter';"
              className="u-full-width"
              placeholder="If you'd like to make any additional notes about your chosen profession do so here."
              value={this.props.formData.occupationAdditionalInfo}
              onChange={(event) =>
                this.props.setFormData({ ...this.props.formData, occupationAdditionalInfo: event.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
