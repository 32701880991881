import React, { useState } from "react";
import MultiStep from "react-multistep";
import { StepOne } from "./assets/js/multistep/step-one";
import { StepTwo } from "./assets/js/multistep/step-two";
import { StepThree } from "./assets/js/multistep/step-three";
import { StepFour } from "./assets/js/multistep/step-four";
import { StepFive } from "./assets/js/multistep/step-five";
import { StatsSkillsAdvantages } from "./assets/js/multistep/stats-skills-advantages";
import { Relationships } from "./assets/js/multistep/relationships";
import { Review } from "./assets/js/multistep/review";
import { Process } from "./assets/js/multistep/process";

import "./assets/css/prog-track.css";
import "./assets/css/styles.css";

import stats from './assets/js/data/stats';
import skills from './assets/js/data/skills';
import advantages from './assets/js/data/advantages';
import customSkillsTemplates from './assets/js/data/customSkills';
import { FontBackground } from "./assets/js/components/FontBackground";
import { Navigation } from "./Navigation";

export default function CharacterCreator() {
    const [formData, setFormData] = useState({
        alive: true,
        socialClass: "",
        home: "",
        homeAdditionalInfo: "",
        name: "",
        birthMonth: "",
        age: "",
        race: "",
        userAdditionalInfo: "",
        relations: [],
        occupation: "",
        occupationAdditionalInfo: "",
        experience: "",
        experienceAdditionalInfo: "",
        problem: "",
        problemAdditionalInfo: "",
        totalPoints: 85,
        availablePoints: 85,
        wealth: 0,
        treasure: 0,
        inventory: [],
        createdOn: newDate(),
        notes: [],
        currentHome: [{ date: newDate(), description: `You're part of a baseball game that never ends my friend. Why? Cause you ain't got no home. If you find yourself looking for a place to rest your weary head, first lookup home prices in the 'How to Play' section then talk with me about setting up a residence.` }],
        armor: { head: 0, leftArm: 0, rightArm: 0, torso: 0, leftLeg: 0, rightLeg: 0 },
        damage: { head: 0, leftArm: 0, rightArm: 0, torso: 0, leftLeg: 0, rightLeg: 0 },
        stats: stats,
        skills: skills,
        advantages: advantages,
        customSkillsTemplates: customSkillsTemplates,
    });

    const steps = [
        { name: "Process", component: <Process formData={formData} setFormData={setFormData} /> },
        { name: "Origin", component: <StepOne formData={formData} setFormData={setFormData} /> },
        { name: "Self", component: <StepTwo formData={formData} setFormData={setFormData} /> },
        { name: "Relationships", component: <Relationships formData={formData} setFormData={setFormData} /> },
        { name: "Occupation", component: <StepThree formData={formData} setFormData={setFormData} /> },
        { name: "Experiences", component: <StepFour formData={formData} setFormData={setFormData} /> },
        { name: "Problems", component: <StepFive formData={formData} setFormData={setFormData} /> },
        { name: "Stats, Skills, and Advantages", component: <StatsSkillsAdvantages formData={formData} setFormData={setFormData} /> },
        { name: "Review", component: <Review formData={formData} setFormData={setFormData} /> }
    ];

    return (
        <div>
            <Navigation />
            <div style={{ minHeight: '70vh' }}>
                <FontBackground />
                <MultiStep showNavigation={true} steps={steps} />
            </div>
        </div>
    );
}

function newDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}