import React from "react";
import { SelectHome, SocialClass } from "../components/HomeAndSocial";

export class StepOne extends React.Component {
  render() {
    return (
      <div>
        <div className="row">
          <label className="heavyWords">Social Class</label>
          <small>What social class you're from could influence what you know, how you see the world, and how others treat you.</small>
          <SocialClass setFormData={this.props.setFormData} formData={this.props.formData} />
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <label className="heavyWords">Home</label>
          <small>Home is where the heart is. Or was. Unless you didn't much like your home. Or it didn't much like you... Your home will determine where you start from. Though it provides
            no specific boons, it could play a very large part in the overall game. Pick somewhere memorable. </small>
          <SelectHome setFormData={this.props.setFormData} formData={this.props.formData} />
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="eight columns">
            <label className="heavyWords">Additional Info</label>
            <textarea
              onKeyDown="return event.key != 'Enter';"
              className="u-full-width"
              placeholder="A space for any additional notes you'd like to make relating to your home or social class..."
              value={this.props.formData.homeAdditionalInfo}
              onChange={(event) =>
                this.props.setFormData({ ...this.props.formData, homeAdditionalInfo: event.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
