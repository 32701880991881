import React, { useState, useEffect } from 'react';
import './fades.module.css';
import styles from '../../css/colors.module.css';

import { MenuItem, Select } from '@material-ui/core';

import socialClasses from '../data/origins.js';
import homes from '../data/homes.js';

export function SocialClass(props) {
    const { formData, setFormData } = props;
    const [infoText, setInfoText] = useState('')
    const [socialClass, setSocialClass] = useState(formData.socialClass)
    const [initLoad, setInitLoad] = useState(true);

    useEffect(() => {
        setSocialClass(formData.socialClass);
        if (initLoad && socialClass != '') {
            setInfoText(socialClasses[socialClass]);
        }
        setInitLoad(false);
    }, [formData.socialClass]);


    const handleChange = (event) => {
        setFormData({ ...formData, socialClass: event.target.value });
        setInfoText(socialClasses[event.target.value])
    };

    return (
        <div>
            <Select
                labelId="social-class-select"
                id="social-class-select-helper"
                value={socialClass}
                onChange={handleChange}
                label='age'
            >
                <MenuItem value={'Primitive'}>Primitive</MenuItem>
                <MenuItem value={'Nomadic'}>Nomadic</MenuItem>
                <MenuItem value={'Barbaric'}>Barbaric</MenuItem>
                <MenuItem value={'Civilized'}>Civilized</MenuItem>
            </Select>
            {infoText.name ?
                <div className="fancyBorder">
                    <p><b>Description: </b>{infoText.description}</p>
                    <p><b>Standard <span className={styles.green}>Skills </span>: </b>{infoText.standardSkills} <br />
                        <b>Example Combat Styles: </b>{infoText.exampleCombatStyles} <br />
                        <b>Professional <span className={styles.green}>Skills </span>: </b>{infoText.ProfessionalSkills} <br />
                        <b>Cultural Passions: </b>{infoText.culturalPassions}</p>
                </div>
                :
                <></>
            }
        </div>
    )
}


export function SelectHome(props) {
    const { formData, setFormData } = props;
    const [infoText, setInfoText] = useState('');
    const [home, setHome] = useState(formData.home);
    const [options, setOptions] = useState([]);
    const [initLoad, setInitLoad] = useState(true);

    useEffect(() => {
        if (homes[formData.socialClass]) {
            let tempArr = [];
            let homeChoices = Object.keys(homes[formData.socialClass]);
            homeChoices.forEach(element =>
                tempArr.push(<MenuItem key={Math.random()} value={element}>{element}</MenuItem>)
            );
            setOptions(tempArr);
            if (!initLoad) {
                setFormData({ ...formData, home: '' });
                setInfoText('');
            } else if (initLoad && home != '') {
                setInfoText(homes[formData.socialClass][home]);
            }
            setInitLoad(false);
        }
    }, [formData.socialClass]);

    useEffect(() => {
        setHome(formData.home);
    }, [formData.home]);

    const handleChange = (event) => {
        if (event.target.value) {
            setFormData({ ...formData, home: event.target.value });
            setInfoText(homes[formData.socialClass][event.target.value]);
        }
    };

    return (
        <div>
            <Select value={home} onChange={handleChange} >
                {options ? options : <MenuItem></MenuItem>}
            </Select>
            {
                infoText.name && infoText ?
                    <div className="fancyBorder">
                        <div>
                            {infoText.location ? <div><b>Location:</b> {infoText.location}<br /> </div> : ''}
                            {infoText.government ? <div><b>Government:</b> {infoText.government}<br /> </div> : ''}
                            {infoText.economy ? <div><b>Economy:</b> {infoText.economy}<br /> </div> : ''}
                            {infoText.jobs ? <div><b>Example Jobs:</b> {infoText.jobs}<br /> </div> : ''}
                        </div>
                        {infoText.description ?
                            <p><b>Description:</b> {infoText.description}<br /></p> : ''
                        }
                        {infoText.baseDescription ?
                            <div><b>Description: </b><p dangerouslySetInnerHTML={{ __html: infoText.baseDescription || '' }}></p></div> : ''
                        }
                        <p>
                            {infoText.notableBusinesses ? <div><b>Notable Businesses:</b> {infoText.notableBusinesses}<br /> </div> : ''}
                        </p>
                        {infoText.attractions ?
                            <div><b>Attractions: </b><p dangerouslySetInnerHTML={{ __html: infoText.attractions || '' }}></p></div> : ''
                        }
                    </div> : ''
            }
        </div>
    )
}