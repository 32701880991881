import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';

import useWindowDimensions from '../components/UseWindow'

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function Gear(props) {
    const { height, width } = useWindowDimensions();

    return (
        <div style={{ width: '100%' }}>
            <Accordion>
                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Armor</AccordionSummary>
                <AccordionDetails style={{ display: 'grid' }}>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        Different types of armor cover different hit locations. A helmet covers only the head ,
                        a breastplate only protects the torso, while a full suit of armor covers everything. The
                        armor descriptions below list the protected locations.
                    </p>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        Because some armor is thicker or better made, different armor types have a numerical
                        rating, the Armor Rating (or AR for short) that shows just how much damage they stop.
                    </p>
                    <p style={{ width: '100%' }} style={{ marginBottom: '20px', textIndent: '30px' }}>
                        <b>For every point of AR, the armor stops one point of Shock damage and one point of
                            Killing damage.</b>
                    </p>
                    <table style={{ width: '100%', lineHeight: `${width > 400 ? '1' : 'auto'}` }} >
                        <tr>
                            <th>Armor Type</th>
                            <th>Locations Covered</th>
                            <th>Armor Rating</th>
                            <th>Weight</th>
                        </tr>
                        <tr>
                            <td>Wooden Helmet</td>
                            <td>10</td>
                            <td>1</td>
                            <td>Light</td>
                        </tr>
                        <tr>
                            <td>Iron Cap</td>
                            <td>10</td>
                            <td>2</td>
                            <td>Light</td>
                        </tr>
                        <tr>
                            <td>Reinforced Full Helmet</td>
                            <td>10</td>
                            <td>3</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cuirass (Boiled Leather)</td>
                            <td>7-9</td>
                            <td>1</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td>Chainmail Shirt</td>
                            <td>7-9</td>
                            <td>2</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td>Breastplate</td>
                            <td>7-9</td>
                            <td>3</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Leather Gloves</td>
                            <td>4 and 6</td>
                            <td>1</td>
                            <td>Light</td>
                        </tr>
                        <tr>
                            <td>Chainmail Gloves</td>
                            <td>4 and 6</td>
                            <td>2</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td>Steel Gauntlets</td>
                            <td>4 and 6</td>
                            <td>3</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cavalry Boots</td>
                            <td>1-2</td>
                            <td>1</td>
                            <td>Light</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td>Full Leather Set</td>
                            <td>1-9</td>
                            <td>2</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td>Full Chain Set</td>
                            <td>1-9</td>
                            <td>3</td>
                            <td>Heavy (Body 6 requirement, no stealth or swim)</td>
                        </tr>
                        <tr>
                            <td>Full Plate Set</td>
                            <td>1-9</td>
                            <td>4</td>
                            <td>Very Heavy (Body 8 requirement, no stealth, swim, or climb)</td>
                        </tr>
                    </table>
                    <div>
                        <p>
                            <b>Example:</b>
                        </p>
                        <div className="fancyBorder" style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', textAlign: 'center' }} >
                            <div style={{ maxWidth: '500px' }}>
                                <p style={{ width: '100%' }} className="noPaddingParagraph">

                                    Morrec is wearing a wooden helmet with <b>1 AR</b>. Thorvald swings at him with a battleaxe and
                                    gets a <b>2x10</b> success.  A battleaxe does damage equal to the <b>Width</b> of the roll in <b>Killing and
                                        Shock</b>—so if Morrec’s head was uncovered, he’d be unconscious with <b>two Killing</b> and <b>two
                                            Shock</b> to his head.  But the helmet stops <b>one Killing</b> and <b>one Shock</b>, so he’s still conscious.
                                </p>
                            </div>
                        </div>
                        <div className="fancyBorder" style={{ marginBottom: '40px', display: 'flex', justifyContent: 'center', textAlign: 'center' }} >
                            <div style={{ maxWidth: '500px' }}>
                                <p style={{ width: '100%' }} className="noPaddingParagraph">
                                    Lio has on a breastplate that gives him <b>3 AR</b> to his chest (locations 7-9).  He gets chopped
                                    in the arm by a <b>5x3</b> hit.  To make matters worse, the guy is using a greatsword, so it does
                                    <b> Width+1 Killing</b> and <b>Width Shock</b>.  Lio only has five wound boxes in his arm, so the first
                                    <b> five Killing</b> go in there, with <b>five Shock</b> and <b>one Killing</b> point spilling over into his torso.  His
                                    armor does not reduce that damage.
                                </p>
                                <p style={{ width: '100%' }} className="noPaddingParagraph">
                                    Next round he takes a blow to the chest from a mace. It’s a <b>4x7</b> hit, so it does <b>5 Shock, 1
                                    Killing</b>—just like the spillover damage. But because it hit location 7, which is armored, he
                                    only takes <b>2 Shock</b>. If that blow had been a <b>4x3</b>, hitting his mangled arm, the full <b>5 Shock
                                    and 1 Killing</b> would have bled through into his chest.
                                </p>
                            </div>
                        </div>
                        <p style={{ width: '100%' }} className="noPaddingParagraph">
                            Armor is well suited for keeping you unharmed in combat. It’s dismal for many other
                            tasks. To figure out if armor is going to impede a given task, look at the chart and see if
                            the armor is heavy, medium or light. This is based not only on physical weight, but on
                            which locations it protects. Armor that covers your arms and/or legs is going to get in
                            your way a lot more than something that just covers your head or chest.
                        </p>
                        <p style={{ width: '100%' }} className="noPaddingParagraph">
                            Heavy armor protects the arms and the legs and provides at least AR2. Light armor provides
                            AR2 or less to one or two locations. Medium armor is anything that isn’t light or heavy.
                        </p>
                        <p style={{ width: '100%' }} className="noPaddingParagraph">
                            <b>Light armor doesn’t affect Skill use at all.</b>
                        </p>
                        <p style={{ width: '100%' }} className="noPaddingParagraph">
                            <b>Medium armor imposes Difficulty 3 on all Stealth, Climb, Run, Endurance and Athletics
                            rolls.</b> If you’re attempting to swim in medium armor, you lose 2d from your pool.
                        </p>
                        <p style={{ width: '100%' }} className="noPaddingParagraph">
                            <b>Heavy armor imposes a -2d penalty on all Climb and Run rolls, along with Difficulty 4 on
                            Endurance and Athletics rolls. Trying to use Stealth or go swimming in heavy armor is
                            pointless: There’s no chance of success.</b>
                        </p>
                        <p style={{ width: '100%' }} className="noPaddingParagraph">
                            If you have a combination of armors you always use the highest weight when evaluating
                            penalties. For example, someone wearing cavalry boots (light armor) and a chain shirt
                            (medium armor) is impaired as if wearing medium armor.
                        </p>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Shields</AccordionSummary>
                <AccordionDetails style={{ display: 'grid' }}>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        When you have a shield, at the beginning of each combat round you can declare that you’re
                        protecting a specific body part with it. If you don’t declare a protected part, it protects the
                        arm with which you’re holding it. The protection begins before any actions resolve.
                    </p>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        Small shields can protect one hit location at a time. Large shields always protect one
                        location on the arm, and can either protect a different location (if you wish) or protect
                        both arm sites (if you don’t say what else you’re covering).
                    </p>
                    <table style={{ width: '100%', lineHeight: '1' }} >
                        <tr>
                            <th>Shield Type</th>
                            <th>Locations Covered</th>
                            <th>Armor Rating</th>
                        </tr>
                        <tr>
                            <td>Small Wooden Shield</td>
                            <td>4 or 6 or choice</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>Large Wooden Shield</td>
                            <td>4 or 6 and choice</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>Small Metal Shield</td>
                            <td>4 or 6 or choice</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>Large Metal Shield</td>
                            <td>4 or 6 and choice</td>
                            <td>3</td>
                        </tr>
                    </table>
                    <div>
                        <p style={{ width: '100%' }} className="noPaddingParagraph">
                            If you’re actively using a shield to defend yourself, it’s considered medium armor (as
                            discussed above under “Armor Restrictions”). If you’re just carrying it, it doesn’t impose
                            penalties, but neither does it offer protection.
                        </p>

                        <p>
                            <b>Example:</b>
                        </p>
                        <div className="fancyBorder" style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', textAlign: 'center' }} >
                            <div style={{ maxWidth: '500px' }}>
                                <p style={{ width: '100%' }} className="noPaddingParagraph">
                                    Blina has heard her foe always strikes for the head.  She has a large shield on her left arm.
                                    This shield always protects location 4, and it defaults to location 5 if she doesn’t use it for
                                    some other location.  As it happens, she says she wants to protect her head, so for one
                                    round it protects locations 4 and 10.
                                </p>
                            </div>
                        </div>
                        <div className="fancyBorder" style={{ marginBottom: '40px', display: 'flex', justifyContent: 'center', textAlign: 'center' }} >
                            <div style={{ maxWidth: '500px' }}>
                                <p style={{ width: '100%' }} className="noPaddingParagraph">
                                    Li has a small shield on his right arm and has already taken damage to his chest.  He picks
                                    hit location 7 to defend—a torso location.  If he hadn’t done that, the shield would have protected location 6, his arm.  That round, his attacker rolls a 2x8.  This is a torso hit, but
                                    it’s not the number that the shield is covering.  The blow gets past the shield.
                                </p>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Martial Weapons</AccordionSummary>
                <AccordionDetails style={{ display: 'grid' }}>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        Whether they work by bludgeoning, cutting, crushing or stabbing, the mechanical effect
                        of weapons are the same: They produce greater or lesser amounts of damage. Some
                        weapons aid in certain maneuvers, require both hands to use and so forth—but generally
                        speaking, broad classes of weapons work the same way. Characters may prefer the saber or
                        the broadsword over the longsword, but they all do the same damage.
                    </p>
                    <table style={{ width: '100%', lineHeight: '1' }} >
                        <tr>
                            <th>Hand Weapon</th>
                            <th>Damage</th>
                            <th>Special Qualities</th>
                        </tr>
                        <tr>
                            <td>Bite (Human)</td>
                            <td>1 Shock</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Punch or Kick</td>
                            <td>Width Shock</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Whip</td>
                            <td>Width Shock</td>
                            <td>Can use to trip</td>
                        </tr>
                        <tr>
                            <td>Short Club</td>
                            <td>Width +1 Shock</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Bottle</td>
                            <td>Width +1 Shock</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Guantleted Punch</td>
                            <td>Width +1 Shock</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Small Knife</td>
                            <td>Width Shock, 1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Dagger</td>
                            <td>Width Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Hand Axe</td>
                            <td>Width Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Club</td>
                            <td>Width +2 Shock</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Mace</td>
                            <td>Width +1 Shock, 1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Flail</td>
                            <td>Width +2 Shock</td>
                            <td>Can use to trip</td>
                        </tr>
                        <tr>
                            <td>Staff</td>
                            <td>Width +2 Shock</td>
                            <td>Requires two hands</td>
                        </tr>
                        <tr>
                            <td>Shortsword</td>
                            <td>Width Killing, 1 Shock</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Longsword</td>
                            <td>Width +1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Saber</td>
                            <td>Width +1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cutlass</td>
                            <td>Width +1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Broadsword</td>
                            <td>Width +1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Scimitar</td>
                            <td>Width +1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Short Spear</td>
                            <td>Width Killing, 1 Shock</td>
                            <td>Requires two hands</td>
                        </tr>
                        <tr>
                            <td>Longaxe</td>
                            <td>Width +1 Killing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Long Spear</td>
                            <td>Width +1 Killing, 1 Shock</td>
                            <td>Requires two hands</td>
                        </tr>
                        <tr>
                            <td>Tournament Lance</td>
                            <td>Width +3 Shock</td>
                            <td>(Blunted) Can only be used when mounted</td>
                        </tr>
                        <tr>
                            <td>War Lance</td>
                            <td>Width Killing, Width +3 Shock</td>
                            <td>Can only be used when mounted</td>
                        </tr>
                        <tr>
                            <td>Big Club</td>
                            <td>Width +3 Shock</td>
                            <td>Requires two hands</td>
                        </tr>
                        <tr>
                            <td>Polearm</td>
                            <td>Width +1 Killing, Width Shock</td>
                            <td>Requires two hands</td>
                        </tr>
                        <tr>
                            <td>Battleaxe</td>
                            <td>Width +1 Killing, Width Shock</td>
                            <td>Requires two hands</td>
                        </tr>
                        <tr>
                            <td>Greatsword</td>
                            <td>Width +1 Killing, Width Shock</td>
                            <td>Requires two hands</td>
                        </tr>
                    </table>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        <b>Massive Weapons: </b>If you choose to use a massive weapon—one that’s even bigger and heavier than the typical specimen—
                        it does an extra point of Killing damage on impact.  However, you can’t use a Massive weapon unless
                        your Body is 4 or more.  Only big clubs, battleaxes, polearms and greatswords can be made massive.
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary className="subTan" expandIcon={<ExpandMore />}>Ranged Weapons</AccordionSummary>
                <AccordionDetails style={{ display: 'grid' }}>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        Weapons that do damage from a distance work the same as melee weapons, except they have
                        range limits. The more powerful weapons can shoot farther, as determined by the Range
                        quality. Essentially, a weapon’s range limits the greatest distance to which it can operate.
                    </p>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        If the range is short, you can only really hit someone who’s within twenty paces (or about
                        sixty feet). The missile can probably go farther than that, but past that point it’s either
                        ineffective or inaccurate. Medium range is as far as you can see someone and identify her
                        face—call it eighty paces or 250 feet. Long range is as far as you can see someone and see
                        individual limbs—500 feet, more or less.
                    </p>
                    <table style={{ width: '100%', lineHeight: '1' }} >
                        <tr>
                            <th>Missile Weapon</th>
                            <th>Damage</th>
                            <th>Range</th>
                            <th>Special Qualities</th>
                        </tr>
                        <tr>
                            <td>Thrown Rock</td>
                            <td>Width Shock</td>
                            <td>Short</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Thrown Dagger/ Shortsword</td>
                            <td>Width Shock</td>
                            <td>Short</td>
                            <td>+1 Killing</td>
                        </tr>
                        <tr>
                            <td>Thrown</td>
                            <td>Width Shock</td>
                            <td>Short</td>
                            <td>Hand Weapon</td>
                        </tr>
                        <tr>
                            <td>Sling</td>
                            <td>Width +1 Shock</td>
                            <td>Medium</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Shortbow</td>
                            <td>Width Shock</td>
                            <td>Medium</td>
                            <td>1 Killing</td>
                        </tr>
                        <tr>
                            <td>Light Crossbow</td>
                            <td>Width Killing</td>
                            <td>Medium</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Longbow</td>
                            <td>Width Killing</td>
                            <td>Long</td>
                            <td>Medium</td>
                        </tr>
                        <tr>
                            <td>Heavy Crossbow</td>
                            <td>Width +1 Killing</td>
                            <td>Long</td>
                            <td>If used in short range Ignores 1AR, can't be reloaded in battle</td>
                        </tr>
                        <tr>
                            <td>Compound Bow</td>
                            <td>Width Killing</td>
                            <td>Long</td>
                            <td>+1 Shock</td>
                        </tr>
                    </table>
                    <p style={{ width: '100%' }} className="noPaddingParagraph">
                        Long range weapons can actually shoot much farther than 500 feet, of course, but once
                        you’re past that distance they’re only good for shooting into crowds.  An individual person
                        that far away is too small a target to hit.  If you’re shooting into a large crowd (10 or more
                        people) at extreme long range, any success hits someone.  If the crowd is composed of
                        unworthy opponents (see page 125) you might take one out if you roll well enough.  If they’re
                        not unworthy opponents, the GM decides who you hit, probably with a random roll.
                    </p>
                </AccordionDetails>
            </Accordion>
        </div >
    )
}