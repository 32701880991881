const items = {
    'Alchemist' : {
        trainedWeapon : 'Staff',
        tradeGoods : 'Oil, 1 flask',
        name : 'Alchemist',
        description: 'Alchemists concern themselves with creating, or trading in, a wide range of chemical and herbal substances which are used by the local community. These concoctions can vary in purpose - soaps, polishes, dyes, disinfectants, acids, oils, unguents, perfumes, narcotics, medicines, poisons - the list is almost endless. The larger the society they reside in, the more likely that the alchemist will specialize in making or selling one specific type of compound.',
        descriptionSubType: 'Apothecary, Herbalist, Poisoner, Perfumer...',
    },
    'Agent' : {
        trainedWeapon : 'Staff',
        tradeGoods : 'Oil, 1 flask',
        name : 'Agent',
        description: 'The work of an agent is to report upon, and operate covertly against those his employer wishes to keep an eye on. They watch, discover, and act secretively. Anyone might be an agent - servants, beggars, noblemen, even children. Likewise those that employ agents might be anybody from a jilted lover or struggling merchant to the the ruler of a nation. Agents mostly deal with passing of information or minor acts of sabotage, although a few sometimes perform assassinations if the cause, or payment, is right.',
        descriptionSubType: 'Agitator, Assassin, Detective, Informer, Spy...',
    },
    'Beast Handler' : {
        trainedWeapon : 'Club',
        tradeGoods : 'Pony',
        name : 'Animal Trainer',
        description: 'Beast handlers make their living by capturing, trading, breeding, caring for, or domesticating creatures for a variety of different roles in the community. Examples include horse0whisperers, falconers, hound masters, snake wranglers, and the like. Some raise ostensibly wild animals for domesticated use, whilst others tend the beasts on display in private menageries. A few even take captured beasts and turn them into guardians, battlefield weapons, or competitors for pit fights and gladiatorial events.',
        descriptionSubType: 'Animal Breeder, Beast Tamer, Menagerie Keeper...'
    },
    'Courtesan' : {
        trainedWeapon : 'Dagger',
        tradeGoods : 'Silk Clothes (3x set)',
        name : 'Courtesan',
        description: 'Professional companions, courtesans make their living through providing company, conversation, dance, singing, and sometimes other services to those who can afford it. The attitude towards courtesans can vary dramatically according to their culture, some are little more than tavern girls only there to draw in customers, whilst others are revered by the high ranking wealthy. The most famous and skilled courtesans may literally have princes begging at their feet. Courtesans can be both male and female.',
        descriptionSubType: 'Concubine, Escort, Harem-girl, Houri, Paramour...'
    },
    'Courtier' : {
        trainedWeapon : 'Cane (as staff)',
        tradeGoods : 'A rather nice hat',
        name : 'Courtier',
        description: 'Traditionally members of the gentility or nobility, courtiers are those summoned into the service of a ruler or government to act as an adviser, a companion, an aide or simply as a lackey. Chosen for their ability to be persuasive, insightful or discreet, they are often the power-brokers behind domestic and foreign policy. Some courtiers are in fact ambassadorial representatives from another, foreign nation. Courtiers see and hear much about the affairs of state, and may learn a great deal about politics and scandal in high places. Whatever their political standing, the machinations of courtiers can save or undermine a nation.',
        descriptionSubType: 'Advisor, Conspirator, Delegate, Diplomat, Sycophant'
    },
    'Craftsman' : {
        trainedWeapon : 'Hammer (as club)',
        tradeGoods : '20 gold',
        name : 'Craftsman',
        description: 'Skilled artisans, professional craftsmen cover a huge range of trades: from basket making through to weaving. Most craftsmen specialize in one particular area, but it is not uncommon to find those skilled in a second field related to their primary craft. Some crafters have associated experience in buying materials and selling their own wares, depending on their scale of their business. Primitive crafters may simply be individuals who produce objects for their family or tribe. Civilized crafters on the other hand might be part of a family enterprise or members of an official guild. Some crafts may be unavailable or illogical for particular cultures, such as a smith from primitives. ',
        descriptionSubType: 'Artificer, Artisan, Blacksmith, Cobbler, Cooper, Cheesemaker...'
    },
    'Entertainer' : {
        trainedWeapon : 'Dagger',
        tradeGoods : 'Ukulele (or other tool of trade)',
        name : 'Entertainer',
        description: 'Professional entertainers have a range of skills in which they can specialize. Acrobats perform feats of balance, gymnastics, and hand-eye co-ordination. Actors present plays or ribald pantomimes. Dancers entertain with diverse dances ranging from the erotic to ceremonial. Musicians use their instruments to perform private concerts or provide background music. Poets recite great works of literature, and lyric poetry, whereas singers perform choral works, often as choirs. Story-tellers recount apocryphal tales, and so on. Entertainers sometimes learn several complimentary arts to increase their repertoire and create unique acts. For instance, a bard might combine the art of music, singing and poetry.',
        descriptionSubType: 'Acrobat, Bard, Dancer, Player, Poet...'
    },
    'Farmer' : {
        trainedWeapon : 'Pitchfork (as spear)',
        tradeGoods : 'Tools and seeds related to their specific crop',
        name : 'Farmer',
        description: 'Farmers cultivate the land, raising or harvesting crops, but they are often generalists in a range of skills necessary for foraging or maintaining their chattels. Usually their produce is food and other useful flora, such as medicinal herbs or plants which produce fibres for clothing. Not all agriculturalists necessarily tend a farm or plantation. Some wander through wild regions harvesting the naturally occurring vegetation, whilst others are employed to supervise formal gardens for rich patrons. Likewise not all farmers are necessarily yokels tied to a small pasture, orchard or vineyard. Many cultures may place high status on owners of large estates who manage the running of their property.',
        descriptionSubType: 'Gardener, Estate Owner, Forager, Harvester, Ploughman...'
    },
    'Fisher' : {
        trainedWeapon : 'Knife (as dagger)',
        tradeGoods : 'Fishing Rod',
        name : 'Fisher',
        description: 'Fisherman work the waters, braving the elements and dangers to bring back its bounty. Mostly they trawl for edible food, anything from shellfish to whales. A few seek commodities other than food however, particularly valuable items like rare shells, pearls or even medicinal types of water plants. the social rank of fishermen depends on the value of what they recover; a primitive island based culture for example, might grant high status to those who bring back the greatest amount or most dangerous produce. Fishermen do not necessarily need boats, and neither are they tied to the sea. Many live beside lakes or along rivers, using nets, baskets, and weirs to trap their catch.',
        descriptionSubType: 'Beach Comber, Net Tender, Pearl Diver, Whaler...'
    },
    'Herder' : {
        trainedWeapon : 'Staff',
        tradeGoods : 'Pair of animals specific to their trade',
        name : 'Herder',
        description: 'Herders are those who raise and tend flocks of creatures, not necessarily domesticated animals; anything from goats to migrating mammoths. Most herdsmen spend ddays or even weeks out on the plains or hills, ensuring their beasts have sufficient food and water, whilst also watching for predators. A harder does not necessarily raise his own livestock; some hire themselves out to escort hers over large distances to far off markets or use their skills to purchase stock for less discerning customers. It can be a solitary profession, but herdsmen generally know the land, the seasons, and the habits of their animals.',
        descriptionSubType: 'Breeder, Herdsman, Sacred Goose Tender, Wrangler...'
    },
    'Hunter' : {
        trainedWeapon : 'Shortbow',
        tradeGoods : 'Deer pelt',
        name : 'Hunter',
        description: 'Responsible for finding, tracking, and killing creatures, the hunter, like the herder, is knowledgeable about the land and the behaviour of animals. A hunter may hunt in a variety of ways: simply stalking, trap laying or driving creatures into pens. The purpose of the hunt depends on the culture of the hunter. Most seek out prey for the simple purpose of gathering food, but some hunters specialize in the extermination of deadly predators or the culling of animals for valuable body parts: furs, horns or teeth for instance. Hunters often learn a supplemental craft for preserving or skinning the creatures they kill.',
        descriptionSubType: 'Forester, Poacher, Scavenger, Stalker, Trapper'
    },
    'Merchant' : {
        trainedWeapon : 'Knife (as dagger)',
        tradeGoods : '50 gold',
        name : 'Merchant',
        description: 'Merchants come in many forms: the street vendor, small shop-keeper, wily caravanserai, intrepid merchant-venturer, and many more. Although their methods or merchandise are different, all have one thing in common: to trade commodities (be it goods, food, livestock, people, property or even money) for the highest profit. Merchants know a bargain, and how to drive a good one; and not all trade in legal merchandise. Due to their dealings as middle men, many merchants pick up some knowledge of foreign culture and languages.',
        descriptionSubType: 'Broker, Hawker, Money Lender, Smuggler, Trader...'
    },
    'Miner' : {
        trainedWeapon : 'Pick (as axe)',
        tradeGoods : '1 oz. Mithril',
        name : 'Miner',
        description: 'Miners are those who excavate the ground, for a number of reasons. Some dig for ore and precious metals, a few seek out special types of stone for building or statuary, whilst others dig to create catacombs or sewers, or to penetrate the defences of towns. Whatever the purpose, miners are prepared to go deep into the ground, risking life and limb. As a result, miners tend to be both physically and mentally strong; used to working in hot, cramped conditions for hours on end.',
        descriptionSubType: 'Prospector, Quarryman, Sapper, Well Digger...'
    },
    'Mystic' : {
        trainedWeapon : 'Staff',
        tradeGoods : 'Herbs (various) 1 lb.',
        name : 'Mystic',
        description: 'Mystics are those who seek mastery of the world by the attainment of perfection, spiritual insight or by becoming inured to physical discomfort. Depending on the culture from which they originate, mystics may be found as anything from reclusive mountaintop hermits to respected teachers of martial or artistic schools. The path of their development is always via mastery of self, and most are sagacious as a result of their studies.',
        descriptionSubType: 'Guru, Hermit, Illuminate, Master...'
    },
    'Official' : {
        trainedWeapon : 'Dagger',
        tradeGoods : 'Gold Ring worth 10 gold',
        name : 'Official',
        description: 'Officials are those appointed or elected to an office, charged with performing certain administrative duties and granted a degree of authority to ensure their commands are followed. Examples include slave managers of large estates, guild masters, bureaucratic scribes, legal advocates or tax collectors. Depending on the size and complexity of the organization or government, most officials control a body of men to help perform their duties.',
        descriptionSubType: 'Minister, Overseer, Steward, Tax Collector...'
    },
    'Physician' : {
        trainedWeapon : 'Shovel (as staff)',
        tradeGoods : 'Medical Supplies',
        name : 'Physician',
        description: 'Physicians have detailed knowledge of how the body works, although they do not necessarily use that learning to heal. Some instead offer their skills to extract information by coercion, or further the depth of their education by questionable practices. A physician can take many guises, some examples being the medicine man of a tribe with his totems and fetishes; a local hermit or wise woman with their knowledge of herbs and poultices; or a court physician who can cure fevers and agues with complex chemical remedies. Depending on the sophistication of the culture, many doctors embellish their treatments with ceremonial theatrics, bogus medications, and manipulative psychology.',
        descriptionSubType: 'Doctor, Healer, Medicine Man, Torturer, Vivisectionist...'
    },
    'Priest' : {
        trainedWeapon : 'Cudgel (as staff)',
        tradeGoods : 'Holy Symbol',
        name : 'Priest',
        description: 'Priests can take a variety of different roles in society; an official leading worship of the gods, an illuminated seer leading followers on an exodus to find their god, an administrator in a theocracy, and so on. Priests do not necessarily need to be religious or even believe in the faith they serve, whilst others might be granted miracles from their deity to reward suitable propitiation.',
        descriptionSubType: 'Arbiter, Cultist, Druid, Mendicant, Prophet...'
    },
    'Sailor' : {
        trainedWeapon : 'Cutlass (as shortsword)',
        tradeGoods : 'Sailcloth, 10 sq. yards',
        name : 'Sailor',
        description: 'Sailors and boatmen have a myriad of roles upon the lakes, rivers, and seas, from the simple crew of a trading ship to hardened convict rowers. Those sailors employed as professional raiders or marines are often given shipboard combat training. Wise to the ways of the waves, but superstitious with it, sailors are used to long dangerous travels, and the hardships that go with it.',
        descriptionSubType: 'Captain, Galley Slave, Marine, Pirate, Raft man...'
    },
    'Scholar' : {
        trainedWeapon : 'Staff',
        tradeGoods : 'Lantern',
        name : 'Scholar',
        description: 'the keepers and seekers of knowledge and learning, scholars and scribes are people who are fascinated by knowledge and the powers contained within it. Scholars are not necessarily confined to dusty libraries or suffocating scriptoriums; they may be active explorers and archivists, intent on advancing their learning or collections of tomes. Neither are they dependent on literacy to learn; some scholars memorize and hand down their knowledge orally. Some scholars are specialists in particular areas of knowledge, such as a lawspeaker who is employed to memorize and quote legal codes during law cases.',
        descriptionSubType: 'Annalist, Librarian, Philosopher, Skald, Scribe...'
    },
    'Scout' : {
        trainedWeapon : 'Dagger',
        tradeGoods : 'Falcon',
        name : 'Scout',
        description: 'Scourts are those who thrive beyond the reach of their native civilization They may be pioneers seeking out new territory or rangers employed by the military to keep an eye on the borders. Some are employed to seek out natural commodities which they sell to merchants of more settled lands, whilst others are hired to hunt down fugitives. A few are scholarly explorers, high ranked men seeking to document new cultures or simply folks cursed with chronic wanderlust. Hardened by their life, scouts often live from what the land provides, understanding the plants, animals and seasons of the wilderness they inhabit.',
        descriptionSubType: 'Bounty Hunter, Explorer, Pioneer, Ranger, Wayfarer...'
    },
    'Shaman' : {
        trainedWeapon : 'Dagger',
        tradeGoods : 'Talisman',
        name : 'Shaman',
        description: 'The medicine-man, the witch-doctor, the wise-woman: all commune with the spirits and the ancestors; understanding the secrets of the spirit planes which lie over, under, and around the world we live in. The shaman has great insight and power, or so they would have you believe. Not all have magical powers, some merely follow the rituals and traditions handed down to them Others are outright charlatans using trickery to fool the gullible members of their community. Despite the primitive associations, many civilized cultures also believe in the powers of the spirit word, propitiating dead ancestors for instance.',
        descriptionSubType: 'Animist, Diabolist, Exorcist, Madman, Witch Doctor...'
    },
    'Sorcerer' : {
        trainedWeapon : 'Staff',
        tradeGoods : 'A low level spell scroll',
        name : 'Sorcerer',
        description: 'The dedicated practitioners of sorcery who seek to control the world around them through spells, incantations and rituals that are not necessarily tied to any gods or spirits, but directly alter the fabric of reality. A sorcerer is often fascinated by all aspects of magic, driven to master its subtleties, and discover lost incantations. Some gain their power from a lifetime of studying dusty tomes, whereas others might offer sacrifices to learn from the diabolic entities of forbidden realms. A few are deceivers with no supernatural powers at all, using legerdemain and prestidigitation to fool others into paying them respect.',
        descriptionSubType: 'Enchantress, Magi, Warlock, Witch, Wizard...'
    },
    'Thief' : {
        trainedWeapon : 'Dagger',
        tradeGoods : 'Disguise Kit',
        name : 'Thief',
        description: 'Thieves are those who delight in stealing, or are forced to steal from others to support themselves. They range from petty criminals who mug or pickpocket folk on the streets, to gang leaders and golden-tongued swindlers. Whilst thieves thrive in urban environments they also exist in less civilized cultures too, operating as outlawed brigands, and raiders of neighboring tribes. Some thieves specialize in tomb robbing, stealing the grave goods of the dead. A few independently wealthy cutpurses and burglars are simply bored individuals who thieve for adventurous excitement.',
        descriptionSubType: 'Burglar, Conman, Fence, Mugger, Tomb Raider...'
    },
    'Warrior' : {
        trainedWeapon : 'Longsword',
        tradeGoods : 'Iron Shield',
        name : 'Warrior',
        description: 'Professional soldiers are the warrior class of a community; this is the career of the fighting man. Most of their time is spent patrolling, guarding and training - focusing on the use of weapons, close-quarter tactics, and the study of enemy styles to make the most of any future opportunities. the warrior may or may not follow a particular martial code, and similarly he might focus on finesse or simple brute force.',
        descriptionSubType: 'Bodyguard, Champion, Mercenary, Pit Fighter, Soldier...'
    },
}

export default items;