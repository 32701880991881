import React from "react";
import { SelectExperience } from "../components/Experience";

export class StepFour extends React.Component {
  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="columns">
            <h4 className="reallyHeavyWords">Unusual Experience</h4>
            <small>
              Choose an unusual experience your character has had. Maybe it's affected who they are as a person, or is the reason they've gone and left the shire, or maybe the consequences of the event have yet to come? Unusual experience set you appart from the everyman so pick something memorable and run with it. After all, great heroes deserve great origin stories.
            </small>
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="columns">
            <label className="heavyWords">Choose Your <i>Unusual </i>Experience</label>
            <SelectExperience setFormData={this.props.setFormData} formData={this.props.formData} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="eight columns">
            <label className="heavyWords">Additional Info</label>
            <textarea
              onKeyDown="return event.key != 'Enter';"
              className="u-full-width"
              placeholder="Describe your Unusual Experience in detail here. "
              value={this.props.formData.experienceAdditionalInfo}
              onChange={(event) =>
                this.props.setFormData({ ...this.props.formData, experienceAdditionalInfo: event.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
