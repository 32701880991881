import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';
import silhouette from '../../img/satyr.png'

import { CharacterDamageTool } from '../../js/components/CharacterDamageTool'

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function CombatConditions(props) {
    return (
        <div>
            <p className="noPaddingParagraph">
                Here are the rules for those gray zones between “perfectly fine” and “dead.”
            </p>
            <span className="heavyWords">Blind</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                If you’re trying to swing at something you can’t see, your attack is at Difficulty 4. If you’re
                trying to shoot or throw at something you can’t see, your attack is made at a –2d penalty.
            </p>
            <p className="noPaddingParagraph">
                Grapple attacks are exempt from this penalty, as long as you had a grip on your opponent
                before you lost your vision.
            </p>
            <span className="heavyWords">Dazed</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                If your torso is filled with Shock damage, you can’t catch your breath and every limb
                seems to respond slowly. When this happens, your character is dazed, and takes a -1d
                penalty to every action. This is in addition to any other penalties.
            </p>
            <span className="heavyWords">Downed</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                If you’re downed,you’re flat on your back, or sitting, or crawling on all fours. It’s a bad
                position to fight from. If you try to take any sort of combat action, you take a -1d penalty
                in addition to any other penalties. Once you’re downed, you stay down until you take a
                round to stand up. If you stand and do nothing else, you can do it automatically during
                the Declare phase. If you try to stand and take another action, roll the second action with
                a –1d penalty. If you get a set, you stand when that set goes off. If you don’t get a set, you
                stand at the end of the Resolve phase.
            </p>
            <p className="noPaddingParagraph">
                You can’t move more than a few feet in any direction while downed.
            </p>
            <span className="heavyWords">Unconcious</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                If your head fills up with Shock damage, you drop. You remain unconscious for at least a
                half hour, unless revived. (Reviving an unconscious person requires a successful Healing
                roll. It doesn’t restore any shocked boxes—some of them disappear when the combat
                ends, as described on page 114. But just because you have an unshocked box in your head
                doesn’t mean you’re awake and aware.)
            </p>
        </div>
    )
}