import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function SkillChecks(props) {
    return (
        <div>
            <div className="row">
                <div className="columns ">
                    <Accordion>
                        <AccordionSummary style={{ backgroundColor: '#95ab9c', color: '#311E10' }} expandIcon={<ExpandMore />}>Static</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <p className="noPaddingParagraph">
                                Static Contests are the simplest of challenges. In a static contest, it's your character against circumstance. Perhaps you're trying to swim across a river, lift a heavy boulder, carve a key out of a bar of soap, or
                                jump out of the way of an avalanche. It's a one-time event and you'll either succeed or fail.
                            </p>
                            <p className="noPaddingParagraph">To get the result simply roll you pool. Got a match? Good- you've succeeded. It's that simple.</p>
                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>If it's not that simple, i.e It's a challenging task you want to accomplish, the GM (me) may set a minimum set <b>Height</b> you need to roll to achieve your task. Or he may impose a penalty on the roll (more on that in the tab below) </p>
                            <p style={{ marginBottom: '4px'}}><b>Examples:</b></p>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p className="noPaddingParagraph">
                                        River Tam, Last Blooded Chief of the Meskie Clan kneels before a frothing river the color of wine. Without looking, he points over his shoulder.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        A breathspan passes and then a splash. <br /> He starts to count.
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[2, 6, 6, 5, 4, 3, 1].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                        A moment later he spies the drenched Swallow draging itself out onto the distant bank, guide rope still tight around its waist. Men move to assemble the aparati. Silence reigns.
                                    </p>
                                    <p className="noPaddingParagraph"><b>The Swallow got a pair of sixes (2X6) on his Body(4)+Endurance(3) roll. Thus The Swallow crosses the river succesfully.</b></p>
                                </div>
                            </div>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p className="noPaddingParagraph">
                                        Gunter 'Glider' Barnabus, self proclaimed huntsman extraordinaire, and three time visitor to the Stonea Camp Infirmary perches on a branch jutting from the trunk of a twisted pine tree.
                                        Somewhere far below he hears his friend Rudy calling up to him. He shakes his head and grins "Comeon now if a squirell can do it, it can nae be all that hard."
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[2, 1, 3, 5].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div><p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                        Gunter 'Glider' Barnabus, self proclaimed huntsman extraordinaire, make a fourth visit to the Stonea Camp Infirmary.
                                    </p>
                                    <p className="noPaddingParagraph"><b>Gunter rolls his Coordination(1)+Climb(3) pool of four total dice but gets no sets. Thus he fails.</b></p>

                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className="row">
                <div className="columns ">
                    <Accordion>
                        <AccordionSummary style={{ backgroundColor: '#cdb970', color: '#311E10' }} expandIcon={<ExpandMore />}>Dynamic</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <p className="noPaddingParagraph">
                                Dynamic Contests arewhen two or more people are trying to do the same thing at the same time, and only one of them can. An example is a foot race (two or more characters
                                using their <b>Body+Run</b> dice pools). Or it could be a battle of wits as two swains try to impress a beautiful stranger (<b>Charm+Fascinate</b>). Or two advisors attempting to sway the king’s
                                opinion with <b>Charm+Fascinate </b>(or <b>Command+Intimidate</b>, or <b>Charm+Plead</b>) rolls.
                            </p>
                            <p className="noPaddingParagraph">Regardless of the type of contest, the rules all work the same way. All involved contestants roll their pools and choose what sets they're going to use. Then those sets are compared and the best one walks
                                away with the prize- in the cases above: a trophy, the stranger's attention, or the will of the king.</p>
                            <p className="noPaddingParagraph">Which set is 'best' depends entirely on the nature of the competition. In a foot race, the GM might say that the <b>Widest</b> set is the best because <b>Width </b>
                                indicates speed. With the struggling suitors, <b>Height</b> might be a better guage, as it show's who's favored by fortune, who's luckier, or perhaps who's better adapting to circumstances. To persuade the king, it might
                                be <b>Height</b> if each side has plenty of time to set out arguments and provide evidence. However, if the barbarians are already at the gate and seconds matter, <b>Width</b> might serve, as a tersely-stated case
                                plays to the king's need for decisiveness.
                            </p>
                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                The GM will make it clear beforehand whether Width or Height is the deciding factor. Or whether there are different rewards for each. Perhaps in the case of the suitors the one
                                with the <i>quick</i> wit wins the fair strangers eye, but the narrow/high result keeps his dignity and impresses the onlookers, who
                                dismiss the wide/low character's desperate clowning.
                            </p>
                            <p style={{ marginBottom: '4px'}}><b>Examples:</b></p>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p style={{ marginBottom: '4px' }}>
                                        "Bet I can beat you to that tree over there."
                                    </p>
                                    <p style={{ marginBottom: '4px' }}>
                                        "Bet ya' can't. "
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[2, 6, 6, 4].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[3, 3, 3, 1].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p className="noPaddingParagraph">
                                        <b>The second runner wins. Though his set of 3's was not Higher than the first runner's, it's Width (speed) was greater. Again, the GM will let you know before the contest what the winning condition is if it's not obvious.</b>
                                    </p>
                                </div>
                            </div>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p style={{ marginBottom: '4px' }}>
                                        "Bet I can lift a bigger rock than you."
                                    </p>
                                    <p style={{ marginBottom: '4px' }}>
                                        "Bet ya' can't. "
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[2, 2, 2, 2].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[4, 4, 2, 1].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p className="noPaddingParagraph">
                                        <b>The second lifter wins. Though he lifted his boulder slower than the other contestant, his roll's Height was greater.</b>
                                    </p>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className="row">
                <div className="columns ">
                    <Accordion>
                        <AccordionSummary style={{ backgroundColor: '#c98a82', color: '#311E10' }} expandIcon={<ExpandMore />}>Opposed</AccordionSummary>
                        <AccordionDetails style={{ display: 'grid' }}>
                            <p className="noPaddingParagraph">
                                Opposed Contests are for when one character wants to climb the rope, present his evidence, or bury his sword in somebody's spleen. While another
                                character wants to pull him down, dismiss his testimony, or avoid that lethal thrust. These are opposed contests and they are handled
                                with a mechanic called <b>Gobble Dice</b>.
                            </p>
                            <p className="noPaddingParagraph">
                                The character attempting the action— climb, debate, stab —rolls normally. In these instances,
                                we’re looking at <b>Coordination+Climb</b>, <b>Command+Inspire</b> and <b>Body+Fight</b> (or <b>Coordination+[Weapon_Skill] if she's taken a specific sword skill</b>). The blocking
                                character who wants to drag, obfuscate, or jump back, rolls her pool as well. She’d be rolling
                                <b> Body+Fight</b>, <b>Command+Inspire</b>, or <b>Coordination+Dodge</b>.
                            </p>
                            <p className="noPaddingParagraph">
                                If the active character fails his roll, the blocker’s roll doesn’t matter. If the climber can’t get
                                up the rope on his own, he sure as hell can’t do it with someone grabbing his legs.
                            </p>
                            <p className="noPaddingParagraph">
                                If the active character succeeds and the blocker gets no successes, then the attempt
                                succeeds, like you’d expect. He gets up, gets heard, or spills blood.
                            </p>
                            <p className="noPaddingParagraph">
                                It gets interesting when both succeed. Then the dice in the blocker’s pool become <b>Gobble Dice</b>.
                                Each Gobble die can counteract one die in an opponent’s set, as long as that die is of equal
                                or lesser value. <b>Once a set is broken down to one die or fewer, it no longer has any value</b>.
                            </p>
                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                Read the examples below for a better understand of exactly how <b>Gobble Dice</b> work.
                            </p>
                            <p style={{ marginBottom: '4px'}}><b>Examples:</b></p>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p className="noPaddingParagraph">
                                        Cillian grabs the rope and begins to climb. An alarm rings out, and in a flash the room is filled with the sound
                                        of stomping boots. She attempts to haul herself up-
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[1, 1, 5, 1, 4, 6, 4].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p className="noPaddingParagraph">
                                        While a guard makes a desperate lunge for the rope-
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[4, 4, 6, 5].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                        <b>
                                            Cillian chooses her 3X1 set over her 2X4 because Width determines speed. She succeeds in climbing the rope before the guard is able to get a handle on it to bring her down.
                                            And thus, even though the guard had the ability to catch her, he was neither quick nor lucky enough to do so and she escapes.
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p className="noPaddingParagraph">
                                        Lapis stands on the edge of a crumbling marble balcony at the top of Tow Centuri. Wind howls in her ears as she looks up at the writhing mass of multi-colored storm clouds above.
                                        As the first dyed rain drops stain her cheeks she tenses.
                                        She doesn't hear the foot steps of a man moving behind her, but she doesn't expect to.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        Everyone knows the Val'aluh walk on air.
                                    </p>
                                    <p className="noPaddingParagraph">
                                        A seconds pause and then she throws herself into the void-
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[2, 3, 3, 5].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div><p className="noPaddingParagraph">
                                        Hands each out to grab her-
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[1, 3, 3, 4, 4, 5, 5].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                        <b>
                                            Lapis gets a pair of 3's on her Body+Athletics roll. Because there is no Width difference between her pair of 3's or the man behind her's
                                            5's they happen at the same time. By using one of his 2X5's he gobbles one die out of her 2x3 set, thus reducing it to 1x3—no
                                            match. She is taken.
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div style={{ maxWidth: '500px' }}>
                                    <p className="noPaddingParagraph">
                                        Jonesy crouches behind a raised pedestal topped with a small golden idol. He's trying to seize the item off the pedestal and then climb up the rope
                                        behind him (but if he can't get the idol, he'll still try to flee the guard standing with her back to the pedestal). He rolls a <b>Multiple Action </b>
                                        (described later) hoping to get two sets -one to grab the gem, one to go up the rope- he rolls.
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[2, 2, 5, 5, 1].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p className="noPaddingParagraph">
                                        The guard turns at the alarm and move to grab him. She only needs to roll one <b>Body+Fight</b> pool
                                        to grab him. She rolls.
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                        {[6, 6, 1, 2, 3, 4].map((v) => (
                                            <Die key={Math.random()} value={v} />
                                        ))}
                                    </div>
                                    <p className="noPaddingParagraph">
                                        <b>
                                            Her two sixes become Gobble Dice. With one, she knocks a single die out of the 2x5 set,
                                            reducing it to 1x5 and, therefore, nothing. With the other, she takes a die out of the 2x2 set.
                                            Just by grabbing him, she’s prevented him from taking the stone and from climbing the rope.
                                        </b>
                                    </p>
                                    <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                        When defending against more than one person or action, Gobble Dice can be used on different sets
                                        even if the defender only got a single pair.
                                    </p>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}