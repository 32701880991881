import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function DifficultyAndPenalties(props) {
    return (
        <div style={{ minWidth: '900px'}}>
            <p style={{ marginBottom: '4px', textIndent: '30px' }}>
                Every task has a <b>Difficulty</b> rated from 1 to 10. Difficulty indicates how hard a task is for everyon, novices and experts alike.
            </p>
            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                Once a difficulty is assigned, you roll your dice pool and discard any dice which are <i>lower</i> than the difficulty.
                Example: Your party is invited to dine with a local lord for the night. You were seated next to their very
            </p>
            <table style={{ width: '100%', lineHeight: '1' }} >
                <caption>
                    <b>Difficulty</b>
                </caption>
                <tr>
                    <th>Difficulty</th>
                    <th>Rating</th>
                    <th>Example</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Easy</td>
                    <td>Boiling up a tasty bowl of soup</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Demanding</td>
                    <td>Swimming across a river</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Challenging</td>
                    <td>Climbing down a muddy cliff face after a rainstorm</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Hard</td>
                    <td>Catching a wild rabbit with your bare hands</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Very Hard</td>
                    <td>Winning a rigged carnival contest</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Extremely Hard</td>
                    <td>Wrestling a large black bear</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Formidable</td>
                    <td>Piloting a leakly Skipjack across a wide river in a storm</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Gargantuan</td>
                    <td>Wrestling a small brown bear</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Titanic</td>
                    <td>Convincing a local lord that you deserve to pay fewer taxes than your fellow peons</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Don't Even Try</td>
                    <td>Smacking that mosquito that's been buzzing around your head all afternoon.</td>
                </tr>
            </table>
            <p style={{ marginBottom: '4px', textIndent: '30px' }}>
                A <b>Penalty</b> is when the GM knocks some dice out of your pool. This indicates a problem where someone of a lesser skill is unlikely to
                have a chance, and even an expert isn't going to have an easy time.
            </p>
            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                The difficulty between <b>Penalties</b> and <b>Difficulties</b> is that <b>Difficulties</b> make things harder for everyone to an equal degree, regardless
                of pool size. <b>Penalties</b> are far harsher to people with small pools. <b>Note that with penalties Expert or Master Dice are removed from a pool first</b>
            </p>
            <table style={{ width: '100%', lineHeight: '1' }} >
                <caption>
                    <b>Penalty</b>
                </caption>
                <tr>
                    <th>Penalty</th>
                    <th>Rating</th>
                    <th>Example</th>
                </tr>
                <tr>
                    <td>-1D</td>
                    <td>Hindering</td>
                    <td>Trying to find a misfiled scroll</td>
                </tr>
                <tr>
                    <td>-2D</td>
                    <td>Frustrating</td>
                    <td>Attempting to climb down a cliff during a hurricane</td>
                </tr>
                <tr>
                    <td>-3D</td>
                    <td>Handicapping</td>
                    <td>Sneaking past a company of alert, trained guards</td>
                </tr>
                <tr>
                    <td>-4D</td>
                    <td>Hamstringing</td>
                    <td>Trying to get something from a noble who's son's nose you broke earlier that morning</td>
                </tr>
                <tr>
                    <td>-5D</td>
                    <td>Thwarting</td>
                    <td>Stealing an item off of someone's neck, or if it has their attention and is in their immediate line of sight</td>
                </tr>
            </table>
        </div>
    )
}