import React from "react";
import { SelectRace, SelectMonth } from "../components/BirthMonthAndRace";

export class StepTwo extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      emailConfirm: ""
    };
    this.handleEmailChanged = this.handleEmailChanged.bind(this);
    this.handleEmailConfirmChanged = this.handleEmailConfirmChanged.bind(this);
  }

  handleEmailChanged(event) {
    this.setState({ email: event.target.value });
  }

  handleEmailConfirmChanged(event) {
    this.setState({ emailConfirm: event.target.value });
  }

  render() {
    return (
      <div>
        <div className="row">
          <label className="heavyWords">Name</label>
          <input
            style={{ maxWidth: '300px' }}
            className="u-full-width"
            placeholder="Kimi no Na wa"
            type="text"
            autoFocus
            value={this.props.formData.name}
            onChange={(event) =>
              this.props.setFormData({ ...this.props.formData, name: event.target.value })
            }
          />
        </div>
        <label className="heavyWords" style={{ marginTop: "20px" }}>Birth Month</label>
        <small>
          The gods grant blessings to those born in their worshipped month.
        </small>
        <small>
          By Norigradian custom, birthdays are celebrated on the first of each month as a communal affair. Oftentimes towns will designate this day as a holiday. Gifts are exchanged.
        </small>
        <div className="row">
          <SelectMonth setFormData={this.props.setFormData} formData={this.props.formData} ></SelectMonth>
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <label className="heavyWords">Age</label>
          <input
            style={{ maxWidth: '300px' }}
            className="u-full-width"
            placeholder="Age"
            type="text"
            autoFocus
            value={this.props.formData.age}
            onChange={(event) =>
              this.props.setFormData({ ...this.props.formData, age: event.target.value })
            }
          />
        </div>
        <label className="heavyWords" style={{ marginTop: "20px" }}>Race</label>
        <div className="row">
          <SelectRace setFormData={this.props.setFormData} formData={this.props.formData} ></SelectRace>
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="eight columns">
            <label className="heavyWords">Additional Info</label>
            <textarea
              onKeyDown="return event.key != 'Enter';"
              className="u-full-width"
              placeholder="Describe yourself. Be as descriptive or vague as you'd like."
              value={this.props.formData.userAdditionalInfo}
              onChange={(event) =>
                this.props.setFormData({ ...this.props.formData, userAdditionalInfo: event.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
