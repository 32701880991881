import React from "react";
import Die from "./assets/js/components/die";
import { FontBackground } from "./assets/js/components/FontBackground";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from './assets/css/colors.module.css';
import { Navigation } from "./Navigation";

import { SkillChecks } from './assets/js/howto/SkillCheck'
import { DifficultyAndPenalties } from './assets/js/howto/DifficultyAndPenalties'
import { TheBasics } from './assets/js/howto/TheBasics'
import { MultipleActions } from './assets/js/howto/MultipleActions'
import { TheCombatRound } from './assets/js/howto/TheCombatRound'
import { DamageDeathHealing } from './assets/js/howto/DamageDeathHealing'
import { Cover } from './assets/js/howto/Cover'
import { CombatConditions } from './assets/js/howto/CombatConditions'
import { Gear } from './assets/js/howto/Gear'
import { UnworthyOpponents } from './assets/js/howto/UnworthyOpponents'
import { Money } from './assets/js/howto/Money'
import { ExperiencePoints } from './assets/js/howto/ExperiencePoints'

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        overflowX: 'scroll',
        scrollbarWidth: 'thin'
    }
})(MuiAccordionDetails);

export default function HowToPlay(props) {

    return (
        <div>
            <Navigation />
            <div style={{ minHeight: '70vh' }}>
                <FontBackground />
                <div className="container" style={{ marginBottom: '15px' }}>
                    <div className="col-sm-8">
                        <div style={{ paddingLeft: 'clamp(0px, 2%, 20px)', paddingRight: 'clamp(0px, 2%, 10px)' }}>
                            <h4 className="reallyHeavyWords" style={{ marginTop: '20px' }}>How To Play</h4>
                            <p className="noPaddingParagraph">
                                Below is a bare bones look at everything you could possibly need to know about using the One Roll Engine. If you find yourself wanting to dive deeper into anything described here then please check out the creator of the ORE, Greg Stolze's, website for details <a href="https://gregstolze.com/reign1/">here</a>.
                            </p>
                            <TheBasics />
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="darkTan" expandIcon={<ExpandMore />}>Skill Checks</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <SkillChecks />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="lightTan" expandIcon={<ExpandMore />}>Multiple Actions</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <MultipleActions />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="darkTan" expandIcon={<ExpandMore />}>Difficulty and Penalties</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <DifficultyAndPenalties />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <h4 className="heavyWords" style={{ marginTop: '20px' }}>Combat</h4>
                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                Combat is broken down into “rounds.” A combat round isn’t an exact measure of time—it’s
                                as much time as it takes for the combatants to act. It’s a contrivance to make the rules work.
                                It could be as little as half a second if the combat is two excellent fighters dueling. If it’s a
                                broad melee, it could be as long as five seconds.
                            </p>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="darkTan" expandIcon={<ExpandMore />}>The Combat Round</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <TheCombatRound />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="lightTan" expandIcon={<ExpandMore />}>Damage, Dying, and Healing</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <DamageDeathHealing />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="darkTan" expandIcon={<ExpandMore />}>Cover</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <Cover />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="lightTan" expandIcon={<ExpandMore />}>Gear</AccordionSummary>
                                        <AccordionDetails>
                                            <Gear />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="darkTan" expandIcon={<ExpandMore />}>Combat Conditions</AccordionSummary>
                                        <AccordionDetails>
                                            <CombatConditions />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="lightTan" expandIcon={<ExpandMore />}>Unworthy Opponents</AccordionSummary>
                                        <AccordionDetails>
                                            <UnworthyOpponents />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <h4 className="heavyWords" style={{ marginTop: '20px' }}>Character Upgrades</h4>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="darkTan" expandIcon={<ExpandMore />}>Experience [XP]</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <ExperiencePoints />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns ">
                                    <Accordion>
                                        <AccordionSummary className="lightTan" expandIcon={<ExpandMore />}>Money</AccordionSummary>
                                        <AccordionDetails style={{ display: 'grid' }}>
                                            <Money />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <h4 className="heavyWords" style={{ marginTop: '20px' }}>Possible Avenues to Explore</h4>
                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                There are many more systems to explore in the ORE. A few are mentioned in brief here and, if there is interest in pursuing any of these unique avenues in game, they could be further expanded in the future.
                            </p>

                            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                                <ul>
                                    <li>
                                        <b>Companies:</b>
                                        <p className="noPaddingParagraph">
                                            Just as a character is defined by Stats, Skills and Advantages, a Company is defined by its
                                            goal and by its Qualities: Might, Territory, Sovereignty, Treasure, and Influence are the building blocks of a company.
                                            A Company is formed to achieve a goal, from something as broad as "Seek knowledge wherever it's found" to something as
                                            concrete as "Assassinate the King!" The ORE includes provisions for forming a company and having it interact with the landscape around it.
                                            If you find yourself wanting to feel like you're turning the gears of the world, forming a Company is certainly one way to go about doing so.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Magic:</b>
                                        <p className="noPaddingParagraph">
                                            Self explanatory, but the ORE offers a system for creating magic spells that can be used both in and out of combat. If that's something you want to explore
                                            let me know.
                                        </p>
                                    </li>
                                    <li>
                                        <b>Buildings, Defences, and Machines of War:</b>
                                        <p className="noPaddingParagraph">
                                            After forming a company or amassing a large enough pile of wealth, one may draw the attention of outside parties.
                                            Outside parties could be anything from brigands, to a politically savy neighbooring lord, to a lone skilled thief. This section
                                            of the ORE focuses more on community building creating a real solid Company or Town and perhaps building it all the way up to the level of empire.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}