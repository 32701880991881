import React, { useState, useEffect } from 'react';
import './fades.module.css';

import { MenuItem, Select } from '@material-ui/core';

import races from '../data/races.js';
import months from '../data/months.js';

export function SelectMonth(props) {
    const { formData, setFormData } = props;
    const [infoText, setInfoText] = useState('')
    const [month, setMonth] = useState(formData.birthMonth)
    const [initLoad, setInitLoad] = useState(true);

    useEffect(() => {
        setMonth(formData.birthMonth);
        if (initLoad && month != '') {
            setInfoText(months[month]);
        }
        setInitLoad(false);
    }, [formData.birthMonth]);


    const handleChange = (event) => {
        setFormData({ ...formData, birthMonth: event.target.value });
        setInfoText(months[event.target.value])
    };

    return (
        <div >
            <Select value={month} onChange={handleChange} >
                <MenuItem value={'Janos'}>Janos</MenuItem>
                <MenuItem value={'Februs'}>Februs</MenuItem>
                <MenuItem value={'Marc'}>Marc</MenuItem>
                <MenuItem value={'April'}>April</MenuItem>
                <MenuItem value={'Maia'}>Maia</MenuItem>
                <MenuItem value={'Yune'}>Yune</MenuItem>
                <MenuItem value={'Jule'}>Jule</MenuItem>
                <MenuItem value={'Barmhardt'}>Barmhardt</MenuItem>
                <MenuItem value={'Syvtember'}>Syvtember</MenuItem>
                <MenuItem value={'Oktober'}>Oktober</MenuItem>
                <MenuItem value={'Neuvember'}>Neuvember</MenuItem>
                <MenuItem value={'Zehnember'}>Zehnember</MenuItem>
            </Select>
            {infoText ?
                <div className='fancyBorder'>
                    <p dangerouslySetInnerHTML={{ __html: infoText }}></p>
                </div>
                : ''}
        </div>
    )
}

export function SelectRace(props) {
    const { formData, setFormData } = props;
    const [infoText, setInfoText] = useState('')
    const [race, setRace] = useState(formData.race)
    const [initLoad, setInitLoad] = useState(true);

    useEffect(() => {
        setRace(formData.race);
        if (initLoad && race != '') {
            setInfoText(races[race]);
        }
        setInitLoad(false);
    }, [formData.race]);


    const handleChange = (event) => {
        setFormData({ ...formData, race: event.target.value });
        setInfoText(races[event.target.value])
    };

    return (
        <div>
            <Select value={race} onChange={handleChange} >
                <MenuItem value={'Dwarf'}>Dwarf</MenuItem>
                <MenuItem value={'Elf'}>Elf</MenuItem>
                <MenuItem value={'Halfling'}>Halfling</MenuItem>
                <MenuItem value={'Human'}>Human</MenuItem>
                <MenuItem value={'Gnome'}>Gnome</MenuItem>
                <MenuItem value={'Half-Elf'}>Half-Elf</MenuItem>
                <MenuItem value={'Half-Orc'}>Half-Orc</MenuItem>
                <MenuItem value={'Hobgoblin'}>Hobgoblin</MenuItem>
                <MenuItem value={'Tengu'}>Tengu</MenuItem>
                <MenuItem value={'Wyrwood'}>Wyrwood</MenuItem>
                <MenuItem value={'Tiefling'}>Tiefling</MenuItem>
            </Select>
            {infoText.description ?
                <div className="fancyBorder">
                    <p><b>Physical Description: </b>{infoText.description}</p>
                    <p><b>Society: </b>{infoText.society}</p>
                </div>
                :
                <></>
            }
        </div>
    )
}