import React from "react";
import Die from "./assets/js/components/die";
import { FontBackground } from "./assets/js/components/FontBackground";
import { Navigation } from "./Navigation";
import { Link } from "react-router-dom";

export default function Home(props) {
    return (
        <div>
            <Navigation />
            <div style={{ minHeight: '70vh' }}>
                <FontBackground />
                <div className="container" style={{ background: 'none', maxWidth: '300px', minWidth: '200px', marginBottom: '15px' }}>
                    <div className="col-sm-8" >
                        <div className="diceBox" style={{ display: 'grid' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {[3, 4, 5, 3].map((v) => (
                                    <Die key={Math.random()} value={v} />
                                ))}
                            </div>
                            <Link className="item -link heavyWords" style={{paddingTop: '10px'}} to="/CharacterCreator">Character Creator</Link>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ background: 'none', maxWidth: '300px', minWidth: '200px', marginBottom: '15px' }}>
                    <div className="col-sm-8">
                        <div className="diceBox" style={{ display: 'grid' }}>
                            <div style={{ display: 'flex', justifyContent: 'center'  }}>
                                {[1, 2, 3, 2, 1].map((v) => (
                                    <Die key={Math.random()} value={v} />
                                ))}
                            </div>
                            <Link className="item -link heavyWords" style={{paddingTop: '10px'}} to="/HowToPlay">How to Play</Link>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ background: 'none', maxWidth: '300px', minWidth: '200px', marginBottom: '15px' }}>
                    <div className="col-sm-8">
                        <div className="diceBox" style={{ display: 'grid' }}>
                            <div style={{ display: 'flex', justifyContent: 'center'  }}>
                                {[2, 6, 2].map((v) => (
                                    <Die key={Math.random()} value={v} />
                                ))}
                            </div>
                            <Link className="item -link heavyWords" style={{paddingTop: '10px'}} to="/Library">Library</Link>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ background: 'none', maxWidth: '300px', minWidth: '200px', marginBottom: '15px' }}>
                    <div className="col-sm-8">
                        <div className="diceBox" style={{ display: 'grid' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {[3, 4, 5, 3].map((v) => (
                                    <Die key={Math.random()} value={v} />
                                ))}
                            </div>
                            <Link className="item -link heavyWords" style={{paddingTop: '10px'}} to="/CharacterLookup">Character Lookup</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}