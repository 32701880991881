import React from "react";
import Die from "../components/die";

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styles from '../../css/colors.module.css';
import silhouette from '../../img/satyr.png'

import { CharacterDamageTool } from '../../js/components/CharacterDamageTool'

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: '2px',
        fontSize: 22,
        fontWeight: '700',
        letterSpacing: '1px',
        color: '#000',
        textTransform: 'uppercase',
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    content: {
        display: 'block',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export function UnworthyOpponents(props) {
    return (
        <div>
            <span className="heavyWords">Damage</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                Whether you call them “mooks,” “minions,” or “extras,” many stories feature hordes of
                opponents assaulting the hero.
            </p>
            <p className="noPaddingParagraph">
                When PCs fight warriors of equal skill, they use the same rules. With hit locations, dice
                pools, multiple actions and so forth. However, against “unworthy opponents”—unnamed
                thugs whose purpose is to provide a minor tactical challenge, not a climactic battle—the
                rules are simpler.
            </p>
            <p className="noPaddingParagraph">
                Minions roll collectively. The GM rolls a pool of dice equal to the number of attackers, up
                to a 15d maximum. Ten cultists attack? GM rolls 10d. Fifty soldiers? 15d, until the PCs
                whittle them down to fourteen foes.
            </p>
            <p className="noPaddingParagraph">
                Unworthy opponents have no hit locations or wounds to track. They are binary, either
                fully functioning or out of commission. (What “out of commission” means depends on
                what your charactes use against them. If it’s hand-to-hand weapons, they could be killed and
                maimed, or they could be unconscious or surrendering or fleeing.)
            </p>
            <p className="noPaddingParagraph">
                Not all mobs are created equal, so unworthy opponents have one stat: Their Threat
            </p>
            <span className="heavyWords" style={{}}>Threat</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p style={{ marginBottom: '40px' }}>
                Threat measures how dangerous an individual minion is. An unarmed, untrained minion,
                alone, has Threat 1. Facing a PC, he’d roll 1d to attack. He cannot get a match, no way.
            </p>
            <p className="noPaddingParagraph">
                There are three factors that can increase the combat worth (and hence, the Threat) of a thug
            </p>
            <p className="noPaddingParagraph">
                <b>Equipment:</b> If the scrubs are equipped with weapons better than clubs or stones, or with
                armor (even just shields), or both, their Threat scores go up by 1.
            </p>
            <p className="noPaddingParagraph">
                <b>Motivation:</b> Someone who’s fighting because a distant ruler conscripted him is less fierce
                than someone fighting for his children’s lives. A fighter who’s trained, even partially,
                shows greater mettle on the battlefield. Someone who’s got a leader giving direct orders is
                more likely to fight hard.
            </p>
            <p className="noPaddingParagraph">
                <b>Enchantment:</b> There are many, many spells for making people fight better. Everything
                from bestowing magical strength to instilling maniacal bloodlust has the same
                mechanical effect: A +1 Threat bonus.
            </p>
            <p className="noPaddingParagraph">
                All these factors can provide the motivation boost to Threat. If the scrubs are trained
                warriors, or are cornered with nothing to lose, or have a leader who has used the
                Inspiration Skill, their Threat scores all go up by one.
            </p>
            <p className="noPaddingParagraph">
                Thus we see that Threat scores for unworthy opponents range from 1-4. Threat 1 enemies
                are disorganized, waving rocks, easily defeated. Threat 2 hordes have an edge: Maybe
                they’re the ensorcelled thralls of a magus, maybe they’re armed, or maybe they’re
                desperate enough to fight to the death. Threat 3 hordes are decent: This covers any militia
                that has weapons and knows how to use them. Typical guards, pirate crews and
                field soldiers are Threat 3. Enemies at Threat 4 are top-grade— trained, equipped, and
                given some sort of magical boost. 4 is an appropriate Threat level for elite units like royal
                honor guards and the defenders of the temple’s sanctum.
            </p>
            <span className="heavyWords" style={{}}>Out of the Action</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                It’s easy to remove a scrub from a fight: All you have to do is hit him with any attack that has
                a <b>Height</b> or <b>Width</b> equal to his Threat or greater. Against Threat 1-2 enemies, this means
                any success at all. A Threat 4 minion needs a 4x match, or a set whose <b>Height</b> is 4 or more.
            </p>
            <p className="noPaddingParagraph">
                Minions are either fighting at their full capacity, or they’re done.
            </p>
            <span className="heavyWords">Moral Attacks</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                In addition to being removed by getting hit, they can also be scared away by Morale
                Attacks. Certain spells, or maneuvers, are so impressive that henchmen who see them
                may cut and run.
            </p>
            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                Some techniques produce a Morale Attack equal to the <b>Height</b> of a roll, or its <b>Width</b>, or equal
                to some outside factor. But regardless of the value of the Morale Attack, they all work the
                same way. When used, a number of unworthy opponents equal to the Morale Attack’s rating
                cut and run. The only exception is if their Threat is equal to or greater than the Morale Attack.
            </p>
            <p>
                <b>Example:</b>
            </p>
            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} >
                <div style={{ maxWidth: '500px' }}>
                    Twenty villagers with Threat 2 are storming a sorcerer’s castle.  He casts a spell which
                    produces a Morale Attack equal to the Height of his roll.  He gets a <b>2x10</b>.  The Morale Attack 
                    sends ten of them off screaming.  If he’d rolled a <b>2x2</b>, however, his Morale Attack 2
                    would not have frightened any of them, because their Threat is high enough to resist it.
                </div>
            </div>
            <span className="heavyWords">Ganging Up</span>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <p className="noPaddingParagraph">
                The GM does not roll an individual dice pool for each scrub in a group. Instead, he rolls
                one pool for all of them. The size of this pool is equal to the number of fighters in the
                group. These pools can never be higher than 15 dice, however.
            </p>
            <p className="noPaddingParagraph">
                Unworthies can only gang up when they’re massed. If a group of ten mooks splits up,
                one chasing the hero who fled north and the other chasing the hero who ran south, the
                GM doesn’t roll one pool of ten for both groups: He rolls two pools of five (or however
                the mob splits). This has a drastic effect. Splitting the pool quarantines unmatched dice from
                each group, keeping them from finding mates in the other group’s unmatched dice.
            </p>
            <p className="noPaddingParagraph">
                Minions don’t aim or do any fancy maneuvers. They just attack, parry and dodge. They don’t
                have to declare their intentions (since their intentions are so simple). The GM has leeway to
                use sets from the minion pool as Gobble Dice or attacks, whichever seems most reasonable.
            </p>
            <p className="noPaddingParagraph">
                Rolling up to 15d in a pool means large crowds always get matches. But there are
                restrictions on the minions’ use of matches.
                <ol>
                    <li>
                        No fancy moves. As described above, unworthy opponents only attack or avoid.
                    </li>
                    <li>
                        No Wide successes. The GM cannot assemble a set Wider than 3 from a mook pool.
                        Any match of 4x must be broken into two pairs. A 5x match becomes a pair and a trio, a
                        6x match can be three pairs or two trios, and so forth.
                    </li>
                    <li>
                        Minions are slow. Any time there’s a tie between a worthy opponent and an unworthy,
                        the minion goes last.
                    </li>
                </ol>
            </p>
            <p className="noPaddingParagraph">
                The normal rule that injuries disrupt attacks does apply to unworthy opponents’ sets. Every
                time a PC takes out a henchman, one die is removed from a successful set.
            </p>
            <p className="noPaddingParagraph">
                Motivations should be kept in mind with big groups. If a mob of twenty people attacks
                five foreigners and, within seconds, half of them are bleeding on the pavement, the other
                half runs even if no one makes a Morale Attack.
            </p>
            <p style={{ marginBottom: '20px', textIndent: '30px' }}>
                Unguided gangs fall apart quickly. They’re disorganized and tend to spread their attacks
                evenly among all available targets. Once a leader is present, a group is more likely to
                concentrate on one enemy (especially one using magic), fight longer, and protect one
                another. By the same token, if the leader falls the group probably has little incentive to
                fight to the death rather than fleeing.
            </p>
            <p>
                <b>Example:</b>
            </p>
            <div className="fancyBorder" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} >
                <div style={{ maxWidth: '500px' }}>
                    <p className="noPaddingParagraph">
                        Aramis, Lefleur and Schrenck are beset by 15 soldiers.  The soldiers have Threat 3.  Our three
                        heroes have AR 2 on all locations, swords and 6d pools for <b>Body+Fight</b>, <b>Coordination+Bow</b>
                        and <b>Body+Parry</b>.
                    </p>
                    <p className="noPaddingParagraph">
                        On the first round, Aramis and Lefleur declare attacks, while Schrenck parries. The soldiers
                        don’t need to declare their actions.
                    </p>
                    <p className="noPaddingParagraph">
                        Everyone rolls.  The soldiers get <b>3x2, 3x1, 2x9 and 2x4</b>.  Aramis gets a <b>2x4</b> and <b>2x3</b>, Schrenck
                        gets <b>2x4</b> and <b>2x5</b>, while Lefleur gets nothing.
                    </p>
                    <p className="noPaddingParagraph">
                        With their <b>3x2</b>, the soldiers strike Lefleur.  Schrenck can’t parry this, because it happens too
                        fast. Lefleur’s armor takes half of it, but he’s still hurt. With a <b>3x1</b>, the soldiers strike Aramis.
                    </p>
                    <p className="noPaddingParagraph">
                        He loses a die from his <b>2x3</b> set, but still has a <b>2x4</b> to use.
                        With their <b>2x9</b>, the soldiers hit Schrenck. Again, too fast— he loses a die from his <b>2x4</b> set but
                        has <b>2x5</b> for backup. However, since he’s got AR2, he takes reduced damage.
                    </p>
                    <p className="noPaddingParagraph">
                        Aramis applies his <b>2x4</b> and nails one of the soldiers. It doesn’t matter that the soldier is in
                        armor. The <b>Height</b> is greater than the soldier’s Threat, so he’s down. This drops a die from
                        the soldiers’ <b>2x4</b> match, ruining their last attack.  Schrenck can act with his Parry dice but
                        there’s nothing left to parry.
                    </p>
                    <p className="noPaddingParagraph">
                        Next round, the fourteen remaining soldiers roll <b>14d10</b> and the heroes all decide to press the
                        attack.  The soldiers get <b>3x9, 3x2, 2x7 and 2x5</b>.  Aramis gets a <b>2x5</b> and <b>2x3</b>, Lefleur gets a <b>2x8 </b>
                        and <b>2x6</b>, while Schrenck gets no matches.
                    </p>
                    <p className="noPaddingParagraph">
                        The GM considers holding those <b>3x9s</b> back as dodges to draw out the fight, but instead
                        applies them to Aramis. He loses a die from his <b>2x3</b> set. The <b>3x2</b> set is pushed on
                        Schrenck.
                    </p>
                    <p className="noPaddingParagraph">
                        Lefleur goes next with <b>2x8</b>.  He kills a soldier and ruins their <b>2x5</b> set.  Too late, the soldiers
                        decide to parry with their <b>2x7</b> set, which offsets Aramis’ <b>2x5.</b>
                    </p>
                    <p className="noPaddingParagraph">
                        Next round, the soldiers roll 13d, while the wounded heroes roll full pools.  Aramis has been
                        rolling hot, so he decides to drop to 5d and do a multiple action—attack and parry.  Lefleur
                        and Schrenck both attack.
                    </p>
                    <p className="noPaddingParagraph">
                        The GM rolls <b>3x10, 2x9 and 4x7!</b>  But unworthies can’t have a set bigger than 3x, so he
                        has to break that x7 set into <b>2x7 and 2x7</b>.  Aramis, with his reduced pool, gets nothing.
                    </p>Schrenck rolls well—a <b>3x5</b> and a <b>3x7</b>.  Lefleur gets a <b>2x7</b> as well.
                    <p className="noPaddingParagraph">
                        The GM hits Aramis with the <b>3x10</b>—four points of <b>Killing</b> damage to the head, and his
                        helmet only cuts out two.  The <b>2x9</b> goes against Schrenck, knocking his backup set to <b>2x5</b>,
                        but then it’s the PCs’ turn.  Lefleur and Schrenck kill one soldier apiece.
                    </p>
                    <p className="noPaddingParagraph">
                        Now the tide should start to turn.  The PCs press their attack, and the soldiers’ pool is down
                        to 11d. As more soldiers are downed, morale in the group drops until eventually it reaches a tipping point and they break.
                        Aramis, Lefleur and Schrenck live another day.
                    </p>
                </div>
            </div>
        </div>
    )
}