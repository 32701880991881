const { nanoid } = require("nanoid");
const axios = require('axios');

export async function postCharacter(character) {
    character.key = nanoid();

    try {
        const options = {
            headers: {
                "Content-Type": "application/json",
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        }

        let result = await axios.post('https://hq7p5g915i.execute-api.us-east-1.amazonaws.com/prod/characters', character, options)

        // return success
        return {
            statusCode: 200,
            body: JSON.stringify({
                success: true,
                data: result.data,
            }),
        };

    } catch (error) {
        return {
            statusCode: error.statusCode || 500,
            body: JSON.stringify(error),
        };
    }
};

export async function putCharacter(character) {
    try {
        const options = {
            headers: {
                "Content-Type": "application/json",
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        }

        let result = await axios.post('https://hq7p5g915i.execute-api.us-east-1.amazonaws.com/prod/characters', character, options)

        // return success
        return {
            statusCode: 200,
            body: JSON.stringify({
                success: true,
                data: result.data,
            }),
        };

    } catch (error) {
        return {
            statusCode: error.statusCode || 500,
            body: JSON.stringify(error),
        };
    }
};

export async function getCharacters() {
    try {
        const options = {
            headers: {
                "Content-Type": "application/json",
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        }
        let result = await axios.get('https://hq7p5g915i.execute-api.us-east-1.amazonaws.com/prod/characters', options)
        return {
            statusCode: 200,
            body: JSON.stringify({
                success: true,
                data: result.data,
            }),
        };

    } catch (error) {
        return {
            statusCode: error.statusCode || 500,
            body: JSON.stringify(error),
        };
    }
}

export async function getCharacter(key) {
    try {
        const options = {
            headers: {
                "Content-Type": "application/json",
                'x-api-key': process.env.REACT_APP_API_KEY
            },
            params: {
                characterKey: key
            }
        }
        let result = await axios.get('https://hq7p5g915i.execute-api.us-east-1.amazonaws.com/prod/characters', options)
        return {
            statusCode: 200,
            body: JSON.stringify({
                success: true,
                data: result.data,
            }),
        };

    } catch (error) {
        return {
            statusCode: error.statusCode || 500,
            body: JSON.stringify(error),
        };
    }
}