import React from "react";
import { AddRelationships } from "../components/Relationships";

export class Relationships extends React.Component {
  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="ten columns">
            <h4 className="reallyHeavyWords">Relationships</h4>
            <small>
              Who inspired you to pick up the mantle of an adventurer? Who did you leave behind to start your journey? And who's going to avenge you if you should fall?
              <ul>
                <br />
                <li>
                  Family members ground your character in the world. They support you from afar, or maybe they're the reason you took off in the first place. From a gameplay perspective
                  familial relations can provide interesting plot hooks and character motivations. You don't need to trace your ancestry back to the dawn of man, but one or two relatives would be nice.
                </li>
                <br />
                <li>
                  Relations could be friends, family, or even lovers. If you haven't got any family or friends, an enemy will also do in a pinch.
                </li>
              </ul>
            </small>
          </div>
        </div>
        <AddRelationships setFormData={this.props.setFormData} formData={this.props.formData}></AddRelationships>
      </div>
    );
  }
}
