import React from "react";

import { CharacterSheet } from '../components/CharacterSheet';

export class Review extends React.Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="columns terms">
            <h4 className="reallyHeavyWords" style={{ marginBottom: '20px' }}>Character Sheet:</h4>
          </div>
        </div>
        <CharacterSheet setFormData={this.props.setFormData} formData={this.props.formData} />
      </div>
    );
  }
}
